import { React, useEffect, useState, useRef ,useContext} from "react";
import "./OpenBlog.css";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import { FiShare2 } from "react-icons/fi";
import Comment from "../Comment/Comment";
import { useHistory } from "react-router-dom";
import axios from "axios";
import dotenv from "dotenv";
import { Modal } from "react-responsive-modal";
import {FaWhatsappSquare,FaFacebookSquare} from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { BiCommentDetail } from "react-icons/bi";
import { FacebookShareButton,WhatsappShareButton,EmailShareButton} from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LogContext from "../../../LogContext";

dotenv.config();

export default function OpenBlog(props) {
  const blogID = props.match.params.id?props.match.params.id:props.location.state.BlogID;
  const scrollRef = useRef();
  const history = useHistory();
  const logStatus = useContext(LogContext);
  const [blogData, setBlogData] = useState();
  const [htmlData, setHtmlData] = useState();
  const [like, setLike] = useState();
  const [copy ,setCopy] = useState(false);
  const [open, setOpen] = useState(false);
  const [likeCount ,setLikeCount] = useState();
  const [openAnother, setOpenAnother] = useState(false);
//  const []
  const [commentStatus, setCommentStatus] = useState(false);
  // const commentCount = props.location.state.commentCount?props.location.state.commentCount:0;
  const [commentCount , setCommentCount ] = useState(0);

  const likeBlogApi= async ()=>{
  const token = localStorage.getItem('token');
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/cms/like-blog/${blogID}`
        ,{ headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
       
    }
  };


 const otherApi = (token, url)=>{
      axios.post(`${process.env.REACT_APP_API_URL}/cms/blog-comment/content`,{
       uri:url
    }
    ).then((res)=>{
      setHtmlData(res?.data);
   
   }) 
  }

  const getApiBlogDataWithID = async () => {
    const token  = localStorage.getItem('token');
    try {  
           await axios.get(
            `${process.env.REACT_APP_API_URL}/cms/blog/${blogID}`
            ).then((response)=>{
              setBlogData(response?.data);
              setLikeCount(response?.data?.likes?.length);
              setLike(response?.data?.liked);            
              otherApi(token,response?.data?.content)
            })
   
    } catch (error) {
      console.log(error);
      history.push("/blogpage")
    }
  };
  useEffect(()=>{
    getApiBlogDataWithID();
 },[])

  useEffect(()=>{
   const token =  localStorage.getItem('token');   
   if(token){
     if(blogData?.liked){
       setLike(true)
     }else{
       setLike(false)
      }
    }
  }, [blogData]);

  const likeClickhandler = () => {
    const token = localStorage.getItem("token");
    if (token) {
      if (like === true) {
        setLike(false);
        setLikeCount(likeCount - 1);
        likeBlogApi();
      } else if (like === false) {
        setLike(true);
        setLikeCount(likeCount + 1);
        likeBlogApi();
      }
    } else {
      // setOpen(true);
      logStatus.setOpenPop(true);
    }
  };

  useEffect(() => {
    if (blogID == null) {
      history.goBack();
    }
  }, []);
  

  useEffect(() => {
    if (props.location.hash === "#OpenBlogPageTop") {
      const body = document.querySelector("#root");
      body.scrollIntoView(
        {
          behavior: "smooth",
        },
        500
      );
    } else if (props.location.hash === "#commentedits") {
      scrollToBottom();
    }
  }, []);

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth" }, 1000);
    }, 1000);
  };

  return (
    <>
      <div className="open-blog-page-wrap">
        <div className="blog-title">
          <h1>{blogData?.title}</h1>
          {/* <h3>Sponsored by Saks Fifth Avenue and ShopStyle.</h3> */}
          <p className="blog-summary">{blogData?.summary}</p>
        </div>

        <div className="blog-img-div">
          <img
            src={blogData?.bannerImage}
            alt=""
            className="blog-banner-img"
          />
        </div>
        <div className="blog-title-again">
          {/* <h1>{blogData?.title}</h1> */}
        </div>

        <div className="blog-detail-div">
          <div
            dangerouslySetInnerHTML={{ __html: htmlData }}
            className=""
          ></div>
        </div>

        <div className="open-blog-like-section">
          <div className="like-share-button">
            {/* <div  className={like?"blog-like-button-selected blog-like-count":"blog-like-btn blog-like-count"}> */}

            <div className="like-container-blog">
              {like ? (
                <AiFillLike
                  className="like-icon-openBlog"
                  onClick={() => likeClickhandler()}
                />
              ) : (
                <AiOutlineLike
                  className="like-icon-openBlog"
                  onClick={() => likeClickhandler()}
                  size="1.2rem"
                />
              )}
              <p className="button-text-sectiion">{likeCount}</p>
            </div>
            {/* </div> */}
            <div className="comment-blog">
              <BiCommentDetail onClick={()=> setCommentStatus(!commentStatus)} className="comment-on-openblog"></BiCommentDetail>
              <p className="button-text-sectiion">{commentCount}</p>
            </div>
            <div className="share-container-blog">
              <FiShare2
                onClick={() => setOpenAnother(true)}
                className="like-icon-openBlog"
              />
              <p
                onClick={() => setOpenAnother(true)}
                className="button-text-sectiion share-module-text"
              >
                Share
              </p>
            </div>

            <Modal
              open={openAnother}
              onClose={() => setOpenAnother(false)}
              center
            >
              <h2>Share</h2>
              <div className="share-modal-wrap">
                  <div className="whatsapp share-modal">
                  <WhatsappShareButton
                  url={"https://www.spiffyyou.com/blogpage/"+blogData?._id}
                  quote={"Recommended Blog:"+blogData?.title}
                  hashtag={"#hashtag"}
                  description={"aiueo"}
                  className="Demo__some-network__share-button"
                   ><FaWhatsappSquare color={"brown"} size={"3rem"} /></WhatsappShareButton>
                  </div>
                  <div className="facebook share-modal">
                <FacebookShareButton
                        url={"https://www.spiffyyou.com/blogpage/"+blogData?._id}
                         quote={"Recommended Blog:"+blogData?.title}
                        hashtag={"#spiffy"}
                        description={"aiueo"}
                        className="Demo__some-network__share-button"
                    >
                    <FaFacebookSquare color={"brown"} size={"3rem"} />
                  </FacebookShareButton>
                  </div>
                  <div className="mail share-modal">
                 <EmailShareButton
                    url={"https://www.spiffyyou.com/blogpage/"+blogData?._id}
                       quote={"Recommended Blog:"+blogData?.title}
                    hashtag={" "}
                    description={"aiueo"}
                    className="Demo__some-network__share-button"
                    >
                      <IoIosMail color={"brown"} size={"4rem"} />
                    </EmailShareButton>
                  </div>
                </div>
              <div style={{padding:"10px"}} className="link-row">
                <div className="link-for-copy">https://www.spiffyyou.com/blogpage/{blogData?._id}</div>
                <div  style={{marginLeft:"10px"}} className="copy-btn">
                <CopyToClipboard
                 text={"https://www.spiffyyou.com/blogpage/"+blogData?._id}
                 onCopy={()=>setCopy(true)}
                >
                  <button className="copy-button">Copy</button>
                </CopyToClipboard> 
                </div>
              </div>
            </Modal>
          </div>
          {/* <div className="like-Counter-div">{likeCount}</div>   */}
        </div>
        <div ref={scrollRef} id="commentedits" className="comment-section-div">

          <Comment
           setCommentCount={setCommentCount}
            blogID={blogID}
            commentStatus={commentStatus}
            setCommentStatus={setCommentStatus}
          />
        </div>
      </div>
    </>
  );
}
