import {React,useContext} from 'react'
import './CardComponent.css';
import {GiCancel} from "react-icons/gi";
import logContext from "../../../LogContext";
import {useHistory} from 'react-router-dom';

function LoginPopCard() {

  const logState = useContext(logContext);
  const history = useHistory();

  const handleuseChecks = (pageRequest) =>{
      if(pageRequest===false){
        history.push("/register");
        logState.setOpenPop(false)
      }else if(pageRequest===true){
        history.push("/loginpage");
        logState.setOpenPop(false)
      }
  }

  return (
    <div className="Main-Container-LogIn-Pop-Class">
     <GiCancel onClick={()=>logState.setOpenPop(false)} className="cancel-button-log" />
        <div className="Heading-Conatiner-Class CeraProBold"> LIKE WHAT YOU SEE ? </div>
        <div className="Sub-Heading-Conatiner-Class CeraProMedium">Become a spiffy insider to Enjoy Exclusive Benefits</div>
        <div className="list-container-class CeraProMedium">
            <ul>
                <li>Save brands you like for later</li>
                <li>Exclusive newsletter</li>
                <li>Member giveaways</li>
                <li>Brand recommendations based on your profile</li>
            </ul>
        </div>
        <div onClick={()=>handleuseChecks(false)} className="signUp-Button-Class CeraProMedium">SIGN UP FOR MORE</div>
        <div onClick={()=>handleuseChecks(true)} className="LogIn-Button-class CeraProMedium"> Already a member? <span style={{cursor:'pointer'}} className="Log-InButton-Class">Log-in</span></div>
    </div>
  )
}

export default LoginPopCard