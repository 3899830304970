import React from "react";
import "./ProvideItems.css";
import cartIcon from '../../../assets/img/cart-icon.png';
import "../OurValues/OurValues.css";
import valueImg1 from '../../../assets/About_page/1.png';
import valueImg2 from '../../../assets/About_page/2.png';
import valueImg3 from '../../../assets/About_page/3.png';


export default function ProvideItems() {
  const ourvaluearr = [
    { 
image:valueImg1,  
      title: "Access",
      description:
        "To most sought after fashion labels from different parts of the world.",
    },
    {
      image:valueImg2,
      title: "Connect",
      description:
        "With an honest community that is thoughtfully curating the best in fashion.",
    },
    {
      image:valueImg3,
      title: "Simplify ",
      description:
        " Your shopping decisions with credible reviews.",
    },
  ];
  return (
    <>
          <h1 className="provides-title">Be a part of the Spiffy tribe, because we provide - </h1>
  <div className="our-value-wrap our-value-wrap-under-mission">
  {/* <img className="about-page-image" src={aboutImage} alt="" /> */}
{ourvaluearr.map((values,index) => {
  return (
    <div className={`our-value-first-col mrtop${index}`}>
      <div  className="our-value-img-div">
        <img src= {values.image} alt="pic" />
      </div>
     
      <div className={`our-value-title`} >
      
        <h1>{values.title}</h1>
      </div>
      <div className="our-value-description">
        <p>
          {values.description}
        </p>
      </div>
    </div>
  );
})}
</div>
</>
  )
//     const povidearr = [1,2,3,4];
//   return (
//     <div className="provide-items-wrap">
//         {
//             povidearr.map((index) => {
// return (
//     <div className="provide-item-first-col">
//     <div className="circle-img-div">
//       <img src={cartIcon} alt="" />
//     </div>
//     <div className="provide-item-title">
//       <h1>Lorem Ipsum</h1>
//     </div>
//     <div className="provide-item-description">
//       <p>
//         Lorem Ipsum is simply dummy text of the printing and typesetting
//         industry.
//       </p>
//     </div>
//   </div>
// )
//             })
//         }
      
//     </div>
//   );

}
