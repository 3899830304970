import React from "react";
import { RWebShare } from "react-web-share";
  

export default function WebShareGfg(){
    return (
      <div>
        <h1>Web Share - GeeksforGeeks</h1>
        <RWebShare
          data={{
            text: "Chack Out This Brand On Spiffy",
            url: "http://spiffyyou.com",
            title: "Share",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <button>Share on Web</button>
        </RWebShare>
      </div>
    );
  };