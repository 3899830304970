import React from "react";
import "./GetInTouch.css";
import {IoMail} from 'react-icons/io5'


// spelling o f pinterest is wrong
// you can remove this unused commented assets as you will be able to always import them when required.


export default function GetInTouch() {
  return (
    <>

      <div className="getintouch-wrap">
        <div id="getintouch" className="getintouch-des">
          <h1 className="CeraProBold">#GetInTouch</h1>
          <p className="CeraProLight" style={window.screen.width < 540? {fontSize:"1rem"}:{fontSize:"1rem"}}>
            Boosting shopping confidence with credible social proof reviews! Get
            in touch with us in case you’ve any questions or just want to show
            some love!
          </p>
        </div>

        <div className="getintouch-icon">
          <a href="https://www.instagram.com/spiffy.you/" target={"blank"}>
            <img
              src={require("../../../assets/img/instagram.svg").default}
              alt="istagram"
              className="getintouch-icon-img"
            />
          </a>
          <a
            href="https://in.pinterest.com/kikaicomp/_created/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../../../assets/img/pintrest.svg").default}
              alt="pintrest"
              className="getintouch-icon-img"
            />
          </a>
          <a
            href="https://www.facebook.com/people/Spiffy-You/100078406224985/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={require("../../../assets/img/facebook.svg").default}
              alt="facebook"
              className="getintouch-icon-img"
            />
          </a>
          <a
            href="https://mail.google.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
          <IoMail color="#AF1C1C" size={"2rem"} className="mail-icon"/>
          </a>
        </div>
      </div>
    </>
  );
}
