import {React,useEffect,useState} from 'react'
import verified from "../../assets/verified.gif"
import { TailSpin } from  'react-loader-spinner';
import axios from "axios";
import {useParams,useHistory} from "react-router"
import dotenv from "dotenv";
dotenv.config();

function EmailVerLink() {
    
    const [verStatus ,setVerstatus] = useState(false)
    const emailToken = useParams().token  ;
    const history = useHistory();

    const emailVerificationApi = async () => {
      try{
          axios.post(`${process.env.REACT_APP_API_URL}/auth/verify-email`,
          {token:emailToken}
          ).then(()=>{
            setVerstatus(true)
          })
      }catch{}
    }
    const proccedToLogOut = () =>{
      localStorage.clear();
      history.push("/LogInPage")
    }

    useEffect(()=>{
      console.log(emailToken,"EmailToken");
     emailVerificationApi()
    },[])

    return (
        <>
        {verStatus ?
         <div style={LinkPageStyle}>
           <h1 className="ceraProBold" style={{color:"#17C37E"}}>Email is Verified</h1>
            <img height="450px" width="450px" src={verified} alt="verification" />
            <div>
            <button className="CeraProMedium" style={buttonStyleLog} onClick={()=>proccedToLogOut()}>Go To Login</button>
            </div>
         </div>:
         <div style={LinkPageStyle}>
             <TailSpin  color="#8BFFAC" height={100} width={100} />
         </div> 
        }
       </>
    )
}

export default EmailVerLink
const LinkPageStyle ={
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",
    height:"70vh",
    marginTop: "4rem"
}   

const buttonStyleLog = {
  borderRadius :"5px",
  padding:"1rem 2rem",
  backgroundColor:"#af1c1c" ,
  borderColor:"#af1c1c",
  color:"white",
  fontSize:"15px"

}