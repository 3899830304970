import {React,useState,useEffect} from "react";
import "./EasySteps.css";
import { useHistory } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import star from "../../../assets/ImageAnother/star.svg";
import idea from '../../../assets/ImageAnother/idea.svg';
import location from '../../../assets/ImageAnother/location.svg';
import upload from '../../../assets/ImageAnother/upload.svg';
import rating from '../../../assets/ImageAnother/rating.svg';
import Done from "../../ui/DoneComponent/Done";
import { BsFillCameraFill  } from "react-icons/bs";
import { Modal } from "react-responsive-modal";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios"
import dotenv from "dotenv";
dotenv.config();

function EasySteps({userData}) {
  const [openEdit, setOpenEdit] = useState(false);
  const [inputNameValue, setInputNameValue] = useState();
  const quizeUrl = "https://www.tryinteract.com/share/quiz/60a3278c331f270017e93cd2"
  const [inputLocationValue, setInputLocationValue] = useState();
  const ProfilePatchApi = async () => {
    const token = localStorage.getItem("token");
    try {
      
      const res = await axios.patch(
        `${process.env.REACT_APP_API_URL}/user`,
        { name: inputNameValue, location: inputLocationValue },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log(error, "error In User Patch API");
    }
  };

  const handleInputValueName = (e) => {
    setInputNameValue(e.target.value);
  };

  const handleInputValueLocation = (e) => {
    setInputLocationValue(e.target.value);
  };
  const history = useHistory();
  return (
    <div className="main-container">
      <div className="heading-container">
        <h1 className="heading">5 easy steps to a 5-star profile </h1>
        <p className="sub-heading"> Fashion is Fun When Social </p>
      </div>
      <Modal
            open={openEdit}
            onClose={() => setOpenEdit(false)}
            center
            classNames={{
              overlay: "customOverlay",
              modal: "edit-modal",
            }}
          >
            <h2 className="edit-modal-heading">Edit your profile</h2>
            <div className="edit-profile-modal-wrap">
              <div className="camera-icon camera-icon-profile-page">
                <BsFillCameraFill color="#ffffff" size={"0.8rem"}/>
              </div>
              <div className="edit-image">
                {userData?.profilePic ? (
                  <img src={userData?.profilePic} alt="this is a image" />
                ) : (
                  <FaUserCircle size={"6rem"} color="#989898" />
                )}
                <button className="update-profile-img-btn">
                  update profile
                </button>
              </div>
              <div className="edit-form-wrap">
                <div className="edit-feild-1">
                  <div className="edit-input-label">
                    <label>Name</label>
                  </div>
                  <div className="edit-feild-text">
                    <input
                      type="text"
                      name="name"
                      className={"Input-profile-Name"}
                      onChange={handleInputValueName}
                      value={inputNameValue}
                    />
                  </div>
                </div>
                <div className="edit-feild-2">
                  <div className="edit-input-label">
                    <label>Location</label>
                  </div>
                  <div className="edit-feild-text">
                    <input
                      type="text"
                      name="edit-location"
                      onChange={handleInputValueLocation}
                      value={inputLocationValue}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-profile-submit">
              <button
                className="edit-profile-submit-btn"
                onClick={() => ProfilePatchApi()}
              >
                submit
              </button>
            </div>
          </Modal>

      <div className="icon-container">
   
     <div className="iconBox-container" onClick={()=>window.open(quizeUrl,"blank")} href={!(userData?.fiveStarProfile?.takeTheStyleQuiz)}>
         <Done  showValueOfDone={userData?.fiveStarProfile?.takeTheStyleQuiz} />
          <img src={idea} alt="" />
          <p>Take The Style Quiz</p>     
        </div>
        <div  className="iconBox-container" onClick={!(userData?.fiveStarProfile?.updateLocation)?()=>setOpenEdit(true):""}>
        <Done showValueOfDone={userData?.fiveStarProfile?.updateLocation} />
          <img src={location} alt="" /> <p>Update Location</p>
        </div>
        <div className="iconBox-container" onClick={!(userData?.fiveStarProfile?.submitNewBrand)?()=>history.push("/submitbrand"):''}>
        <Done showValueOfDone={userData?.fiveStarProfile?.submitNewBrand} />
          <img src={upload} alt="" /> <p>Submit a New Brand</p>
        </div>
        <div className="iconBox-container" onClick={!(userData?.fiveStarProfile?.rateBrand)?()=>history.push("/discoverpage"):''}>
        <Done showValueOfDone={userData?.fiveStarProfile?.rateBrand} />
          {" "}
          <img src={star} alt="" />
          <p>Rate a Brand</p>
        </div>
        <div className="iconBox-container" onClick={!(userData?.fiveStarProfile?.writeBrandReview)?()=>history.push("/discoverpage"):''}>
        <Done showValueOfDone={userData?.fiveStarProfile?.writeBrandReview} />
          {" "}
          <img src={rating} alt="" />
          <p>Write a Brand Review</p>
        </div>
      </div>

      <div className="button-container">
        <div className="button" onClick={() => history.push("/discoverpage")}>
          <p>Discover And Rate Other Brands</p>
          <BsArrowRight color={"white"}/>

        </div>
        <div className="button" onClick={() => history.push("/submitbrand")}>
          <p> Submit A New Brand </p> 
          <BsArrowRight />
        </div>
      </div>
    </div>
  );
}

export default EasySteps;
