import {React,createContext} from "react";
import ReactDOM from "react-dom";
import "./font/Cera Pro/Cera Pro Medium.ttf";
import "./font/Nunito/Nunito-Light.ttf";
import "./font/Nunito/Nunito-SemiBold.ttf";
import "./font/Nunito/Nunito-Regular.ttf";
import "react-toastify/dist/ReactToastify.css";
import "./font/Cera Pro/Cera Pro Bold.ttf";
import "./index.css";
// import { BrowserRouter } from "react-router-dom";
import AppWraper from "./AppWraper";



ReactDOM.render(
  <AppWraper/>
 , document.getElementById("root"));
