    import React from 'react';
import BrandDetail from './BrandDetial/BrandDetail';
import "./SubmitBrand.css";
import SubmitBrandBanner from './SubmitBrandBanner/SubmitBrandBanner';

export default function SubmitBrand({editOpenSumbit,setEditOpenSumbit}) {
    return (
        <div>
            <SubmitBrandBanner/>
            <BrandDetail editOpenSumbit={editOpenSumbit}  setEditOpenSumbit={setEditOpenSumbit}/>
        </div>
    )
}
