import {React, useState, useEffect} from 'react';
import MustReadCards from '../mustread/MustReadCards';
import "./ResMustRead.css";
import BlogCards from "../../BlogPage/BlogCards/BlogCards";
import axios from 'axios';
import ReadAllButton from '../mustread/ReadAllButton';
import buttonArrowImg from "../../../assets/img/button-white-arrow.svg";
import EmptyData from "../EmptyData/EmptyData"
import {useHistory} from "react-router-dom"
export default function ResMustRead({blogTittle}) {
    const [blogData, setBlogData] = useState([]);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const getBlogData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/cms/blog?page=${1}&limit=${2}`
      );
      setBlogData(response?.data?.Blog);
    };
    useEffect(() => {
      getBlogData();
    }, []);
    const likeBlogApi = async (card) => {
      const token = localStorage.getItem("token");
      try {
        await axios.patch(
          `${process.env.REACT_APP_API_URL}/cms/like-blog/${card?._id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (error) {
        console.log(error);
      }
    };
  
    return (
      <>
        <div id="Exploreee" className="bg-div">
          <h1 className="CeraProBold mustread-h1">{blogTittle}</h1>
          {/* <p className="mustread-p">
            How-tos, must-trys and strictly avoid - our editorial covers
            fundamentals remaining in style. Read our explore section and refresh your
            wardrobe.
          </p> */}
        </div>
       {blogData.length > 0?<div className="responsive-mustread-wrap">
            {
                blogData?.map((card,index)=>{
                return <BlogCards  setOpen={setOpen}
                pro={false}
                likeBlogApi={likeBlogApi}
                pro={"BlogPage"}
                key={index}
                card={card} />
            })  
            }
        </div>:<div className="empty-mob-res"> <EmptyData profilepage={true} Heading={''} ButtonText={"Read More"} EmptyMassage={"No Items In Your Blog List"}/> </div>}
            {/* <ReadAllButton nameForClass="read-all-btn-wrap-responsive"/> */}
            { blogData?.length > 0?<div className="Read-all-Btn-class">
                <button onClick={()=>history.push("/blogpage")} className="Read-all-BTN-Home">
                  Read All <span><img className="arrow-on-blog-sectioin" src={buttonArrowImg} alt=""/></span>
                </button>
            </div>:''}
            </>
    )
}
