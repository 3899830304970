import React from "react";
import "./HeroSection.css";
import { Carousel } from "react-responsive-carousel";
import slider1 from "../../../assets/about/1.svg";
import slider2 from "../../../assets/about/2.svg";
import slider3 from "../../../assets/about/3.svg";
import slider4 from "../../../assets/about/4.svg";
import slider5 from "../../../assets/about/5.svg";
import slider6 from "../../../assets/about/6.svg";

import MobSlider1 from "../../../assets/about/Mob/1.svg";
import MobSlider2 from "../../../assets/about/Mob/2.svg";
import MobSlider3 from "../../../assets/about/Mob/3.svg";
import MobSlider4 from "../../../assets/about/Mob/4.svg";
import MobSlider5 from "../../../assets/about/Mob/5.svg";
import MobSlider6 from "../../../assets/about/Mob/6.svg";

export default function HeroSection() {
  return (
    <>
    <div className="hero-section-wrap web-view">
      <Carousel showThumbs={false} autoPlay={false} >
        <div className="about-story-1 about-story-div">
          <img className="about-sliderimage" src={slider1} alt="" />
        </div>
        <div className="about-story-2 about-story-div">
          <img className="about-sliderimage" src={slider2} alt="" />
        </div>
        <div className="about-story-3 about-story-div">
          <img className="about-sliderimage" src={slider3} alt="" />
        </div>
        <div className="about-story-4 about-story-div">
          <img className="about-sliderimage" src={slider4} alt="" />
        </div>
        <div className="about-story-5 about-story-div">
          <img className="about-sliderimage" src={slider5} alt="" />
        </div>
        <div className="about-story-5 about-story-div">
          <img className="about-sliderimage" src={slider6} alt="" />
        </div>
      </Carousel>
      </div>

      <div  className="hero-section-wrap mob-view">
      <Carousel  showThumbs={false} autoPlay={false}>
      <div className="about-story-1 about-story-div">
          <img className="about-sliderimage" src={MobSlider1} alt="" />
        </div>
        <div className="about-story-2 about-story-div">
          <img className="about-sliderimage" src={MobSlider2} alt="" />
        </div>
        <div className="about-story-3 about-story-div">
          <img className="about-sliderimage" src={MobSlider3} alt="" />
        </div>
        <div className="about-story-4 about-story-div">
          <img className="about-sliderimage" src={MobSlider4} alt="" />
        </div>
        <div className="about-story-5 about-story-div">
          <img className="about-sliderimage" src={MobSlider5} alt="" />
        </div>
        <div className="about-story-5 about-story-div">
          <img className="about-sliderimage" src={MobSlider6} alt="" />
        </div>
        </Carousel>
        </div>
    </>
  );
}
