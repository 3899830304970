import dotenv from "dotenv";
import React from "react";
import "./DiscoverCards.css";
import cardImage from "../../../assets/img/card-img.svg";
import { FaStar } from "react-icons/fa";
import locationImg from "../../../assets/img/Location.svg";
import favrioteImg from "../../../assets/img/favriote.svg";
import shareImg from "../../../assets/img/Share.svg";
import starImg from "../../../assets/img/star.svg";
dotenv.config();

export default function DiscoverCards({ BrandapiData, bgColor }) {
  const cards = [
    {
      title: "Burberry",
      city: "Paris",
      rating: 3,
      totalStars: 5,
      ratingNum: "1685 rating",
      ratingPoint: 5.0,
      tags: ["Leisure", "Atheletic"],
    },
    {
      title: "Cadbury",
      city: "Indore",
      rating: 2,
      ratingNum: "1685 rating",
      ratingPoint: 5.0,
    },
    {
      title: "Imaginary",
      city: "Jaipur",
      rating: 3.5,
      ratingNum: "1685 rating",
      ratingPoint: 5.0,
    },
  ];
  let ratingArray = [1, 2, 3, 4, 5];

  let fullStars = new Array(5);
  fullStars.fill(0);
  return (
    <div className="cards-section-wrap" style={{ background: `${bgColor}` }}>
      <div
        // className="discover-main-card-div mobile-vew-discover-card"
        // style={{ background: `${bgColor}` }}
        className="discover-main-card-div"
        style={{ background: `${bgColor}` }}
      >
        {BrandapiData?.map((card) => {
          return (
            <div className="card-1 discover-card-1">
              <div className="card-img-div">
                <img
                  src={`${card.images[0]}`}
                  alt=""
                />
              </div>
              <div className="card-first-detail discover-card-first-detail">
                <div className="first-detail-title">
                  <div className="title discover-title CeraProMedium ">
                    {card.title}
                  </div>
                  <div className="country discover-card-country CeraProLight">
                    {card.city}
                  </div>
                </div>
                <div className="first-detail-rating">
                  <div className="rating-row-1 discover-rating-row-1">
                    {fullStars.map((star, index) => {
                      return index < card.rating ? (
                        <FaStar color={"brown"} />
                      ) : (
                        <FaStar color={"#707070"} />
                      );
                    })}
                  </div>

                  <div className="rating-row-2">
                    <div className="rating-num discover-page-rating-num CeraProMedium">
                      {card.ratingNum}{" "}
                    </div>
                    <div className="rating-point discover-page-rating-point CeraProLight">
                      {card.ratingPoint}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tag-row">
                <div className="tags discover-tags">
                  <h4>Athleisure</h4>
                </div>
                <div className="tags discover-tags">
                  <h4>Bespoke</h4>
                </div>
                <div className="tags discover-tags">
                  <h4>Formal</h4>
                </div>
                <div className="tags discover-tags">
                  <h4>Formal</h4>
                </div>
              </div>

              <div className="icon-row">
                <div className="learn-more">Learn More</div>
                <div className="icon-section">
                  <div className="location discover-location">
                    <img
                      src={locationImg}
                      alt=""
                      className="freshfinds-cards-icon discover-freshfinds-cards-icon"
                    />
                  </div>
                  <div className="favorite">
                    <img
                      src={favrioteImg}
                      alt=""
                      className="freshfinds-cards-icon discover-freshfinds-cards-icon"
                    />
                  </div>
                  <div className="share">
                    <img
                      src={shareImg}
                      alt=""
                      className="freshfinds-cards-icon discover-freshfinds-cards-icon"
                    />
                  </div>
                </div>
              </div>

              <div className="bottom-rating-row discover-card-rating-row">
                <div className="rate-it discover-rate-it">
                  <p className="CeraProMedium">Rate it</p>
                </div>
                <div className="bottom-stars discover-rating-star">
                  {ratingArray.map((item, index) => {
                    return <img src={starImg} alt="" />;
                  })}
                </div>

                <div className="review-div discover-review-div CeraProMedium">
                  Write a <br /> Review
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
