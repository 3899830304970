import React from 'react'
import './FitQualitySimple.css'
function FitQualitySimple({isBrandPage ,qualityRadiovalue, fitRadiovalue ,setQualityRadioValue, setFitRadioValue}) {
    return (
        <div className="fit-and-quality-wrap">
        <div className="fit-wrap-review">
          <div className={isBrandPage ?"fit-title submitbrand-row-padding lable-b":"fit-title submitbrand-row-padding lable"}>
            <lable >Fit</lable>
          </div>
          <div className="radio-select radio-select-review">

              <div className="radio-select-box-simple radio-select-box-simple-review ">
                <input onClick={()=>setFitRadioValue("1")} value="1"  type="radio" name="fit" />
                <br/> 
                <div className="radio-text-value radio-text-value-review">Runs Small</div>
              </div> 

              <div className="radio-select-box-simple radio-select-box-simple-review">
                <input onClick={()=>setFitRadioValue("2")} value="2"  type="radio" name="fit" />
                <br/> 
                <div className="radio-text-value radio-text-value-review">True To Size</div>
              </div> 

              <div className="radio-select-box-simple radio-select-box-simple-review">
                <input onClick={()=>setFitRadioValue("3")} value="3"  type="radio" name="fit" />
                <br/> 
                <div className="radio-text-value radio-text-value-review">Runs Large</div>
              </div> 
                         
          </div>
        </div>
        <div className="quality-wrap-review">
          <div className={isBrandPage?"qualit-title submitbrand-row-padding lable-b":"qualit-title submitbrand-row-padding lable"}>
            <lable>Quality</lable>
          </div>
          <div className="radio-select radio-select-review">

          <div className="radio-select-box-simple radio-select-box-simple-review">
                <input onClick={()=>setQualityRadioValue('1')} value="1" type="radio" name="quality" />
                <br/> 
                <div className="radio-text-value radio-text-value-review">Could Be Better</div>
              </div> 

              <div className="radio-select-box-simple radio-select-box-simple-review">
                <input onClick={()=>setQualityRadioValue('2')} value="2" type="radio" name="quality" />
                <br/> 
                <div className="radio-text-value radio-text-value-review">Ok</div>
              </div> 

              <div className="radio-select-box-simple radio-select-box-simple-review">
                <input onClick={()=>setQualityRadioValue('3')} value="3"  type="radio" name="quality" />
                <br/> 
                <div className="radio-text-value radio-text-value-review">Amazing</div>
              </div> 

          </div>
        </div>
      </div>
    )
}

export default FitQualitySimple
