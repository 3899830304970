import axios from 'axios';
import Config from './config.js';
let url = Config.baseURL;
console.log("massage from axiosConstant",url)


axios.defaults.headers.common["Authorization"] = `${localStorage.getItem(
  "token"
)}`;


const instance = axios.create({
    baseURL: url,
    params: {},
    data: {},
  });

  instance.interceptors.request.use(function (request) {
    if (!request.url.includes("login")) {
      request.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
    }
    return request;
  });

instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
   
    return Promise.reject(error);
  });


  export default instance;





  