import * as dotenv from "dotenv";
import { React, useEffect, useState } from "react";
import "./Blog.css";
import BlogCards from "./BlogCards/BlogCards";
import BlogFilter from "./BlogFilter/BlogFilter";
import axios from "axios";
import NoData from "../../assets/ImageAnother/NoData.svg";
import { TailSpin } from  'react-loader-spinner';
import exploreBannerImg from "../../assets/ExploreBannar.png";
import exploreBannerImgMobile from "../../assets/img/Mobile-explore-spiffy-stories.png";
import { Modal } from "react-responsive-modal";
import { useHistory } from "react-router-dom";
import { FaWhatsappSquare, FaFacebookSquare } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroll-component";


dotenv.config();
export default function Blog() {
  const [scrollLimit ,setScrollLimit] = useState(6);
  const [blogApiData, setBlogApiData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [openAnother, setOpenAnother] = useState(false);
  const [openSort ,setOpenSort] = useState(false);
  const [loadingIndicator, setLoadingIndicator] = useState(true);
  const [totalBlogs ,setTotalBlogs] = useState();
  
  const history = useHistory();

  const [pageCount,setPageCount] = useState(1);
  const [pageCountForApi ,setPageCountForApi] = useState(1)
  const [fitlerValue,setFileterValue] = useState({
    RecentlyAdd:{value: "", status: false},
    mostPopular: { value: "", status: false },
    relevant:{ value: 1, status: true},
    ATOZ:{ value: "", status: false},
    ZTOA:{ value: "", status: false},
    value:"Relevant"
  })

  useEffect(() => {
    getBlogApidData();
  }, [searchValue,fitlerValue,pageCountForApi,scrollLimit]);



  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {behavior: "smooth",},500);
  }, []);


  const getBlogApidData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/blog?page=${pageCountForApi}&limit=${scrollLimit}&search=${searchValue}&sort-createAt=${fitlerValue.RecentlyAdd.value}&sort-title=${fitlerValue.ATOZ.status?fitlerValue.ATOZ.value:fitlerValue.ZTOA.value}&sort-most-popular=${fitlerValue.mostPopular.value}`
    );
    setBlogApiData(response?.data?.Blog);
    setPageCount(response?.data?.totalPage)
    setTotalBlogs(response?.data?.totalCount)
  };
  

  const handleCallback = (childData) => {
    setSearchValue(childData);
  };

  
  const fetchMoreData =()=>{
    if( blogApiData?.length === totalBlogs){
      setLoadingIndicator(false)
    } else if( blogApiData?.length !== totalBlogs){
       setLoadingIndicator(true)
    }
    setTimeout(() => {
      if(blogApiData?.length !== totalBlogs){
      setScrollLimit(scrollLimit+6)
      }
     },1500)
   }

 

  const likeBlogApi = async (card) => {
    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/cms/like-blog/${card?._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log(error);
    }
  };
  
const handlePageChange=(e)=>{
  setPageCountForApi((e.selected)+1);
 }


  return (
    <div>
      <Modal open={openAnother} onClose={() => setOpenAnother(false)} center>
        <h2>Share</h2>
        <div className="share-modal-wrap">
          <div className="whatsapp share-modal">
            <FaWhatsappSquare color={"brown"} size={"3rem"} />
          </div>
          <div className="facebook share-modal">
            <FaFacebookSquare color={"brown"} size={"3rem"} />
          </div>
          <div className="mail share-modal">
            <IoIosMail color={"brown"} size={"4rem"} />
          </div>
        </div>
        
        <div className="link-row">
          <div className="link-for-copy">http://15.206.178.99/This is THe Blog</div>
          <div className="copy-btn">
            <button>Copy</button>
          </div>
        </div>

      </Modal>
      <div className="explore-banner">
        <img src={exploreBannerImg} alt="" className="explore-web"/>
        <img src={exploreBannerImgMobile} alt="" className="explore-mobile" />
      </div>
      <BlogFilter pageCount={pageCount} handlePageChange={handlePageChange} fitlerValue={fitlerValue} setFileterValue={setFileterValue} openSort={openSort} setOpenSort={setOpenSort} handleCallback={handleCallback} />
      <InfiniteScroll
          dataLength={scrollLimit}
          next={fetchMoreData}
          hasMore={true}
          loader={loadingIndicator&&<div className="Loading-spinner"><TailSpin  color="#00BFFF" height={50} width={50} /></div>} >

      <div className="blogCard-div-section">
        {blogApiData?.length > 0 ? (
         blogApiData?.map((card, index) => {
            return (
              <BlogCards
                pro={false}
                likeBlogApi={likeBlogApi}
                pro={"BlogPage"}
                key={index}
                card={card}/>
            );
          })
         ):(<img alt="NoDataImage" className="blogPageNoDataImage" src={NoData} />
        )}
       </div>
     
      </InfiniteScroll>
    </div>
  );
}
