import { React, useState, useEffect ,useContext} from "react";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import { BsReplyAllFill } from "react-icons/bs";
import "./OpenBlog.css";
import axios from "axios";
import CommentOnComment from "./CommentOnComment.js";
import { Modal } from "react-responsive-modal";
import LogContext from "../../../LogContext";
import dotenv from "dotenv";
dotenv.config();

function CommentCard({ comment }) {
  const logStatus  = useContext(LogContext);
  const [openCommentPop, setOpenCommentPop] = useState(false);
  const [commentReplyShow, setCommentReplyShow] = useState(false);
  const [reply, setReply] = useState();
  const [commentReplys, setCommentReplys] = useState([]);
  const [likeStatusOnMainComment, setLikeStatusOnMainComment] = useState(
    comment?.liked
  );
  const [commmentLikeCount, setCommentLikeCount] = useState(
    comment?.likes ? comment?.likes?.length : 0
  );
  const [CommentOnCommentID, setCommentOnCommentID] = useState();
  const [commentOnCommetReplyUpdate, setCommentOnCommetReplyUpdate] =
    useState(false);

  const commentOnCommentApi = async () => {
    const token = localStorage.getItem("token");
    try {
       await axios.post(
          `${process.env.REACT_APP_API_URL}/comment-on-comments/2/${comment?._id}`,
          { comment: reply },
          { headers: { Authorization: `Bearer ${token}` } }
        )
      setCommentOnCommetReplyUpdate(!commentOnCommetReplyUpdate);
    } catch (error) {
      console.log(error, "error comment on comment api");
    }
  };


  const getAPiCommentOnComment = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/comment-on-comments/${comment?._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setCommentReplys(res?.data);
    } catch (error) {
      console.log(error, "error in comment Reply");
    }
  };
  const likeOnlikeApi = async () => {
    const token = localStorage.getItem("token");
   if(!token){
    logStatus.setOpenPop(true)
   }else{
    const token = localStorage.getItem("token");
    try {
       await axios.patch(
          `${process.env.REACT_APP_API_URL}/cms/blog-comment/like/${comment?._id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then(() => {
          setLikeStatusOnMainComment(!likeStatusOnMainComment);
          if (likeStatusOnMainComment) {
            setCommentLikeCount(commmentLikeCount - 1);
          } else {
            setCommentLikeCount(commmentLikeCount + 1);
          }
        });
    } catch (error) {
      console.log(error, "error like Api Comment on Comment");
    }
   } 

  };

  useEffect(() => {
    getAPiCommentOnComment();
  }, [commentOnCommetReplyUpdate]);

  const handleReplyHandler = (e) => {
    setReply(e.target.value);
  };

  const onclickOnPostApi = () => {
    commentOnCommentApi();
    setCommentReplyShow(!commentReplyShow);
  };

  const DotComponent = ()=>{
    if(commentReplys.length>0){
      return (
        <>
        <div style={{marginLeft:"22px", marginTop:"-30px",marginBottom:"-15px",color:"#af1c1c"}} className="comment-dot-div">.</div>
        <div style={{marginLeft:"22px", marginTop:"-10px",marginBottom:"-15px",color:"#af1c1c"}} className="comment-dot-div">.</div>
        <div style={{marginLeft:"22px",marginBottom:"-15px",color:"#af1c1c"}} className="comment-dot-div">.</div>
        <div style={{marginLeft:"22px",marginBottom:"-35px",color:"#af1c1c"}} className="comment-dot-div">. . .</div>
        </>
      )
    }
  }
  useEffect(()=>{
    console.log(comment,"Comment DATA");
  },[])
  return (
    <div className="Commment-wrap">
      <Modal
        open={openCommentPop}
        onClose={() => setOpenCommentPop(false)}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <div className="comments-on-comments-outer-conatainer">
          {commentReplys.map((reply) => {
            return (
              <CommentOnComment
                CommentOnCommentID={CommentOnCommentID}
                reply={reply}
              />
            );
          })}
        </div>
      </Modal>
      <div>
        <div className="commment-div-container">
          <div className="like-count-and-button">
            {likeStatusOnMainComment ? (
              <AiFillLike
                onClick={() => likeOnlikeApi()}
                className="Like-button-comment-inbox"
              />
            ) : (
              <AiOutlineLike
                onClick={() => likeOnlikeApi()}
                className="Like-button-comment-inbox"
              />
            )}
            <span className="comment-like-text">{commmentLikeCount}</span>
          </div>
          <div className="comment-userName">
            <p>{comment?.userId?.name}</p>
          </div>
          <div className="comment-text">
            <p>{comment?.content}</p>
          </div>
          {commentReplyShow && <div className="underLine-comment-bottom"></div>}
          {commentReplyShow && (
            <input
              onChange={handleReplyHandler}
              className="input-box-comment-reply"
              placeholder="Enter Your Reply"
            />
          )}
          {commentReplyShow ? (
            <div
              className="like-reply-Container"
              onClick={() => onclickOnPostApi()}
            >
              <sapn className="reply-icon-comment">
                <BsReplyAllFill />
              </sapn>
              Post
            </div>
          ) : (
          localStorage.getItem('token')&& <div
              className="like-reply-Container"
              onClick={() => setCommentReplyShow(!commentReplyShow)}
            >
              <sapn className="reply-icon-comment">
                <BsReplyAllFill />
              </sapn>
              Reply
            </div>
          )}
        </div>
      </div>
      {DotComponent()}
      {commentReplys.slice(0, 1).map((reply) => {
        return (
          <div style={window.screen.width < 560?{marginTop:"10%"}:{}}>
            <CommentOnComment
              CommentOnCommentID={CommentOnCommentID}
              reply={reply}
            />
            {commentReplys.length > 1 && (
              <div
                className="see-more-button"
                onClick={() => setOpenCommentPop(true)}
              >
                See More
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default CommentCard;

//
