import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './getAll.module.css'

const GetAll = () => {

  const history = useHistory()

  const [blogs, setblogs] = useState([])

  useEffect(() => {
    axios.get('https://backend.spiffyyou.com/cms/blog?page=1&limit=1000')
      .then((res) => {
        setblogs(res.data.Blog)
      })
      .catch(console.log)
  }, [])


  return (
    <div>
      {blogs?.map((item, index) => (
        <div className={styles.card} key={index}>
          <img src={item.thumbnailImage} className={styles.thumbnailImage} style={{ height: '300px' }} alt='blog-thumbnail' />
          <br />
          <div>
            <h4>{item.title.slice(0, 55) + '....'}</h4>
            <p>{item.summary.slice(0, 60) + '.....'}</p>
            <button className={styles.editBtn} onClick={() => history.push(`/edit?id=${item?._id}`)}>Edit</button>
          </div>
        </div>
      ))}
    </div>
  )
}

export default GetAll;