import React from "react";
import "./Social.css";
import { BsGlobe } from "react-icons/bs";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

export default function social({ brandPageDataWithid }) {
  return (
    <>
      <div className="social-wrap">
        {brandPageDataWithid?.website&&<div className="website-div social-icon">
          <h1 className="same-heading-brand-page">Website</h1>
          <a target="blank" href={`//${brandPageDataWithid?.website}`} rel="no-referrer">
            {" "}
            <BsGlobe  size={"2rem"} />{" "}
          </a>
        </div>}
       <div className="social-div social-icon">
         {brandPageDataWithid?.socialMedia?.instagram && brandPageDataWithid?.socialMedia?.facebook&&<h1 className="same-heading-brand-page">Social Media</h1>}
          {brandPageDataWithid?.socialMedia?.instagram&&<a target="blank" href={brandPageDataWithid?.socialMedia?.instagram}>
            {" "}
            <FaInstagramSquare  size={"2rem"} />{" "}
          </a>}
          {brandPageDataWithid?.socialMedia?.facebook&&<a target="blank" href={brandPageDataWithid?.socialMedia?.facebook}>
            {" "}
            <FaFacebookSquare  size={"2rem"} />
          </a>}
        </div>
      </div>
    </>
  );
}
{
  /* <div className="social-div">
<div className="social-heading">
    <h1 className="same-heading-brand-page">Social Media</h1>
</div>
<div className="social-icon">
    <img src={instaImg} alt="" />
    <img src={facebookImg} alt="" />
</div>
</div> */
}
