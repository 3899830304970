import {React, useState, useEffect ,useRef} from 'react';
import MustReadCards from '../mustread/MustReadCards';
import "./ResMustRead.css";
import { GrPrevious,GrNext } from "react-icons/gr";
import BlogCards from "../../BlogPage/BlogCards/BlogCards";
import {useHistory} from "react-router"
import axios from 'axios';
import ReadAllButton from '../mustread/ReadAllButton';
import buttonArrowImg from "../../../assets/img/button-arrow.svg";
import reviewimg from '../../../images/reviewimg.png'
import EmptyData from  "../../ui/EmptyData/EmptyData";
import ReactPaginate from 'react-paginate';
export default function ResMustRead({blogTittle}) {
    const [likeBlogList, setLikeBlogList]= useState([]);  
    const [open,setOpen] = useState(false);
    const [pageCount,setPageCount] = useState(1);
    const [pageCountForApi ,setPageCountForApi] = useState(1);
    const history = useHistory()
    const ref = useRef();
    const getPinnedBlogsApiData = async () => {
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/cms/like-blog/user?page=${pageCountForApi}&limit=${2}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setLikeBlogList(response?.data?.Blog);
          setPageCount(response?.data?.totalPage);
        } catch (error) {
          console.log(error, "Error In Blog Api on ProfilePage");      
      }
      };

      const likeBlogApi= async (card)=>{
        const token = localStorage.getItem('token');
       if(token){ 
          try {
             await axios.patch(`${process.env.REACT_APP_API_URL}/cms/like-blog/${card?._id}`,{headers: {"Authorization" : `Bearer ${token}`} })
              .then(()=>{
                const newCards = [...likeBlogList].filter(item=> item?._id !== card?._id)
                setLikeBlogList(newCards);
              
               })
             }   catch (error) {
              console.log(error);
            }
          }
        }

        const scrollfunction=(scrollOffset)=>{
          ref.current.scrollBy({
            top: 0,
            left:scrollOffset,
            behavior: 'smooth'
          })
        }    
      useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
          getPinnedBlogsApiData();
        }
      }, [pageCountForApi]);
        
  const handlePageChange=(e)=>{
  setPageCountForApi((e.selected)+1);
  }

 const pageOffset =0;
    return (
      <div className="ProfilePage-BLogSection-Mob">
       
        <div id="Exploreee" className="bg-div">
          <h1 className="CeraProBold mustread-h1">{blogTittle}</h1>
          
        </div>        
       {likeBlogList.length >0 ? <div className="responsive-mustread-wrap">
            {
                likeBlogList?.map((card,index)=>{
                return <BlogCards  
                setOpen={setOpen}
                pro={false}
                likeBlogApi={likeBlogApi}
                pro={"BlogPage"}
                key={index}
                card={card} />
            })  
            }
            
        </div>:<div className="empty-Data-div-Mob"><EmptyData profilepage={true} Heading={''} ButtonText={"Read More"} EmptyMassage={"No Items In Your Blog List"}/></div>}
        { likeBlogList?.length > 0 &&
            <div style={{marginTop:"-1.2%"}}> 
              <ReactPaginate 
                previousLabel={<GrPrevious className="Prev-Button-Pagination"/>}
                nextLabel={<GrNext className="Next-Button-Pagination"/>}
                pageClassName="All-li-Items"
                pageLinkClassName="li-Item-link"
                previousClassName="Prev-li-Point-main"
                previousLinkClassName="Prev-li-Point"
                nextClassName="Next-BTN-Point-Main"
                nextLinkClassName="Next-BTN-Link"
                breakLabel="..."
                breakClassName="Dot-Break-Point-Main"
                breakLinkClassName="Dot-Break-Point"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="All-Item-Main-Container"
                activeClassName="Item-active-Point"
                forcePage={pageOffset}
           />
           </div>}
            {/* <ReadAllButton nameForClass="read-all-btn-wrap-responsive"/> */}
            {window.screen.width < 560 && <div style={likeBlogList.length >0?{}:{marginTop:"53%"}} className="reviewcontainer">
            <div className="submitareivew">
                <div className="reivewtext">
                    <h1>TRIED A NEW BRAND? SHARE YOUR EXPERIENCE</h1>
                </div>
                <div className="reviewbutton">
                    <button className="reviewbutton-btn" onClick={() => history.push("/discoverpage")}>SUBMIT A REVIEW</button>
                </div>
            </div>
            <div className="reviewimg">
               <img src={reviewimg} alt="this is shop image" />
            </div>
        </div>}
            </div>
    )
}
