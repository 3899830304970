import { React, useState, useEffect, useRef } from "react";
import FreshFindData from "../ui/BrandCards/FreshFindData";
import BannerSection from "./Banner/BannerSection";
import Burbery from "./Burbery/Burbery";
import ImageSlider from "./ImageSlider/ImageSlider";
import Known from "./Known/Known";
import Rate from "./RateIt/Rate";
import Social from "./SocialInfo/Social";
import "./BrandPage.css"; 
import OtherDetails from "./OtherDetails/OtherDetails";
import axios from "axios";
import FitQuality from "../ui/FitAndQuality/FitQuality";
import GoogleMap from "./GoogleMap/GoogleMap";
import { useHistory, useLocation,useParams } from "react-router-dom";
import PopCard from "./PopCard";
import buttonArrowImg from "../../assets/img/button-arrow.svg";
import dotenv from "dotenv";
import TrandingCard from "../ui/BrandCards/TrandingCard"
import EmptyData from "../ui/EmptyData/EmptyData"
import { FaWhatsappSquare, FaFacebookSquare } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { Modal } from "react-responsive-modal";
import YourRatingOnBrandPage from "../Profile/YourReviewPost/YourRatingOnBrandPage";
import { IoIosArrowDown } from "react-icons/io";
import ReactPaginate from 'react-paginate';
import { GrPrevious,GrNext } from "react-icons/gr";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FacebookShareButton,WhatsappShareButton,EmailShareButton} from "react-share";
import CommingSoon from "../../assets/canva3.png";
import { useLocalStorage,useForceUpdate, } from '@mantine/hooks';
import WhiteButtonArrow from "../../assets/img/button-white-arrow.svg";
import { toast } from "react-toastify";
import Bulb from "../../assets/ideaImageBulb.svg"
dotenv.config();


export default function BrandPage(props) {
  const forceUpdate = useForceUpdate();
  const id = useParams();
  const ReviewsButtonState = props.location.state ? props?.location?.state?.ReviewButtonStatus:"";
  const brandId = props.match.params.id?props.match.params.id:props.location.state.cardID;
  const PrePath = props.location.state?.PerPath?props.location.state.PerPath:"";
  const history = useHistory();
  const location = useLocation();
  const [copy ,setCopy] = useState(false)
  const [ratingReviewLikeCount, setRatingReviewLikeCount] = useState();
  // const [brandID,setBrandID] = useState();
  const [brandPageData, setBrandData] = useState([]);
  const [reviewRating, setReviewRating] = useState([]);
  const [brandPageDataWithid, setBrandPageDataWithid] = useState("");
  const [openAnother, setOpenAnother] = useState(false);
  const [brandRatingID ,setBrandRatingID] = useState();
  const [open, setOpen] = useState(false);
  const [openLoginPopUp, setOpenLginPopUp] = useState(false);
  const [starValueonBrandPage, setStarvalueonBrandpage] = useState(props.location.state?.StarRating?props.location.state?.StarRating:0);
  const [ReviewButtonStatus,setReviewButtonStatus] = useState()
  const ref = useRef();
  const refAnother = useRef();
  const [pageCount,setPageCount] = useState(1);
  const [pageCountForApi ,setPageCountForApi] = useState(1);


  const getBranddataWithIdApi = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/brand/${brandId}`
      );
      setBrandPageDataWithid(response?.data);
      response?.data?.userRated&&setStarvalueonBrandpage(response?.data?.userRated?.rating)
    } catch (error) {
      history.push("/discoverpage");
    }
  };
  
  const scrollBottomHandler = (scrollOffset) => {
    refAnother.current.scrollBy({
      top: scrollOffset,
      left: 0,
      behavior: "smooth",
    });
  };

  const getBrandRatingReview = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/rating-review/brand/${brandId}?page=${pageCountForApi}&limit=${3}`
       );
      setReviewRating(response?.data?.RatingReview);
      setPageCount(response?.data?.totalPage);
    } catch (error) {
      console.log(error.toString(),"getting Error From The Review Api");
    }
  };

  const scrollfunction = (scrollOfSet) => {
    window.scrollBy({
      left: 0,
      top: scrollOfSet,
      behavior: "smooth",
    });
  };
  
  useEffect(() => {
    if (!id) {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, []);
  
  
  useEffect(() => {
    getBranddataWithIdApi();
    getBrandapidata();
  
    if (PrePath === location.pathname) {
      const body = document.querySelector("#root");
      body.scrollIntoView({ behavior: "smooth" }, 500);
    }
  }, [brandId, ratingReviewLikeCount,pageCountForApi]);

  useEffect(()=>{
    getBrandRatingReview(); 
  },[pageCountForApi]);

  const getBrandapidata = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/brand/recommendedBrand/${brandId}`);
    setBrandData(response?.data);
  };
  
  const likeRatingReview = async (ratingItem) => {
    const token = localStorage.getItem("token");
   try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/rating-review/like/${ratingItem._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log(error, "Message From Rating Reviewon brandPage");
    }
  };
  
  const handlePageChange=(e)=>{
    setPageCountForApi((e.selected)+1);
  }


 const pageOffset = 0;
 const takeQuize = "https://www.tryinteract.com/share/quiz/60a3278c331f270017e93cd2";

  return (
    <div clssName="Brandpage-wrap-container">
     <div className={location.pathname==="/BrandPage"?"brandPage-CTR":"quiz-and-social-btn-wrap"}>
      <button className="social-btn-wrap">
        <a href="http://instagram.com/" target={"blank"}>
            <img
              src={require("../../assets/img/instagram.svg").default}
              alt=""/>
        </a>

        <a href="http://pintrest.com" target="_blank" rel="noopener noreferrer">
          <img
            src={require("../../assets/img/pintrest.svg").default}
            alt="pintrest"/>
        </a>

        <a href="https://www.facebook.com/people/Spiffy-You/100078406224985/" target="_blank" rel="noopener noreferrer">
          <img
            src={require("../../assets/img/facebook.svg").default}
            alt="facebook"/>
        </a>

      </button>
      
      <button onClick={()=>{history.push({pathname:'/reviewbrand',state:{ 
         Rating_id:brandPageDataWithid?.userRated?brandPageDataWithid?.userRated?._id:brandRatingID,
         BrandID:brandPageDataWithid?._id,
         Brand_name:brandPageDataWithid?.title,
         ratingvalueComes:starValueonBrandPage}})}} className={location.pathname==="/Brandpage"?"brandPage-ctr-con":"brandPage-ctr-con"}>
      {location.pathname==="/"?<p className="text-class-side-com">TAKE YOUR QUIZ</p>:<p className="text-class-side-CTR">WRITE A REVIEW</p>}
        {location.pathname==="/"?<img className="like-button-side-com-H" src={'likeImagexd.svg'} alt="like"/>
        :<img className="like-button-side-com" src={Bulb} alt="Bulb" />}
        {/* HiOutlineLightBulb */}
      </button>
    </div>
      <div ref={ref} className="main-container-brandPage">
        <Modal open={openAnother} onClose={() => setOpenAnother(false)} center>
          <h2>Share</h2>
          <div className="share-modal-wrap">
            <div className="whatsapp share-modal">
            <WhatsappShareButton
                  url={"https://www.spiffyyou.com/discoverpage/"+brandPageDataWithid?._id}
                  quote={"Recommended Brand:"+brandPageDataWithid?.title}
                  hashtag={"#hashtag"}
                  description={"aiueo"}
                  className="Demo__some-network__share-button"
                   ><FaWhatsappSquare color={"brown"} size={"3rem"} /></WhatsappShareButton>
            </div>
            <div className="facebook share-modal">
               <FacebookShareButton
                        url={"https://www.spiffyyou.com/discoverpage/"+brandPageDataWithid?._id}
                         quote={"Recommended Brand:"+brandPageDataWithid?.title}
                        hashtag={"#spiffy"}
                        description={"aiueo"}
                        className="Demo__some-network__share-button"
                    >
                    <FaFacebookSquare color={"brown"} size={"3rem"} />
                  </FacebookShareButton>
            </div>
            <div className="mail share-modal">
               <EmailShareButton
                    url={"https://www.spiffyyou.com/discoverpage/"+brandPageDataWithid?._id}
                       quote={"Recommended Brand:"+brandPageDataWithid?.title}
                    hashtag={" "}
                    description={"aiueo"}
                    className="Demo__some-network__share-button"
                    
                    >
                      <IoIosMail color={"brown"} size={"4rem"} />
                    </EmailShareButton>
            </div>
          </div>
          <div style={{padding:"10px"}} className="link-row">
            <div className="link-for-copy">https://www.spiffyyou.com/discoverpage/{brandPageDataWithid?._id}</div>
            <div style={{marginLeft:"10px"}} className="copy-btn">
            <CopyToClipboard
                 text={"https://www.spiffyyou.com/discoverpage/"+brandPageDataWithid?._id}
                 onCopy={()=>setCopy(true)}>
                  <button className="copy-button">Copy</button>
                </CopyToClipboard>  
            </div>
          </div>
        </Modal>
       
        <PopCard open={open} setOpen={setOpen} />
        <BannerSection />
        <div className="brandpage-section-wrap">
          <div className="brandpage-left-side">
            <div className="first">
              <Burbery
                ReviewsButtonState={ReviewsButtonState}
                setOpenLginPopUp={setOpenLginPopUp}
                starValueonBrandPage={starValueonBrandPage}
                openAnother={openAnother}
                setOpenAnother={setOpenAnother}
                scrollfunction={scrollfunction}
                open={open}
                setOpen={setOpen}
                brandPageDataWithid={brandPageDataWithid}
                ReviewButtonStatus={ReviewButtonStatus}
                setBrandRatingID={setBrandRatingID}
                brandRatingID={brandRatingID}/>
            </div>
            <div className="closing-line"></div>
            <div className="second">
              <Known brandPageDataWithid={brandPageDataWithid} />
            </div>
            <div className="closing-line"></div>
            <div className="third">
              <Rate
                ReviewsButtonState={ReviewsButtonState}
                setOpenLginPopUp={setOpenLginPopUp}
                brandPageDataWithid={brandPageDataWithid}
                starValueonBrandPage={starValueonBrandPage}
                setStarvalueonBrandpage={setStarvalueonBrandpage}
                open={open}
                setOpen={setOpen}
                ratingValue 
                setBrandRatingID={setBrandRatingID}
                brandRatingID={brandRatingID}
                setRatingValue
                setReviewButtonStatus={setReviewButtonStatus}
              />
            </div>
            <div className="closing-line"></div>
            <div className="fourth">
              {brandPageDataWithid ? (
                <FitQuality
                  brandPageDataWithid={brandPageDataWithid}
                  isBrandPage={true}
                />
              ) : (
                ""
              )}
            </div>
            <div className="closing-line"></div>
            <div className="fifth">
              <OtherDetails brandPageDataWithid={brandPageDataWithid} />
            </div>
          </div>

          <div className="brandpage-right-side">

            <div className="right-side-first">
              <ImageSlider brandPageDataWithid={brandPageDataWithid} />
            </div>

            <div className="map-wrap">
              <h2 className="same-heading-brand-page">Store Locator</h2>
              <GoogleMap />
            </div>
            <Social brandPageDataWithid={brandPageDataWithid} />
          </div>
        </div>
        <div className="closing-line"></div>
        <div className="">
          <div className="brandpage-relatedcards-detials">
            <h1 className="CeraProBold">
               Users who like {brandPageDataWithid?.title} also liked
            </h1>
            <div className="main-card-div-Home-FreshFind">
              {brandPageData?.slice(0, 3).map((card) => {
                return (
              <TrandingCard
                card={card}
                heading="User who like burbery also liked"
                bgColor="white"/>);
              })}
            </div>
            <button
              className="discribe-btn"
              onClick={() => history.push("/discoverpage")}>              
              <p>DISCOVER MORE</p>
              <img src={ window.screen.width < 560 ? WhiteButtonArrow : buttonArrowImg } alt=""/>
            </button>
          </div>
        </div>
        <div className="review-section-wrap">
          <div className="Review-card-section-wrap">
            <h1 className="CeraProBold review-heading-brand-page">Reviews.</h1>
            {reviewRating?.length!==0?<div
              className={
                reviewRating?.length > 4
                  ? "reviewpostblock Profile-Page-rating-review-scroll"
                  : "reviewpostblock"
              }
               ref={refAnother}>
              {reviewRating?.map((ratingItem, index) => {
                return (
                  <YourRatingOnBrandPage
                    ratingReviewLikeCount={ratingReviewLikeCount}
                    setRatingReviewLikeCount={setRatingReviewLikeCount}
                    likeRatingReview={likeRatingReview}
                    ratingItem={ratingItem}                   
                  />
                );
              })}              
                </div>:<div className="Empty-component-review empty-com-brandpage">
                <EmptyData   Heading={""}
                  ButtonText={"Read more"}
                  EmptyMassage={
                    "No Review On This Brand"
                  } /></div>}
                    <ReactPaginate 
                      previousLabel={<GrPrevious className="Prev-Button-Pagination"/>}
                      nextLabel={<GrNext className="Next-Button-Pagination"/>}
                      pageClassName="All-li-Items"
                      pageLinkClassName="li-Item-link"
                      previousClassName="Prev-li-Point-main"
                      previousLinkClassName="Prev-li-Point"
                      nextClassName="Next-BTN-Point-Main"
                      nextLinkClassName="Next-BTN-Link"
                      breakLabel="..."
                      breakClassName="Dot-Break-Point-Main"
                      breakLinkClassName="Dot-Break-Point"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName="All-Item-Main-Container"
                      activeClassName="Item-active-Point"
                      forcePage={pageOffset}
                />
            {reviewRating?.length > 4 ? (
              <div className="Arrow-button-scroll-reviews">
                <IoIosArrowDown
                  onClick={() => scrollBottomHandler(500)}
                  className="arrow-button-icon"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {/* <button className="read-more-btn">Read More</button> */}
        </div>
      </div>
    </div>
  );
}
