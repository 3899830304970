import React from "react";
import buttonArrowImgWhite from "../../../assets/img/whiteArrow.svg";
import "./EmptyData.css";
import {useHistory} from "react-router-dom";
function EmptyDataOnBlog({ profilepage, Heading, ButtonText, EmptyMassage }) {
  const history = useHistory()
  return (
    <div
      onClick={()=>history.push("/blogpage")}
      className={
        profilepage
          ? "empty-main-div-container-pro"
          : "empty-main-div-container-Home empty-main-div-container"
      }
    >
      {/* <div
        className={profilepage ? "Heading-container" : "Heading-container-Home"}
      >
        <h1 className="CeraProBold">{Heading}</h1>
      </div> */}
      <div
        className={
          profilepage ? "Massage-container-profile" : "Message-container-Home"
        }
      >
        <div className="Empty-Text">{EmptyMassage}</div>
      </div>
      <div className="Button-container">
        {" "}
        {ButtonText} <img src={buttonArrowImgWhite} alt="" />
      </div>
    </div>
  );
}

export default EmptyDataOnBlog;
