import React from 'react' 
// import discoverBanner from '../../../assets/img/DISCOVER.png';
import discoverImg from '../../../assets/DiscoverBannar.png';
import './discoverbanner.css'
// import covermobile from '../../../images/cover-mobile.svg';
import covermobileDiscover from '../../../assets/DiscoverBannar.png';
function Discoverbanner() {
    return (
        <div className="discoverbanner">    
            {/* <img className="discoverbanner1" src={discoverBanner} alt="Discover Banner" /> */}
            <img className="discoverbanner1" src={discoverImg} alt="Discover Banner" />
            <img className="discoverbanner2" src={covermobileDiscover} alt="mobile image" />
        </div>
    )
}

export default Discoverbanner
