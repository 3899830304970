import {React,useState,useEffect,useContext} from "react";
import "./ResponsiveNav.css";
import "../../Navbar/Navbar.css";
import { Link } from "react-router-dom";
import searchIcon from "../../../assets/img/search.svg";
import spiffyLogo from "../../../assets/img/Spiffy.svg";
import menuIcon from "../../../assets/img/menuIcon.svg";
import {FaUserCircle} from "react-icons/fa";
import {useLocation,useHistory} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import dotenv from "dotenv";
import LogContext from "../../../LogContext";
import fbLogo from "../../../assets/facebook.svg";
import instaLogo from "../../../assets/instagram.svg";
import pinLogo from "../../../assets/pinterast.svg";

dotenv.config();

export default function ResponsiveNav({isLoggedIn ,setisLoggedIn,navShow ,setNavShow, ...props}) {
  const logStatus = useContext(LogContext)
  const searchDataHash={'#WishList':'/profilepage','#Pinned':'profilepage','#Explore':'/','#Trending':'/','#FreshFind':'/'}
  const location = useLocation();
  const [filterItem,setFilterItem] = useState(false);
  const [searchData ,setSearchData] = useState([]); 
  const [searchDataBlog,setSearchDataBlog] =useState([])
  const history = useHistory();
  const [selected ,setSelected] = useState({})
  // const [inputValueMob,setInputValueMob] = useState("");
  
  const handlelogOut =()=>{
    localStorage.setItem('token',"");
    setisLoggedIn(false);
    logStatus.setValue(false);
  }
  const getSearchAPi = async()=>{
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/brand/search/${props.inputValueMob}`,);
      setSearchData(res?.data?.searchedbrand);
      setSearchDataBlog(res?.data?.searchedBlog)
    } catch (error) {
      console.log(error,"Message From NavBar Api")
    } 
  }

  useEffect(()=>{
    if(props.inputValueMob){
      getSearchAPi()
    }
  },[props.inputValueMob])

  const onChangeValue=(e)=>{
    props.setInputValueMob(e.target.value);
   }

  const linkListSearch= (pageValue)=>{
    props.setInputValueMob("")
    if(pageValue === "#Trending"){
      window.open(`https://www.spiffyyou.com/#Trending`,'_parent')
    }else if(pageValue === "#WishList"){
      window.open(`https://www.spiffyyou.com/profilepage/#wishlist`,'_parent')
    }else if(pageValue === "#Pinned"){
      window.open(`https://www.spiffyyou.com/profilepage/#pinned`,'_parent')
    }else if(pageValue=== "#Explore"){
      window.open(`https://www.spiffyyou.com/#Explore`,'_parent')
    }else if(pageValue === "#FreshFind"){
      window.open(`https://www.spiffyyou.com/#freshFinds`,'_parent')
    }
  } 
  const seeMoreFunction =()=>{
    props.setInputValueMob("");  
    history.push("/blogpage");
   }
   const seeMoreFunctionBrnad =()=>{
    props.setInputValueMob("");
    history.push("/discoverpage");
   }
    
   const MAX_LENGTH = 40;

   const itemClickedHandle =(brand)=>{
    props.setInputValueMob("");
     history.push({
       pathname: `/discoverpage/${brand?._id}`,
       state:{cardID:brand?._id},
     })
   }
  
  
  const itemClickedHandleBlog=(blog)=>{
    props.setInputValueMob("");
    history.push({
      pathname: `/blogpage/${blog?._id}`,
      state:{BlogID:blog?._id}
    })
  }
  const activeFunction =()=>{
    if(location.pathname==='/'){
     setSelected({
       Home:true,
       Discover:false,
       Explore:false,
       AboutUs:false,
       Profile:false,
       Login:false,
       Register:false })
    } else if(location.pathname==='/discoverpage'){
     setSelected({
       Home:false,
       Discover:true,
       Explore:false,
       AboutUs:false,
       Profile:false,
       Login:false,
       Register:false })
    }else if(location.pathname==="/blogpage"){
     setSelected({
       Home:false,
       Discover:false,
       Explore:true,
       AboutUs:false,
       Profile:false,
       Login:false,
       Register:false })
    }else if(location.pathname==='/aboutus'){
     setSelected({
       Home:false,
       Discover:false,
       Explore:false,
       AboutUs:true,
       Profile:false,
       Login:false,
       Register:false })
    }else if(location.pathname ==='/profilePage'){
     setSelected({
       Home:false,
       Discover:false,
       Explore:false,
       AboutUs:false,
       Profile:true,
       Login:false,
       Register:false })
    }
    else if(location.pathname ==='/logInPage'){
     setSelected({
       Home:false,
       Discover:false,
       Explore:false,
       AboutUs:false,
       Profile:false,
       Login:true,
       Register:false })
   }
   else if(location.pathname ==='/Register'){
     setSelected({
       Home:false,
       Discover:false,
       Explore:false,
       AboutUs:false,
       Profile:false,
       Login:false,
       Register:true })
    }else if(location.pathname === "/TermAndCondition"){
      setSelected({
        Home:false,
        Discover:false,
        Explore:false,
        AboutUs:false,
        Profile:false,
        Login:false,
        Register:false,
        TermCon:true,
        PrivacyPol:false
      })
    }else if(location.pathname=== "/Privacy"){
      setSelected({
        Home:false,
        Discover:false,
        Explore:false,
        AboutUs:false,
        Profile:false,
        Login:false,
        Register:false,
        TermCon:false,
        PrivacyPol:true
       })
    }
  }
  useEffect(()=>{
   activeFunction()
  },[location]);
  
  useEffect(()=>{
    const token = localStorage.getItem("token");
    if(token){  
  setisLoggedIn(true);
    }else{
    setisLoggedIn(false)
    }
 },[])

 const handleKeyup=(e) =>{
  if(e.keyCode == 13){
    setFilterItem(true);
    setTimeout(() => {
      setFilterItem(false);
    },2)
    }
 }
 
  return (
    <>
    {<Fade left when={navShow}><div className={navShow?('side-nav-bar'):('showNavBar')}>
      <div className="res-nav-link">
        <ul>
          <li onClick={()=>setNavShow(false)} className='Side-nav-bar-li'><Link onClick={activeFunction} className={selected.Home?"link-color-onSelect":""}  to="/">HOME</Link></li>
          <li onClick={()=>setNavShow(false)} className='Side-nav-bar-li'><Link onClick={activeFunction} className={selected.Discover?"link-color-onSelect":""}   to="/discoverpage">DISCOVER</Link></li>
          <li onClick={()=>setNavShow(false)} className='Side-nav-bar-li'><Link onClick={activeFunction}  className={selected.Explore?"link-color-onSelect":""}  to="/blogpage">EXPLORE</Link></li>
          <li onClick={()=>setNavShow(false)} className='Side-nav-bar-li'><Link onClick={activeFunction} className={selected.AboutUs?"link-color-onSelect":""}  to="/aboutus">ABOUT US</Link></li>
          <li onClick={()=>setNavShow(false)} className='Side-nav-bar-li'>{isLoggedIn?<Link className={selected.Profile?"link-color-onSelect":""}  onClick={activeFunction}  to="/profilePage"><FaUserCircle className="profile-icon-Res" /> Profile</Link>:<Link className={selected.Register?"link-color-onSelect":""}  onClick={activeFunction}  to="/register">Sign Up</Link>}   </li>
          <li onClick={()=>setNavShow(false)} className='Side-nav-bar-li'>{isLoggedIn?<Link onClick={handlelogOut} to="/loginpage">LogOut </Link>:<Link     className={selected.Login?"link-color-onSelect":""}  onClick={activeFunction} to="/loginpage">Log in </Link>}</li>

          <li onClick={()=>setNavShow(false)} className='Side-nav-bar-li'><Link onClick={activeFunction} className={selected.TermCon?"link-color-onSelect":""}  to="/TermAndCondition">Terms and Conditions</Link></li>
          <li onClick={()=>setNavShow(false)} className='Side-nav-bar-li'><Link onClick={activeFunction} className={selected.PrivacyPol?"link-color-onSelect":""}  to="/Privacy">Privacy Policy</Link></li>

          <li onClick={()=>setNavShow(false)} className='Side-nav-bar-li'>
          <div onClick={()=>setNavShow(false)} className="social-media-icons-nav">
              <a href="https://www.instagram.com/spiffy.you/" target="_blank"><img src={instaLogo} alt="insta" /></a>
              <a href="https://in.pinterest.com/kikaicomp/_created/" target="_blank"><img src={pinLogo} alt="Pin" /></a>
              <a href="https://www.facebook.com/people/Spiffy-You/100078406224985/" target="_target"><img src={fbLogo} alt="fb" /></a>
          </div>
          </li>
        </ul>
      </div>  
    </div></Fade>}

    <div className={"nav-wrap responsive-nav-wrap"}>
      <div  className="nav-menu responsive-nav-menu">
          <img className="navbar-image-icon" onClick={()=>setNavShow(!navShow)} src={menuIcon} alt="" />
      </div>
     

      <div className={localStorage.getItem("token")?"nav-logo":"nav-logo responsive-nav-logo "} onClick={()=>history.push('/')}>
        <h1 style={{ fontFamily:"CeraProBold",fontSize:"1.6rem" }}> Spiffy </h1>
      </div>
     {localStorage.getItem("token")&&<div className="nav-search">
        <div className="responsive-menu-search">
          <div className="navbar-search-icon-div">
            <img src={searchIcon} alt="" />
          </div>
          <div className="navbar-search-input">
            <input type="text" 
            onKeyUp={handleKeyup}
            inputmode={filterItem?"none":""}
             value={props.inputValueMob}
             onChange={onChangeValue}
             className="search-inputbox-navbar"  placeholder="search" />
          </div>
          <div className={props.inputValueMob?"Search-item-show":"Hide-search-item"}>
            {searchData?.slice(0,5)?.map((brand)=>{
              return <div className="Brand-title-search" onClick={()=>itemClickedHandle(brand)}>{brand?.title}</div>
              })}
             {searchData?.length>0?"":<div className="NoData-inBrands">NO Relevent Brands</div>}
             <div className="Brand-title-search see-More-button-brand color-change" onClick={seeMoreFunctionBrnad}>See More...</div>
              <div className="Blog-section-data-on-Navbar">
  
                <div className="Blog-section-start-border">Blogs</div>
                  {searchDataBlog?.slice(0,5)?.map((blog)=>{return <div className="Brand-title-search" onClick={()=>itemClickedHandleBlog(blog)}> {`${blog?.title.trim().length > MAX_LENGTH ? blog?.title.substring(0,MAX_LENGTH) + '...' : blog?.title}`}</div>})}
                  {searchDataBlog.length>0?"":<div className="NoData-inBlog">NO Relevent Blogs</div>}
              </div>
              <div className="Brand-title-search color-change" onClick={seeMoreFunction}>See More...</div>
             {localStorage.getItem('token')&&<div className="Blog-section-data-on-Navbar">

             <div className="Blog-section-start-border">Page Sections</div>
                  <ul className="">
                    {Object.keys(searchDataHash).map((search)=>{if(search.toLowerCase().includes(props.inputValueMob.toLowerCase())){return(<li onClick={()=>linkListSearch(search)} className="page-section-links">{search}</li>)}})}
                  </ul>
               </div>}
            </div>
        </div>
      </div>}
    </div>
  </>);
}
