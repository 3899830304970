import React from "react";
import "./SubmitBrandBanner.css";
// import submitBrandImg from '../../../assets/img/submit-review.png';
import submitBrandImg1 from '../../../assets/SumbitABrand.png';

export default function SubmitBrandBanner() {
  return (
    <div className="submit-brand-banner-wrap">
      {/* <div className="submit-banner-img">
        <img src={submitBrandImg} alt="" />
      </div>
      <div className="submit-banner-text">
        <h1 className="CeraProBold">Submit A New Brand</h1>
        <p>
          Found your perfect fit? psy it forword and share with your community
          at spiffy. Submit a few detail about your favourite barnd
        </p>
      </div> */}
      <img src={submitBrandImg1} alt="" />
    </div>
  );
}
