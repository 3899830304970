import {React,useState,useEffect} from "react";
import "./BrandDetail.css";
import { HiInformationCircle } from "react-icons/hi";
import ReactStars from "react-rating-stars-component";
import { BsStarFill,BsStarHalf,BsStar } from "react-icons/bs";
import axios from 'axios';
import {toast} from "react-toastify";
import { useHistory} from "react-router-dom";
import dotenv from 'dotenv';
dotenv.config();

export default function BrandDetail(props) {
   const [brandName,setBrandName] = useState("");
   const [showMessage,setShowMessage] = useState(true);
   const [brandReview,setBrandReview] = useState("");
   const [brandWeb,setBrandWeb] = useState('');
   const [ratingStar,setRatingStar] = useState(0);
   const history = useHistory();
   const submitBrandApi = async ()=>{
     const token = localStorage.getItem("token");
    try {
        let brandNameUpper = brandName[0].toUpperCase() + brandName.substring(1);
        console.log(brandNameUpper,"brand Name In Upper Case")
        await axios.post(`${process.env.REACT_APP_API_URL}/recommended-brand`,
        {name:brandNameUpper,
          review:brandReview,
          rating:ratingStar,
          website:brandWeb},
        {headers: {"Authorization" : `Bearer ${token}`} })
        .then((res)=>{
          console.log("Hello from Sumbit brand")
          setBrandReview("");
          props.setEditOpenSumbit(true);
          popUpShow();
          history.goBack();
        })  
        } catch (error) {
        console.log(error);
        history.goBack();
      }
  
    }
   const onChangehandler =(e)=>{
     setBrandName(e.target.value);
   }
   const textAreaValueHandler=(e)=>{
    setBrandReview(e.target.value);
   }
   const webLinkHandler =(e)=>{
    setBrandWeb(e.target.value)
   }
   const ratingValue=(newValue)=>{
    setRatingStar(newValue);
   }
   const popUpShow =()=>{
     setTimeout(() => {
      props.setEditOpenSumbit(false)
     }, 5500);
   }

   useEffect(()=>{
     const token = localStorage.getItem("token")
     if(!token){
        history.push("/logInPage");
     }
       },[]);
       
       useEffect(()=>{
        setTimeout(() => {
          setShowMessage(false)
        }, 30000);
      },[])

      const popHandlerInnerDiv=(ConValue)=>{
        if(ConValue===false){
          setShowMessage(true);
        }
      }
      const popHandlerOuterDiv=(ConValue)=>{
        if(ConValue===true){
          setShowMessage(false)
        }
      }
    


  return (
    <div  onClick={()=>popHandlerOuterDiv(showMessage)} style={window.screen.width < 540 ?{marginLeft:"-5.8rem"}:{}}  className="brand-detail-wrap">
      <div className="brand-detail-section">
        <div className="detail-para submitbrand-row-padding">
          {/* <h3 className="brand-subtitle">
            It takes <span className="brand-detail-title-span">less then a minites</span>  to help others make great choices.
          </h3> */}
        </div>
      
        <div  className="brand-name-feild submitbrand-row-padding">
          <lable className="lable required">Brand Name </lable>
          <input
          style={{width: '9.7rem'}} 
          value={brandName} onChange={onChangehandler} type="text" className={"submit-brand-input submit-brand-input-responsive"} />
        </div>
        <div className="take-rating submitbrand-row-padding">
          <label className="lable required">Your Rating </label>
          {/* {arrstar.map((index)=>{
              return <img  className="submit-brand-star" src={brandStar} alt="" />
          })} */}
          <ReactStars
             size={40}
             count= {5}
             value={ratingStar}
             onChange={ratingValue}
             color= {"black"}
             emptyIcon={<BsStar  className="Rating-start-BrandPage Rating-start-reviewPage" />}
             halfIcon={<BsStarHalf  className="Rating-start-BrandPage Rating-start-reviewPage-fill"  />}
             filledIcon={<BsStarFill  className="Rating-start-BrandPage Rating-start-reviewPage-fill"  />}
             activeColor={"#AF1C1C"}
          />
        </div>
        <div className="review-box-wrap brand-detail-wrap submit-text-review submitbrand-row-padding" >
          <label className="lable " style={{alignSelf:"flex-start"}}>Review
           <span className="information-icon submit-brand-i-button"> 
           {(showMessage )&&<div className="submit-brand-Rating-Message">     
          Dear valued member
          We are trying very hard to build this trustworthy community. Hence, please let 
          them know how your experience with this label was. Share something that you 
          greatly love about this label like may be its value for money, their designs, variety, 
          customer service, easy returns, etc.
          Your honest review here can make a great collective impact. However, lets also try 
          to be respectful in our language.
          We will not hide any reviews or manipulate them in any way
      </div>}
           <HiInformationCircle  className="" onClick={()=>popHandlerInnerDiv(showMessage)}  />
           </span>
           </label>
          <div>
          <textarea
            name="title"
            className="submit-brand-input submit-brand-input-responsive"
            id=""
            style={window.screen.width < 560 ? {marginLeft:"56%"}:{}}
            value={brandReview}
            onChange={textAreaValueHandler}
            cols={window.screen.width < 560 ?"18":"40"}
            rows={window.screen.width < 560 ?"1":"10"}
          ></textarea>
          <div className={"SumbitBrand-length  MR-left-SumbitPage"}><span className={brandReview.length>150?"submit-counter-color":"submit-counter-color-G"}>{brandReview.length}</span>/150</div>
          </div>
        </div>

             <div className="brand-website submitbrand-row-padding">
                <label  className="lable">Brand Website </label>
                <input   style={{width: '9.9rem'}}  value={brandWeb} onChange={webLinkHandler} className="submit-brand-input submit-brand-input-responsive" type="text" name="" id="" />
             </div>
            <div className="submit-brand-btn-div submitbrand-row-padding">
                <button onClick={()=>submitBrandApi()} className="submit-brand-btn">Submit</button>
            </div>
      </div>
    </div>
  );  
}
