import {React,useEffect,useState,useRef} from 'react'
import './yourreviewcontainer.css'
import Yourreviewpost from '../YourReviewPost/Yourreviewpost'
import EmptyData from "../../ui/EmptyData/EmptyData"
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { GrPrevious,GrNext } from "react-icons/gr";
import { IoIosArrowDown } from "react-icons/io";
function Yourreviewcontainer() {
    const [userReviewRating,setUserReviewRating] = useState([]);
    const [pageCount,setPageCount] = useState(1);
    const [pageCountForApi ,setPageCountForApi] = useState(1)
    const ref = useRef();
   const scrollBottomHandler=(scrollOffset)=>{
    ref.current.scrollBy({
      top: scrollOffset,
      left:0,
      behavior: 'smooth'
    })
   }
    const getUserRatinReview = async () => {
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/rating-review/user?page=${pageCountForApi}&limit=${3}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setUserReviewRating(response?.data?.RatingReview);
          setPageCount(response?.data?.totalPage);
        } catch (error) {
          console.log(error, "Error In ProfilePage In UserRating Api");
        }
      };
      const [valueofReview,setValueOfReview] = useState(false)
      useEffect(()=>{
        const token = localStorage.getItem("token");
        if(token){  
        getUserRatinReview();
      }
      },[pageCountForApi,valueofReview])
      
const handlePageChange=(e)=>{
  setPageCountForApi((e.selected)+1);
  setValueOfReview(!valueofReview);
 }
 const pageOffset =0;
    return (
        <div id="yourreviews" className="yourreviewcontainer">
            <div className="yourReview-Heading-con">
            <h1  className="reviewheading CeraProBold">#YourReviews</h1>
            <p   className="CeraProLight review-pera-cont">All your fabulous reviews that help this community make better fashion choices.</p>
            </div>
           {userReviewRating?.length !==0?<div className={userReviewRating?.length > 6?("reviewpostblock Profile-Page-rating-review-scroll" ):("reviewpostblock")} ref={ref}>
                {userReviewRating?.map((reviewItem , index)=>{return <Yourreviewpost  key={reviewItem?._id} reviewItem={reviewItem}  />}) }
            </div>:<div style={window.screen.width <560 ?{marginTop:"-10rem"}:{}} className="EmptyData-ProfilePage"><EmptyData
                   wishListPage={true}
                  Heading={""}
                  ButtonText={"Review a brand today"}
                  EmptyMassage={
                    "Love a brand? Share it with our Spiffy community. "
                  } /></div>}
                 {userReviewRating?.length !==0&&  <ReactPaginate 
                previousLabel={<GrPrevious className="Prev-Button-Pagination"/>}
                nextLabel={<GrNext className="Next-Button-Pagination"/>}
                pageClassName="All-li-Items"
                pageLinkClassName="li-Item-link"
                previousClassName="Prev-li-Point-main"
                previousLinkClassName="Prev-li-Point"
                nextClassName="Next-BTN-Point-Main"
                nextLinkClassName="Next-BTN-Link"
                breakLabel="..."
                breakClassName="Dot-Break-Point-Main"
                breakLinkClassName="Dot-Break-Point"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="All-Item-Main-Container"
                activeClassName="Item-active-Point"
                forcePage={pageOffset}/>}
            {userReviewRating?.length > 4?<div className="Arrow-button-scroll-reviews"><IoIosArrowDown onClick={()=>scrollBottomHandler(500)} className="arrow-button-icon" /></div>:""}
        </div>
    )
}

export default Yourreviewcontainer
