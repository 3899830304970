import dotenv from 'dotenv';
import { FacebookProvider, LoginButton } from 'react-facebook';
import {React,useState,useContext,useEffect} from 'react'
import "./AuthStyle/RegisterPage.css";
import {MdOutlineVisibilityOff,MdOutlineVisibility} from 'react-icons/md';
import {Formik,Form,Field} from "formik"
import * as Yup from 'yup';
import axios from 'axios';
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin } from 'react-google-login';
import {FcGoogle} from 'react-icons/fc'
import { FaFacebookSquare } from "react-icons/fa";
import Country from './Country';
import ApiRoutes from "../../utils/apiConstant"
import {  ref } from "yup";
import signupImg from '../../assets/img/signup.png';
import cornerImage from "../../assets/ImageAnother/AuthBackGroundImage.png";
import LogContext from '../../LogContext';
dotenv.config();


function Register(props) {
    const logStatus = useContext(LogContext);
    const [isRevealed ,setIsRevealed] = useState(false);
    const [isRevealedAnother ,setIsRevealedAnother] = useState(false);
    const BrandName= props.location.state?props.location.state.BrandName:"";
    const RatingValue = props.location.state?props.location.state.RatingValue:"";
    const BrandID = props.location.state?props.location.state.BrandID:"";
    const [privacyIsChecked,setPrivacyIsChecked] = useState(false);
    const [countryNameSelected,setCountryNameSelected] = useState();
    const history = useHistory();

    useEffect(()=>{
      const token= localStorage.getItem('token');
      if(token){
        history.push("/");
      }
    },[])
    
    const Reloadfunction=()=>{
      // setTimeout(() => {
      //   window.location.reload();
      // },2000)   
    }
    
    
    const schema =
      Yup.object().shape({
        email:Yup.string().email("invalid Email Address").required("Enter  Email"),
        fullName:Yup.string().required("Enter Name"),
        password:Yup.string().min(5,"Password must be at least 5 characters").required("Enter Password"),
        confirmPassword: Yup.string().required("Please confirm your password").oneOf([ref("password")], "Passwords do not match"),
        
      })

    const handleSubmitData = async (values)=>{ 
      if(privacyIsChecked){
        await  axios.post(ApiRoutes.register , {
          email:values.email,
          name: values.fullName,
          password:values.password,
          location:countryNameSelected
        })
        .then(function (response) {
          localStorage.setItem("token",response.data.token);
          localStorage.setItem("EmailName",response?.data?.user?.email) 
          props.setisLoggedIn(true)
          props.setisReload(true)
          props.setRegisteration(true)
          logStatus.setValue(true)
          if(props.location.hash){
            console.log(BrandID,RatingValue,BrandName,"LoginPage Data")
            history.push({pathname:"/reviewbrand",
             state:{
                BrandID:BrandID,
                ratingvalueComes:RatingValue,
                Brand_name:BrandName},
                hash:"RegisterPage"
              });
            } else if(response?.data?.user?.emailVerified === false){
              console.log("In The Email Verification");
              history.push('/EmailVerifycation')
            }
            else{
              history.push("/");
            }
        })
        .then(()=>{ 
          Reloadfunction()
        })
        .catch(function (error) {
          console.log("error message",error.response.data.error);
          toast(error.response.data.error,{type:"error"});// putting Meaning Full Error
    });
      }else{
        toast.error("Please Agree The  Privacy Policy")
      }  
    }   
    const handleLoginonGoogle = async googleData => {
     await axios.post(ApiRoutes.googleLogin, {
        token:googleData.tokenId
      })
      .then(function (response) {
        localStorage.setItem("token",response.data.token);       
        props.setisLoggedIn(true);
        props.setisReload(true);
        logStatus.setValue(true)
        
        props.setRegisteration(true)
        if(props.location.hash){
          console.log(BrandID,RatingValue,BrandName,"LoginPage Data")
          history.push({pathname:"/reviewbrand",
           state:{
              BrandID:BrandID,
              ratingvalueComes:RatingValue,
              Brand_name:BrandName},
              hash:"RegisterPage"
            });
          }else{
            history.goBack();
          }
      
      }).then(()=>{Reloadfunction()})
      .catch(function (error) {
        console.log("error message",error.response.data.error);
        toast(error.response.data.error,{type:"error"});// putting Meaning Full Error
      });
    };
    const  handleResponse =async (data) => {
    await axios.post(ApiRoutes.facebook, {
      token:data.tokenDetail.accessToken
      })
      .then(function (response) {     
        localStorage.setItem("token",response.data.token);
        props.setisLoggedIn(true)
        props.setisReload(true);
        props.setRegisteration(true);
        logStatus.setValue(true)

        if(props.location.hash){
          console.log(BrandID,RatingValue,BrandName,"LoginPage Data")
          history.push({pathname:"/reviewbrand",
           state:{
              BrandID:BrandID,
              ratingvalueComes:RatingValue,
              Brand_name:BrandName},
              hash:"RegisterPage"
            });
          }else{
            history.goBack();
          }
      }).then(()=>{
        Reloadfunction()
      })
      .catch(function (error) {    
       // putting Meaning Full Error
      });
    }
    const handleError = (error) => {
      console.log(error);
    }
    const checkPrivacy =()=>{
      setPrivacyIsChecked(!privacyIsChecked)
    }
    const handleSelectValue=(e)=>{
      setCountryNameSelected(e.target.value)
    }
    useEffect(()=>{
      console.log("Country name From useEffect",countryNameSelected);
    },[countryNameSelected])
    return (
        <div className="main-conatianer-Register">
           <div className="image-container-Register">
              <img className="middel-image-Register userSelect" alt="middel-img" src={signupImg} />
           </div> 
           <div className="logIn-container-Register"> 
           <img src={cornerImage} className={"Bear-image-Register"} alt=""/>             
               <div className="Login-section-Register">

               <div className="Register-Heading-container">
                <h1 className="userSelect  logText CeraProMedium">Be a Spiffy Member</h1>
                {/* <p className="Register-sub-Heading userSelect ">
                  Fashion Curated by community
                </p> */}
               </div>
               <Formik
               initialValues={{
                    email:'',
                    fullName:'',
                    password:'',
                    confirmPassword:'',
                    country:''
                }}
                validationSchema={schema}
                onSubmit={values=>{
                    handleSubmitData(values);                    
                             
                }}
                render={({errors,values,touched})=>(
                <Form> 
                 <div>
                 {/* <label className="labal-Register">Name</label> */}
               <Field name="fullName"  className="inputBox-Register email-Input CeraProMedium" placeholder="Name" type="text"/>
                {errors.fullName && touched.fullName ? (<div className="errormassage-Register">{errors.fullName}</div>):(null)}
                </div>
                 
                <div>
                {/* <label className="labal-Register">Email ID</label> */}
                <Field name="email"  className="inputBox-Register email-Input CeraProMedium" placeholder="Email" type="email"/>
                {errors.email && touched.email ? (<div className="errormassage-Register">{errors.email}</div>):(null)}
               </div>
                
                <div>  <span>
                {/* <label className="labal-Register">Password</label> */}
                  <Field name="password" className="inputBox-Register password-Input CeraProMedium" placeholder="Password" type={isRevealed?"text":"password"}/>
                  {isRevealed?(<MdOutlineVisibility className="visiableIcon-Register  userSelect" onClick={()=>setIsRevealed(!isRevealed)} />):(<MdOutlineVisibilityOff className="visiableIcon-Register   userSelect"  onClick={()=>setIsRevealed(!isRevealed)}/>)}
                  {errors.password && touched.password ? (<div className="errormassage-Register">{errors.password}</div>):(null)}
                </span></div>

                <div>  <span>
                {/* <label className="labal-Register">Password</label> */}
                  <Field name="confirmPassword" className="inputBox-Register password-Input CeraProMedium" placeholder="Confirm Password" type={isRevealedAnother?"text":"password"}/>
                  {isRevealedAnother?(<MdOutlineVisibility className="visiableIcon-Register  userSelect" onClick={()=>setIsRevealedAnother(!isRevealedAnother)} />):(<MdOutlineVisibilityOff className="visiableIcon-Register   userSelect"  onClick={()=>setIsRevealedAnother(!isRevealedAnother)}/>)}
                  {errors.confirmPassword && touched.confirmPassword ? (<div className="errormassage-Register">{errors.confirmPassword}</div>):(null)}
                </span></div>
           
               
                <div> 
                {/* <label className="labal-Register">Country</label> */}
                <select onChange={(e)=>setCountryNameSelected(e.target.value)}   name="country" as="select"  className="inputBox-Register country-Input CeraProMedium" placeholder="Country"  type="text">
                   {/* <div className="option-List-Register-Page-div"> */}
                 {Country.map((CountryName)=>{
                         return <option  className="country-list-option" value={CountryName==="Origin Country"?"":CountryName} label={CountryName==="Origin Country"?"Select Country":CountryName} >{CountryName==="Origin Country"?"Select Country":CountryName}</option>
                 })}
                 {/* </div> */}
                </select>
                  </div>
                  {errors.country && touched.country ? (<div className="errormassage-Register">{errors.country}</div>):(null)}
                <div className="Button-section">
                <div className="Privacy-section">
                  <input onClick={()=>checkPrivacy()} className="Privacy-Policy-input" type="checkbox" name="Privacy-Policy"/>
                  <label onClick={()=>history.push("/privacy")} style={{cursor:"pointer"}} className="Privacy-Policy-label">Agree To Privacy Policy</label>
                </div>
                <button  type="submit" className="submit-button-Register CeraProBold userSelect">JOIN US</button>
                  <div className="Border-Register-container"><div className="Border-Register"></div><span className="border-text">or</span><div className="Border-Register"></div></div>
                     <div className="Social-Buttons-Register">         
                <GoogleLogin
                  clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                  render={renderProps => (
                    <button className=" submit-button-social-Register CeraProBold  userSelect" onClick={renderProps.onClick} disabled={renderProps.disabled}> <FcGoogle className="Social-icon-google" /> Continue with Google</button>
                         )}
                  buttonText="Log in with Google"
                  onSuccess={handleLoginonGoogle}
                  // onFailure={handleLoginonGoogle}
                  onFailure={(error)=>console.log(error,"error")}
                  cookiePolicy={'single_host_origin'} 
                  />
                      <FacebookProvider appId="1438295313238413">
                      <LoginButton
                        className="submit-button-social-login  CeraProBold userSelect Social-Buttons "
                        scope="email"
                        onCompleted={handleResponse}
                        onError={handleError} >
                        <span  className="social-btn-container">  <FaFacebookSquare  className="Social-icon-fb" />Continue with Facebook</span>
                      </LoginButton>
                    </FacebookProvider>
                      </div>
                      <div className="Already-a-member userSelect">Already a member?<span onClick={()=>history.push("/loginpage")} className="userSelect SignIn-text">Sign in</span></div>
                  </div>
                </Form>
                )}             
                />
               </div>            
           </div>
       </div>
   )
}

export default Register
