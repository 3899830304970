import React from 'react'
import "./CardComponent.css";
import BadalImage from "../../../assets/badal.png";
function CardCoponent() {
  return (
 
    <div className="Main-container-CardCompo">
      <div>Can's Find what yor are Looking For ?</div>
      <div className="card_badal_image"><img src={BadalImage} alt="card_Image" /></div>
      <div>Fret Not !</div>
      <div>just let us Know The Brand Name</div>
      <div className="Button_Recommend_Brand">Recommend A Brand</div>
    </div>
   
  )
}

export default CardCoponent
