import {React,useState,useEffect,useContext} from "react";
import "./ButtonOnBrand.css";
import { FaHeart, FaStar } from 'react-icons/fa'
import { IoLocationSharp, IoShareSocialSharp } from 'react-icons/io5'
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import dotenv from 'dotenv';
import LogContext from '../../../LogContext';
dotenv.config();
export default function   ButtonOnBrand({brandRatingID ,setBrandRatingID, ReviewButtonStatus,setOpenLginPopUp,starValueonBrandPage, brandPageDataWithid, setOpen,  scrollfunction,  openAnother,  setOpenAnother, ratingValue, setRatingValue,ReviewsButtonState}) {
  const [like,setLike] = useState(false);
  const logStatus = useContext(LogContext);
  const history = useHistory();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const userID = localStorage.getItem("userID");
    if (token) {
      if (brandPageDataWithid?.wishList?.includes(userID)) {
        setLike(true);
      } else {
        setLike(false);
      }
    }
  }, [brandPageDataWithid?.wishList]);

  const checkLogin=()=>{
    const token = localStorage.getItem("token");
    if(token) {
      history.push({pathname:'/reviewbrand',
      state:{ 
        Rating_id:brandPageDataWithid?.userRated?brandPageDataWithid?.userRated?._id:brandRatingID,
        BrandID:brandPageDataWithid?._id,
        Brand_name:brandPageDataWithid?.title,
        ratingvalueComes:starValueonBrandPage}})
   
    }else{
      logStatus.setOpenPop(true)
    }
  }

  const getWishListapi = async()=>{
    const token = localStorage.getItem("token");
    try {
     await axios.patch(`${process.env.REACT_APP_API_URL}/brand/wish-list/${brandPageDataWithid?._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log(error, "message from TrandingFile");
    }
   
  }

  const onClickHandler = () => {
    const token = localStorage.getItem("token");
    if (token) {
      if (like === true) {
        setLike(false);
        getWishListapi();
      } else if (like === false) {
        setLike(true);
        getWishListapi();
      }
    } else {
      // setOpenLginPopUp(true);
      logStatus.setOpenPop(true)
    }
  };
 
  return (
    <div className="button-row-wrap">
     {!ReviewsButtonState&&<button
        className="row-button same-subheading-brand-page"
        onClick={()=>checkLogin()}
      >
        <FaStar color={"brown"} size={"1rem"} className="visibleimage"/>
        <FaStar color={"white"} size={"1rem"} className="hoverimages" />
        Add a review
      </button>}
      <button onClick={onClickHandler} className={like?"row-button-like-sl same-subheading-brand-page":"row-button-like same-subheading-brand-page"} >
        <FaHeart size={"1rem"} className={like?"visibleimage-like-sl":"visibleimage-like"}/>
          Wish List
      </button>

      <button onClick={() => setOpenAnother(true)} className="row-button same-subheading-brand-page">
        <IoShareSocialSharp color={"brown"} size={"1.3rem"} className="visibleimage"/>
        <IoShareSocialSharp color={"white"} size={"1.3rem"} className="hoverimages"/>
        Share
      </button>
      <button onClick={() => scrollfunction(+500)} className="row-button  same-subheading-brand-page">
        <IoLocationSharp color={"brown"} size={"1.3rem"} className="visibleimage"/>
        <IoLocationSharp color={"white"} size={"1.3rem"} className="hoverimages" />

        Location
      </button>
    </div>
  );
}
