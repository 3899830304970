import dotenv from "dotenv";
import { React,useState,useRef,useEffect,useContext } from "react";
import "./ResDiscoverCard.css";
import { FaStar } from "react-icons/fa";
import shareImg from "../../../assets/img/Share.svg";
import ReactStars from "react-rating-stars-component";
import { BsStarFill, BsStarHalf} from "react-icons/bs";
import AvailableDelivery from "../../ui/AvailableDelivery/AvailableDelivery";
import { useHistory } from "react-router-dom";
import { MdImageNotSupported } from "react-icons/md";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { Modal } from "react-responsive-modal";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import { FaWhatsappSquare, FaFacebookSquare } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FacebookShareButton,WhatsappShareButton,EmailShareButton} from "react-share";
import CommingSoon from "../../../assets/canva3.png"
import LogContext from "../../../LogContext";

dotenv.config();

export default function ResDiscoverCard({
  card,BrandapiData,bgColor,userID,setRatingStatus,ratingStatus
}){
  const MAX_LENGTH = 9;
  const [enter, setEnter] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [showRatingValue, setShowRatingValue] = useState(false);
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const [wishListRefresher, setWishListRefresher] = useState(false);
  const [open,setOpen] = useState(false);
  const [copy ,setCopy] = useState(false);
  const [locationIcon,setLocationIcon] = useState(true);
  const [emptyStarsLogOutUser,setEmptystarsLogOutUser] = useState();
  const [ratingID,setRatingID] = useState("");
  const ref = useRef();
  const logStatus = useContext(LogContext);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const userID = localStorage.getItem("userID");
    if (token) {
      if (card?.wishList?.includes(userID)) {
        setWishListRefresher(true);
      } else {
        setWishListRefresher(false);
      }
    }
  }, [card]);

  const scrollfunction = (scrollOffset) => {
    ref.current.scrollBy({
      top: 0,
      left: scrollOffset,
      behavior: "smooth",
    });
  };
  const checkLogIn = ( brandName,) => {
    const token = localStorage.getItem("token");
    if (token === "") {
      // setOpenLoginPopUp(true);
      logStatus.setOpenPop(true);
    } else {
      history.push({
        pathname:"/reviewbrand",
        state: {         
          Rating_id:ratingID?ratingID:card?.userRated?._id,
          Brand_name: brandName,
          BrandID:card?._id,
          ratingvalueComes:card?.userRated?card?.userRated?.rating:ratingValue,         
        },
      });
    }
  };
  
  
  const ratingStarsAPi=async(starValue)=>{
    try {
    const token = localStorage.getItem("token");  
    const res =  await axios.post(`${process.env.REACT_APP_API_URL}/rating-review/}`,{
     brandId:card?._id,
     rating:starValue
    },{headers:{Authorization: `Bearer ${token}`}})    
    setRatingID(res?.data?._id);
    setShowRatingValue(true);
    } catch (error) {
      console.log(error,"message from ratingStarsApi")
    }
  }

  const ratingchanged = (newValue) => {
    const token = localStorage.getItem("token");
    if(token) {
      ratingStarsAPi(newValue)
      setRatingValue(newValue);
    }else{
      logStatus.setOpenPop(true);
      setRatingValue(0);
      setEmptystarsLogOutUser(Math.random());
    }
  };

  const history = useHistory();

  const getWishlistApiData = async () => {
    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/brand/wish-list/${card._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log(error, "message from TrandingFile");
    }
  };

  const onClickHandler = () => {
    const token = localStorage.getItem("token");
    if (token) {
      if (wishListRefresher === true) {
        setWishListRefresher(false);
        getWishlistApiData();
      } else if (wishListRefresher === false) {
        setWishListRefresher(true);
        getWishlistApiData();
      }
    } else {
      // setOpenLoginPopUp(true);
      logStatus.setOpenPop(true);

    }
  };
  
  const locationUrlChecker=()=>{
     if(card?.website){
       return card?.website
     }else if(card?.socialMedia.instagram){
       return card?.socialMedia.instagram
     }else if(card?.socialMedia.facebook){
       return card?.socialMedia.facebook
     }else{return setLocationIcon(false)}
  }

  let fullStars = new Array(5);
  fullStars.fill(0);

  const handleError =(e)=>{
      e.target.src=CommingSoon
  }
  const starValue =()=>{
   
    if(logStatus.value){
      return card?.userRated?.rating?card?.userRated?.rating:ratingValue;
    }else{
      return 0;
    }
  }
  const editvalue=()=>{
    if(logStatus.value){return card?.userRated?false:true}else{return true}
  }

 

  return (
    <div className="card-1 discover-card-1 res-discover-card-1">
    
      <div className="card-img-div">
        <AvailableDelivery
          showAvailableDeliveryCard={card?.deliversWorldWide}
        />
        {/* {card?.logo?<img className="pointer-container" onClick={()=>history.push({pathname:'/BrandPage',state:card?._id})} src={`${process.env.REACT_APP_API_URL}${card?.logo}`} alt="" />:<MdImageNotSupported className="Empty-Image-Card" />} */}

        {enter ? (
          <div
            className="BrandCard-image-Discover scrollImage-container"
            onMouseLeave={() => setEnter(false)}>

          <Carousel
              autoPlay={true}
              infiniteLoop={true}
              interval={1000}
              dynamicHeight={true}
              showStatus={false}
              swipeable={false}
              showThumbs={false}
            >
              <div
                onClick={() =>
                  history.push({ pathname: `/discoverpage/${card?._id}`, state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed}})
                }
                className="image1 BrandCard-image-Discover pointer-container "
              >
                {card?.images?(
                  <img
                    src={`${card?.images[0]}`}
                    className="pointer-container  "
                    alt=""
                    onError={handleError}
                    />
                ) : ( <MdImageNotSupported className="Empty-Image-Card-Tranding-brandPage" />
                )}
              </div>
              <div
                onClick={() =>
                  history.push({ pathname: `/discoverpage/${card?._id}`,state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating}})
                }
                className="image1 BrandCard-image-Discover pointer-container"
              >
                {card?.images ? (
                  <img
                    src={`${card?.images[1]}`}
                    alt=""
                    className="pointer-container"
                    onError={handleError}
                  />
                ) : (
                  <MdImageNotSupported className="Empty-Image-Card-Tranding-brandPage" />
                )}
              </div>
            </Carousel>
          </div>
        ) : card?.logo ? (
          <div
            className="BrandCard-image-Discover"
            onMouseEnter={() => setEnter(true)}
          >
            <img
              className="pointer-container brandData-image"
              onClick={() =>
                history.push({
                  pathname: `/discoverpage/${card?._id}`,
                  state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed}
                })
              }
              src={`${card?.logo}`}
              alt=""
              onError={handleError}
            />
          </div>
        ) : (
           <img 
            onClick={() =>
                history.push({
                  pathname: `/discoverpage/${card?._id}`,
                  state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed}
            })
          } src={CommingSoon} alt="" />
        )}
      </div>
      <div className="card-first-detail discover-card-first-detail  res-discover-card-first-detail">
        <div className="first-detail-title">
          <div
            onClick={() =>
              history.push({ pathname: `/discoverpage/${card?._id}`, state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed}})
            }
            className="title discover-title CeraProMedium res-dis-card-title .res-dis-card-title"
          >{`${
            card?.title.trim().length > MAX_LENGTH
              ? card?.title.substring(0, MAX_LENGTH) + "..."
              : card?.title
          }`}</div>
          <div className="country discover-card-country CeraProLight">
            {card?.city}
          </div>
        </div>
        <div className="first-detail-rating discover-first-detail-rating">
          <div className="rating-row-1 discover-rating-row-1 res-discover-rating-row-1">
            {fullStars.map((star, index) => {
              return index < parseInt(card?.ratingAvg) ? (
                <FaStar color={"brown"} />
              ) : (
                <FaStar color={"#707070"} />
              );
            })}
          </div>

          <div className=" discover-rating-row-2">
            <div className="rating-num discover-page-rating-num CeraProMedium">
              {parseInt(card?.ratingCount)}
              {card?.ratingCount <= 1 ? "  Rating":"  Ratings"}
            </div>
            <div className="rating-point  res-rating-point discover-page-rating-point CeraProLight">
              {/* Rating Coun*/}
            </div>
          </div>
        </div>
      </div>
      <div className="tag-row-tranding res-tag-row">
        {card?.knownForTag?.length < 1 ? (
          <div className="Hide-knonwForTagArrow-Discover"></div>
        ) : (
          <div>
            {" "}
            <MdKeyboardArrowLeft
              onClick={() => scrollfunction(-100)}
              className="ScrollButton-knowForTag-D"
            />
          </div>
        )}
        <div className="KnowForTag-scroll-div" ref={ref}>
          {" "}
          {card?.knownForTag?.map((Tag) => {
            return (
              <div className="tagsD discover-tags">
                <h4>{Tag?.title}</h4>
              </div>
            );
          })}
        </div>
        {card?.knownForTag?.length < 1 ? (
          <div className="Hide-knonwForTagArrow-Discover"></div>
        ) : (
          <div>
            <MdKeyboardArrowRight
              onClick={() => scrollfunction(+100)}
              className="ScrollButton-knowForTag-D"
            />
          </div>
        )}
      </div>

      <div className="icon-row res-icon-row">
        <div
          onClick={() =>
            history.push({pathname: `/discoverpage/${card?._id}`, state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed}})
          }
          className="learn-more res-dis-card-title"
        >
          Learn More
        </div>
        <div className="icon-section">
        {/* {locationIcon&&<div className="location discover-location">
          <a target="blank" href={`//${card?.website}`} rel="no-referrer">
            <img
              src={locationImg}
              alt=""
              className="freshfinds-cards-icon discover-freshfinds-cards-icon res-discover-freshfinds-cards-icon"
            />
            </a>
          </div>} */}
          <div className="favorite">
            {/* TODO:  */}
            {wishListRefresher ? (
              <AiFillHeart
                onClick={() => onClickHandler()}
                className=" heart-icon-size"
              />
            ) : (
              <AiOutlineHeart
                onClick={() => onClickHandler()}
                className=" heart-icon-size"
              />
            )}
          </div>
          <div className="share">
            <img 
              onClick={()=>setOpen(true)}
              src={shareImg}
              alt=""
              className="freshfinds-cards-icon discover-freshfinds-cards-icon res-discover-freshfinds-cards-icon"
            />
              <Modal open={open} onClose={() => setOpen(false)} center>
                <h2>Share</h2>
                <div className="share-modal-wrap">
                  <div className="whatsapp share-modal">
                   <WhatsappShareButton
                     url={"https://spiffyyou.com/discoverpage/"+card?._id}
                    quote={"Recommended Brand:"+card?.title}
                  hashtag={"#spiffy"}
                  description={"aiueo"}
                  className="Demo__some-network__share-button"
                   ><FaWhatsappSquare color={"brown"} size={"3rem"} /></WhatsappShareButton>
                  </div>
                  <div className="facebook share-modal">
                  <FacebookShareButton
                  url={"https://spiffyyou.com/discoverpage/"+card?._id}
                      quote={"Recommended Brand:"+card?.title}
                  hashtag={"#spiffy"}
                  description={"aiueo"}
                  className="Demo__some-network__share-button"
                  >
                    <FaFacebookSquare color={"brown"} size={"3rem"} />
                  </FacebookShareButton>
                  
                  </div>
                  <div className="mail share-modal">
                    <EmailShareButton
                    url={"https://spiffyyou.com/discoverpage/"+card?._id}
                       quote={"Recommended Brand:"+card?.title}
                    hashtag={"#spiffy"}
                    description={"aiueo"}
                    className="Demo__some-network__share-button"
                    >
                      <IoIosMail color={"brown"} size={"4rem"} />
                    </EmailShareButton>
                  </div>
                </div>
                <div style={{padding: "10px"}} className="link-row">
                  <div className="link-for-copy">https://spiffyyou.com/discoverpage/{card?._id}</div>
                  <div style={{marginLeft:"15px"}} className="copy-btn">
                  <CopyToClipboard
                 text={"https://spiffyyou.com/discoverpage/"+card?._id}
                 onCopy={()=>setCopy(true)}
                >
                  <button className="copy-button">Copy</button>
                </CopyToClipboard>
                  </div>
                </div>
              </Modal>
          </div>
        </div>
      </div>

      <div className="bottom-rating-row discover-card-rating-row">
        <div className="rate-it discover-rate-it">
          <p className="CeraProMedium res-discover-rate-it-p">Rate it</p>
        </div>
        <div
          className={
            showRatingValue
              ? "rating-star-box"
              : "bottom-stars discover-rating-star"
          }>
          <ReactStars
            key={emptyStarsLogOutUser}
            size={25}
            count={5}
            edit={editvalue()}
            isHalf={false}
            value={starValue()}
            onChange={ratingchanged}
            emptyIcon={<BsStarFill className="Rating-start-BrandPage" />}
            halfIcon={<BsStarHalf className="Rating-start-BrandPage" />}
            filledIcon={<BsStarFill className="Rating-start-BrandPage" />}
            color={"white"}
            activeColor={"#AF1C1C"}/>
        </div>
     {logStatus.value&&<div
          onClick={() => checkLogIn( card?.title)}
          style={ (card?.userReviewed )?({display:"none"}):( (showRatingValue || card?.userRated)?{}:{display:"none"})}
          className={"review-div-res discover-review-div res-discover-review-div CeraProMedium"}
        >
          Write a <br /> Review
        </div>}
      </div>
    </div>
  );
}