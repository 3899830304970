import {React,useState,useEffect,useRef,useContext} from "react";
import "./ReviewBrand.css";
import FitQualitySimple from "../ui/FitAndQuality/FitQualitySimple";
import ReactStars from "react-rating-stars-component";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import reviewBrandImg1 from "../../assets/ReviewABrand.png";
import axios from 'axios';
import { toast, } from "react-toastify";
import {useHistory} from "react-router-dom";
import dotenv from 'dotenv';
import { Modal } from "react-responsive-modal";
import { BsFillCameraFill  } from "react-icons/bs";
import { GoTriangleDown } from "react-icons/go";
import { MdCancel } from "react-icons/md";
import { HiInformationCircle } from "react-icons/hi";
import ReviewImage from "../../assets/ReviewImage.png";
import { TailSpin } from  'react-loader-spinner';
import { set } from "date-fns/esm";
import LogContext from '../../LogContext';
// import { Loader } from '@mantine/core';
dotenv.config();

export default function ReviewBrand(props) {
    const [ review ,setReview]  = useState('');
    const brandName = props.location.state?.Brand_name;
    const [fitRadiovalue,setFitRadioValue] = useState('')
    const [loaderStatus ,setLoaderStatus] = useState(true);
    const [openModal,setOpenModal] = useState();
    const [qualityRadiovalue,setQualityRadioValue] = useState('');
    const [ratingID,setRatingID] = useState();
    const [uploadImage ,setUploadImage] = useState([]);
    const [passingRating,setPassingRating] = useState();
    const [ratingGiveOnReviewPage ,setRatingGiveOnReviewPage] = useState(0)
    // const [uploadImageAnother , setUploadImageAnohter] = useState();
    const [showMessage,setShowMessage] = useState(true);
    const [rating,setRating] = useState(props.location.state?props.location.state.ratingvalueComes:0);
    const ref = useRef();
    const history = useHistory();
    const inputRef = useRef(); 
    const selecetfilePop = ()=> inputRef.current.click();  
    const Rating_id=props.location.state?props.location.state.Rating_id:"";
    const BrandID = props.location.state.BrandID;
    const logStatus = useContext(LogContext);

    useEffect(()=>{
    const body = document.querySelector('#root');
    body.scrollIntoView({
        behavior: 'smooth'
    }, 500)
    },[]);   
    
    
   const ratingStarsAPi=async(starValue)=>{
     const token = localStorage.getItem("token");
    try {
     const res = await axios.post(`${process.env.REACT_APP_API_URL}/rating-review/}`,{
     brandId:BrandID,
     rating:starValue
    },{headers:{Authorization:`Bearer ${token}`}})
    .then((response) => { 
      setRatingID(response?.data?._id);
      setRatingGiveOnReviewPage(response?.data?.rating);
    })
    } catch (error) {
      console.log(error,"message from ratingStarsApi")
    }
  }
   
  const postReviewRatingOnApi =async()=>{
    const token = await localStorage.getItem("token");
    let formData = new FormData();
    for(let i=0;i<uploadImage.length;i++){
      formData.append("images",uploadImage[i]);
    }
    formData.append("rating",rating);
    formData.append("fit",fitRadiovalue);
    formData.append("quality",qualityRadiovalue);
    formData.append("review",review);

      if(review?.length >0 && review?.length <= 300 && rating > 0){
        try {
          setLoaderStatus(false);
        axios.patch(`${process.env.REACT_APP_API_URL}/rating-review/review/${props.location.state.Rating_id?Rating_id:ratingID} `, formData, {
            headers: { Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setReview("")          
            props.setEditOpen(true)
            popUpShow();
            history.push("/discoverpage");
          })
          .catch((err) => {
            toast(err.response.data.error, { type: "error" });
            //  history.goBack();
          });
      } catch (error) {
        console.log(error,"Error in  ReviewBrand Page")
          }finally{
            setLoaderStatus(true);
          }
       }else if(review?.length === 0 ){
        toast("Short Review",{type:"error"}) //Please your letter shuold less then 150
       }else if(review?.length>=300){
        toast("Review  Have More then 150 Letters",{type:"error"})
       }else if(rating === 0 || rating === undefined){
        toast("Please Give Rating First",{type:"error"})
       }
     }
     const popUpShow=()=>{
       setTimeout(() => {
        props.setEditOpen(false)
       },4500);
     }

  const ratingchanged =(newValue)=>{
    const token = localStorage.getItem("token");
    if(!token){
      setPassingRating(newValue)
     setOpenModal(true);
     console.log(newValue,"Passing Rating")
    }else{
      setRating( newValue);
      ratingStarsAPi(newValue)
    } 
  }

  useEffect(()=>{
   console.log(props.location,"Review brand useEffect")
   if(props.location.hash === "#logInpage" || props.location.hash === "#RegisterPage"){
     setTimeout(() => {
       console.log("Running Rating API");
        try {
          const token = localStorage.getItem("token");
          const res = axios.post(`${process.env.REACT_APP_API_URL}/rating-review/}`,{
              brandId:props.location.state.BrandID,
              rating:props.location.state.ratingvalueComes
           },{ headers: {Authorization: `Bearer ${token}` } })
           .then((response)=>{
             setRatingID(response?.data?._id);
           
             })
           } catch (error) {
             console.log(error,"message from ratingStarsApi")
           }
      },2200)
    }  
  },[])

  const changeReview =(e)=>{
       setReview(e.target.value);
     }
  const handleImageUpload = (e)=>{
     if(e.target.files.length > 2){
       toast("Select Only Two Image",{type:"error"})
     }else{
      setUploadImage(e.target.files)
     }
   }
  const cancelButton=(index)=>{
    let temp=[...uploadImage];
    temp.splice(index,1);
    setUploadImage(temp);
    }

    useEffect(()=>{
      setTimeout(() => {
        setShowMessage(false)
      }, 30000);
    },[])

    

   const popHandler=(ConValue)=>{
     if(ConValue===true){
       setShowMessage(false);
     }
   }
   useEffect(()=>{
    const token = localStorage.getItem("token") 
     if(!token){
      // setOpenModal(true)
      logStatus.setOpenPop(true)
     }
   },[])
  
   const popHandlerInnerDiv = (ConValue)=>{
    if(ConValue===false){
      setShowMessage(true);
    }
   }
   useEffect(()=>{
     console.log(props.location.state,"Review On Brand")
   },[])

  return (
    <div  ref={ref}>
    {/* <Modal
      open={openModal} onClose={()=>setOpenModal(false)} center classNames={{
            overlay: "customOverlay",
            modal: "customModal",}}>
        <div className="BrnadPage-Log-Pop">
          <div className="login-image-pop pop-item-1">
            <img className="Login-image-pop" src="loginPopUp.svg" alt="" />
          </div>
        <div  className="Brand-Page-log-pop-item pop-item-2" onClick={() => {
              history.push({pathname:"/logInpage",
              state:{
                BrandID:props.location.state.BrandID,
                RatingValue:passingRating,
                BrandName:brandName},
                hash:'ReviewBrand'
       })}}>
          Login
        </div>
          <div
            className="Brand-Page-log-pop-item pop-item-3"
            onClick={() => {
              history.push({pathname:"/Register",
              state:{
                BrandID:props.location.state.BrandID,
                RatingValue:passingRating,
                BrandName:brandName},
                hash:'ReviewBrand'
              })
            }}>
            Register
          </div>
        </div>
    </Modal> */}
      <div className="submit-brand-banner-wrap" >
      {/* <ToastContainer position="top-right" autoClose={2000} /> */}
        <div className="submit-banner-img">
          <img src={reviewBrandImg1} alt="" />
        </div>
      </div>
      <div onClick={()=>popHandler(showMessage)} className="brnad-detail-main-container">
        <div className="bot-class-container"> 
        {/* <img onClick={()=>setShowBotMessage(!showBotMessage)} className="bot-image" alt="bot-gif" src={BotGif} />  */}
      </div>    
  
   
      <div className="brand-detail-wrap">
        <div className="brand-detail-section review-brand-detail-section">
          <div className="detail-para submitbrand-row-padding">
           
          </div>
          <div className="brand-name-feild submitbrand-row-padding">
            <lable className="lable">Brand Name </lable>
            <p className="lable">{brandName}</p>
          </div>
          <div className="take-rating submitbrand-row-padding">
            <label className="lable required">Your Rating </label>
           <ReactStars
              key={rating}
              size={40}
              className="ParantClass-ReactStar react-stars"
              count={5}
              edit={props.location.state.Rating_id?false:true}
              onChange={ratingchanged}
              value={rating}
              color={"black"}
              // onChange={props}
              emptyIcon={
               <BsStar className="Rating-start-BrandPage Rating-start-reviewPage" />
              }
              halfIcon={
               <BsStarHalf className="Rating-start-BrandPage Rating-start-reviewPage-fill" />
              }
              filledIcon={
               <BsStarFill className="Rating-start-BrandPage Rating-start-reviewPage-fill" />
              }
              activeColor={"#AF1C1C"}
            />
            </div>
  

          <FitQualitySimple setQualityRadioValue={setQualityRadioValue} setFitRadioValue={setFitRadioValue} qualityRadiovalue={qualityRadiovalue} fitRadiovalue={fitRadiovalue} isBrandPage={false} />
         { (rating>0 && localStorage.getItem("token") ) &&<div className="review-box-wrap brand-detail-wrap submitbrand-row-padding">
            <label className="lable required" style={{ alignSelf: "flex-start" }}>
              Review<span className="information-icon" style={{position: "relative"}}> 
              
      {  (showMessage  && rating >0 ) &&<div className="brand-Rating-Message">     
          Dear valued member
          We are trying very hard to build this trustworthy community. Hence, please let 
          them know how your experience with this label was. Share something that you 
          greatly love about this label like may be its value for money, their designs, variety, 
          customer service, easy returns, etc.
          Your honest review here can make a great collective impact. However, lets also try 
          to be respectful in our language.
          We will not hide any reviews or manipulate them in any way
      </div>}
               <HiInformationCircle className="info-icon-review-brand" onClick={()=>popHandlerInnerDiv(showMessage)}  /></span>
            </label>
            <div className="Review-Text-rating">
           { loaderStatus?
           (<textarea
              onChange={changeReview}
              name="title"
              value={review}
              className="submit-brand-input-review-brand"
              id=""
              style={ window.screen.width  > 540?{borderRadius:"10px"}:{borderRadius:"10px", marginLeft:"57%",marginTop:"-10px"}}
              cols={window.screen.width  > 540?"50":"18" }
              rows={window.screen.width > 540?"10":"2"}
            ></textarea>):(
             <div className="Empty-card-discoverPage">
              <TailSpin  color="#00BFFF" height={80} width={80} />
            </div>)}
            <input onChange={handleImageUpload} style={{display:"none"}}  accept="image/png, image/jpg, image/jpeg"  ref={inputRef} type="file"  />
            {loaderStatus &&<div style={window.screen.width >540?{}:{marginLeft:"58%",padding:"0.7em 2.3em"}}  onClick={()=>selecetfilePop()} className="image-upload-button"><span><BsFillCameraFill className="camera-icon" /></span>Add Photo</div>}
            {loaderStatus&&<div className="margin-left-review-count"><span className={review?.length > 300 ?"reviewCount-span":"submit-counter-color-G"}>{review?.length}</span>/<p>300</p> </div>}
            </div>           
          </div>}
          {loaderStatus&&<div style={window.screen.width >540?{}:{marginLeft:"10px"}} className="image-upload-section">
          {uploadImage[0]&&<div className="first-upload-image-div"><span><MdCancel onClick={()=>cancelButton(0)} className="cancel-button-first"  /></span><img width={"150px"} src={URL.createObjectURL(uploadImage[0])} alt="userImage" /></div>}  
             
          </div>}
         {(props.location.state.Rating_id?Rating_id:ratingID && localStorage.getItem("token")) &&<div className="submit-brand-btn-div submitbrand-row-padding">
            <button onClick={postReviewRatingOnApi} className="submit-brand-btn">Submit</button>
          </div>}
        </div>
      </div>
   </div> 
    </div>
  );
}



