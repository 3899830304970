import {React,useState,useEffect,useRef} from "react";
import "./Known.css";
import axios from "axios";
import { MdKeyboardArrowLeft,MdKeyboardArrowRight} from "react-icons/md";
import {useHistory} from "react-router-dom";
import dotenv from 'dotenv';
dotenv.config();

export default function Known({brandPageDataWithid}) {
  const history = useHistory();
  const [knowForTagData,setknownForTagData] = useState([]);
  
  const getKnowForData =async()=>{
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/known-for-tag`)
    setknownForTagData(response.data);
   }
 useEffect(()=>{
  getKnowForData();
 },[])
 
 const ref = useRef();
    
 const scrollfunction=(scrollOffset)=>{
   ref.current.scrollBy({
     top: 0,
     left:scrollOffset,
     behavior: 'smooth'
   })
 }

  return (
    <div className="known-wrap">
      <div className="known-heading">
        <h1 className="CeraProBold same-heading-brand-page">Known for</h1>
      </div>
      <div className="known-tag-row" >
      {brandPageDataWithid?.knownForTag?.length<3?"":<div><MdKeyboardArrowLeft onClick={()=>scrollfunction(-200)} className="ScrollButton-knowForTag-brandPage" /></div> }
      <div className="KnowForTag-scroll-div" ref={ref}> {brandPageDataWithid?.knownForTag?.map((KnowForTagArray)=>{
       return ( 
       <div onClick={()=>history.push({pathname:"/discoverpage",state:{knowForTagID:KnowForTagArray?._id,KnownForTagTitle:KnowForTagArray?.title}})} className="known-tags">
          <h3>{KnowForTagArray.title}</h3>
        </div>)
      })}</div>
      {brandPageDataWithid?.knownForTag?.length<3?"":<div><MdKeyboardArrowRight onClick={()=>scrollfunction(+200)} className="ScrollButton-knowForTag-brandPage" /></div> }
      </div>
     

    </div>
  );
}


   {/* <div className="known-tags">
          <h3>Bespoke</h3>
        </div>
        <div className="known-tags">
          <h3>Formal</h3>

        </div>
        <div className="known-tags">
          <h3>Formal</h3>
        </div> */}