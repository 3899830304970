import React from "react";
import "./Mission.css";
import missionImg from "../../../assets/img/mission.png";
import FourHandImage from "../../../assets/FourHand-removebg-preview.png"

export default function Mission() {
  return (
    <div className="mission-section-wrap">
      <div className="mission-first">
        <div className="mission-text">
          <div className="mission-text-heading">
            <h1 className="ourvalue-heading-mission">#Mission</h1>
          </div>
          <div className="mission-text-para">
            <h1>Spiffy strives to be the go-to place for discovering the most
              trustworthy fashion labels no matter where you are in the world.</h1>
            <p className="Pera-1-mission ceraProMedium">
              Fashion - no matter how frivolous it is considered, it
              communicates volumes about us as a person. Even as habitual
              buyers, the curiosity inside us is always seeking variety. While
              social media has brought so much awareness and reach, at the same
              time their complex algorithms tend to suppress smaller fashion
              brands. However, many of you well-traveled, woke women have some
              great experiences in your head to share.
            </p>
          </div>
        </div>
        <div className="mission-img">
          <img src={FourHandImage} alt="missionImage" />
        </div>
      </div>
      <div className="mission-second CeraProMedium">
        <p className="Pera-2-mission ">
          With Spiffy, we aim to take you back to the old-fashioned way of
          having a community of helpful friends who share and gain from each
          other's experiences. This is because we know that any amount of
          digital evolution cannot suppress the power of “word of mouth”
          recommendations. Hence, we literally created this platform for word of
          mouth and truly democratized fashion without any influencers, ads,
          paid promotions, etc.
          <br />
          Our mission is to cut the noise and let only the good ones shine
          through peer reviews.
        </p>
      </div>
    </div>
  );
}
