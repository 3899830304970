import React from "react";
import "./UpperFooter.css";
function UpperFooter() {
  const FooterData = [
    {
      subtitle: "We Are",
      title: "A Platform to discover new fashion labels",
      para: " In the sea of fashion labels, it is tough to find the good ones Consumers, not influencers, curate the brands on Spiffy",
    },
    
    {
      subtitle: "You Suggest",
      title: "Rate and review the brands you love",
      para: " Our community is our core strength. Your brand listings, ratings and reviews hosted on the platform will help us develop that community ",
    },
    {
      subtitle: " Together We Create ",
      title: "A fun and authentic world of fashion",
      para: "Instagram should not be the only medium to discover new brands. We want to build a go-to place to find peer-reviewed fashion recommendations in a fuss-free manner ",
    },
  ];

  return (
    <div className="upper-footer-wrap">
      {FooterData.map((data,i) => {
        return (
          <div className="upper-footer-common-div " style={(i+1)%2===0 ? {background:"#31A1AC", color:"#ffffff"}:{}}>
            <h2 className="CeraProMedium">{data.subtitle}</h2>
            <h1 className="CeraProMedium">{data.title}</h1>
            <p className="CeraProLight">
              {data.para}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default UpperFooter;
