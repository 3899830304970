import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import styles from './blog.module.css'
import 'react-image-crop/dist/ReactCrop.css'
import "cropperjs/dist/cropper.css";
import "./Demo.css";
import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export const Blog = () => {

  const history = useHistory()

  const { token } = useParams();
  
  let headers = {
    'Authorization': `Bearer ${token}`
  }


  const [title, settitle] = useState('')
  const [desc, setdesc] = useState('')
  const [bannerImg, setbannerImg] = useState('')
  const [blog, setBlog] = useState();
  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();

  const [binaryBanner, setbinaryBanner] = useState('')
  const [binaryLogo, setbinaryLogo] = useState('')

  const hiddenBannerInput = React.useRef(null);
  const hiddenThumbnailInput = React.useRef(null);

  const handlechange = (content) => {
    setBlog(content)
  }

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if(e.target.files[0].size <= 1000000) {
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  } else {
    setErrorThumbnail(true)
  }
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleBanner = event => {
    hiddenBannerInput.current.click();
  };

  const handleThumbnail = event => {
    hiddenThumbnailInput.current.click();
  };

  const createBlog = async () => {

    let data = {
      "summary": desc,
      "title": title,
      "content": blog
    }

    const bannerFormData = new FormData();
    bannerFormData.append("file", bannerImg);

    var myFile = dataURLtoFile(cropData, 'thumbnail.jpeg');
    const logoFormData = new FormData();
    logoFormData.append("file", myFile);

    axios.post("https://backend.spiffyyou.com/cms/upload", bannerFormData, { headers })
      .then(res => {
        data.bannerImage = res.data.url

        axios.post("https://backend.spiffyyou.com/cms/upload", logoFormData, { headers })
          .then(res => {
            data.thumbnailImage = res.data.url

            axios.post("https://backend.spiffyyou.com/cms/newBlog", data, { headers })
              .then(() => window.alert('Blog created successfully'))
              .catch(console.log)

          })

      })

  }

  const [errorThumbnail, setErrorThumbnail] = useState(false)
  const [errorBanner, setErrorBanner] = useState(false)

  return (
    <div>
      <h2 style={{ textAlign: "center", margin: "15px" }}>Create Your Blog</h2>
      <div className={styles.mainContainer}>
        <div>
          <button onClick={() => history.push(`/getAll/${token}`)} style={{ margin: '15px', backgroundColor: "#2f4f4f", color: 'whitesmoke', padding: '10px', border: 'none', borderRadius: '5px' }}>
            All Blogs
          </button>

          {/* <button className='btn btn-secondary m-3' onClick={() => history.push(`/edit/${'df'}/:${token}`)} >
          Edit
        </button> */}
        </div>
        <div className={styles.formWrapper} >
          <div className={styles.formContainer} >

            <input
              onChange={e => settitle(e.target.value)}
              placeholder="Enter Title..."
              className={styles.inputField}
            />

            <input
              onChange={e => setdesc(e.target.value)}
              placeholder="Enter Description..."
              className={styles.inputField}
            />

            <button className={styles.imageUploadBtn} onClick={handleBanner}>
              Upload Banner
            </button>
            {errorBanner ? (<small style={{color:'red'}}>Please upload image under 1mb</small>) : null}
            <input
              type={'file'}
              ref={hiddenBannerInput}
              onChange={e => {
                if(e.target.files[0].size <= 1000000) {
                  setbannerImg(e.target.files[0])
                } else {
                  setErrorBanner(true)
                }
              }}
              style={{ margin: '6px 0px', height: '290px', width: '170px', objectFit: 'cover', display: 'none' }}
            />

            <button className={styles.imageUploadBtn} onClick={handleThumbnail} >
              Upload Thumbnail
            </button>
            {errorThumbnail ? (<small style={{color:'red'}}>Please upload image under 1mb</small>) : null}
            <input
              type="file"
              ref={hiddenThumbnailInput}
              onChange={onChange}
              style={{ margin: '6px 0px', display: 'none' }}
            />

            <div style={{ width: "100%" }}>
              <Cropper
                // style={{ height: 400, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
              <h1>
                {image && <button style={{ float: "right" }} onClick={getCropData} className={styles.cropBtn}>
                  Crop Image
                </button>}
              </h1>
            </div>
          </div>
          <div className={styles.previewBox} >
            <p style={{ height: '100px', textAlign: 'initial', fontSize: '30px' }} >{title}</p>
            <div className={styles.descBox} >
              <p style={{ position: 'relative', textAlign: 'initial' }} >{desc}</p>
              {bannerImg &&
                <div style={{ borderRadius: '10', overflow: 'hidden', height: '400px', width: '300px', position: 'absolute', top: 100, right: 100 }} >
                  <img
                    src={URL.createObjectURL(bannerImg)}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </div>
              }
              {cropData &&
                <div style={{ borderRadius: '10', overflow: 'hidden', height: '100px', width: '100px', position: 'absolute', top: 450, right: 130 }} >
                  <img src={cropData} alt="cropped" style={{ objectFit: 'contain', height: '100px', width: '100px' }} />
                </div>
              }
            </div>
          </div>
        </div>

        <div style={{ width: '70%', marginTop: `${window.screen.availHeight / 5}px` }} >
          <button onClick={() => createBlog()} className={styles.submitBtn} >Submit Blog</button>
          <br/>
          <small><strong>Note:&nbsp;</strong>Only add images of 100kb-250kb inside Blog.</small>
          <SunEditor
            autoFocus={true}
            placeholder="Please type here..."
            onChange={handlechange}
            height='80vh'
            setOptions={{
              buttonList: [
                [
                  "bold",
                  "underline",
                  "italic",
                  "list",
                  "align",
                  "lineHeight",
                  "fontSize",
                  "table",
                  "image",
                  "video",
                  "audio",
                  "fontColor",
                  "undo",
                  "redo",
                  "preview"
                ]
              ]
            }}
          />
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: blog }} /></div>
      </div>
      <br style={{ clear: "both" }} />
    </div>
  );
};

export default Blog;
