import {React,useState,useEffect ,useContext} from "react";
import "./Comment.css";
import axios from 'axios';
import dotenv from 'dotenv';
import { toast } from "react-toastify";
import CommentCard from "../OpenBlog/CommentCard";
import ReactPaginate from 'react-paginate';
import { GrPrevious,GrNext } from "react-icons/gr";
import Fade from 'react-reveal/Fade';
import LogContext from "../../../LogContext";
dotenv.config()

export default function Comment({blogID,setCommentCount,commentStatus, setCommentStatus}) {
    const [comment,setComment] = useState("");
    const [commentByID, setCommentByID] = useState([]);
    const [commentUpdate ,setCommentUpdate] = useState(false);
    const [pageCount,setPageCount] = useState(1);
    const [pageCountForApi ,setPageCountForApi] = useState(1);
    const logStatus = useContext(LogContext);
    const commmentShowApi = async () => {
      const token = localStorage.getItem('token');
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/cms/blog-comment/blog/${blogID}?page=${pageCountForApi}&limit=${15}`,
          );
        setCommentByID(res?.data?.comments);
        setPageCount(res?.data?.totalPage);
        setCommentCount(res?.data?.totalCount);
        console.log(res?.data,"Comment Get ID");
      } catch (error) {
        console.log(error, "message From Commment API");
      }
    }

    
    useEffect(()=>{
      commmentShowApi()
    },[commentUpdate,pageCountForApi])

    const sendCommentFromUserID = async()=>{
        const token = localStorage.getItem('token');
        try {
          if(comment?.length > 0 && comment?.length <=200 && localStorage.getItem('token')){
          await axios.post(`${process.env.REACT_APP_API_URL}/cms/blog-comment/${blogID}`,{
              content:comment 
          },{headers: {"Authorization" : `Bearer ${token}`} } ).then((response) => {
            toast("Comment Submit Successfully",{type:"success"})  
            // setCommentByID([...commentByID,{content:comment}])
            setCommentUpdate(!commentUpdate);
          })            
          }else if(comment?.length === 0){
           toast("Type Comment ",{type:"error"})
          }else if(comment?.length >=200){
            toast("Too Long Comment",{type:"error"})
          }else if(!(localStorage.getItem("token"))){
            // setOpen(true)
            logStatus.setOpenPop(true);
          }
        } catch (error) {
          console.log(error)  
        } 
    }

    const handleChange=(e)=>{
    setComment(e.target.value);
    }
    const commentSubmit =()=>{
      const token= localStorage.getItem("token")
      if(token===""){
        // setOpen(true);
        logStatus.setOpenPop(true);
       }else{
        sendCommentFromUserID();
        setComment(''); 
        setCommentStatus(true); 
       }
     }
   
     
    const handlePageChange=(e)=>{
       setPageCountForApi((e.selected)+1);
    }
 const pageOffset =0;
  return (
 <>   
  <div className="comment-section-wrap">
    <div className="comment-input-section">
      <h1 className="leave-a-reply">Leave a Reply</h1>     
      <div className="reply-feild-1">
        <div className="reply-input-1">
          <textarea 
            value={comment}
            onChange={handleChange} 
            name="" 
            id=""  
            className="comment-input  commentInputBoxOnBlogPage">
            </textarea>
          <div className={"margin-left-comment"}><span className={comment.length>200?"count-comment-color":"count-comment-color-G"}>{comment?.length}</span>/200</div>
        </div>
      </div>
      <div className="reply-feild-1">
        <button onClick={()=>commentSubmit()} className="submit-brand-btn">Submit</button>
      </div>
      </div>
      <Fade right when={commentStatus}>
        {commentStatus&&<div className="comment-visiable-section">
          {commentByID.length !==0&&<div className="comment-div-Heading"><h1>#Comments</h1></div>}
        <div className="comment-hold-container">      
        {commentByID.map((comment)=>{
            return (
            <CommentCard  comment={comment} />
        )
        })}
         </div>

         <ReactPaginate 
                previousLabel={<GrPrevious className="Prev-Button-Pagination"/>}
                nextLabel={<GrNext className="Next-Button-Pagination"/>}
                pageClassName="All-li-Items"
                pageLinkClassName="li-Item-link"
                previousClassName="Prev-li-Point-main"
                previousLinkClassName="Prev-li-Point"
                nextClassName="Next-BTN-Point-Main"
                nextLinkClassName="Next-BTN-Link"
                breakLabel="..."
                breakClassName="Dot-Break-Point-Main"
                breakLinkClassName="Dot-Break-Point"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="All-Item-Main-Container"
                activeClassName="Item-active-Point"
                forcePage={pageOffset}
                />            
        </div>}
      </Fade>
    </div>
</>
  );
}
