import {React,useRef,useState,useEffect} from 'react'


function InputCheckBox({
  labels,
  setKnownValue,
  setFilterItem,
  knownValue,
  knowForTagID,
  setCheckedBox,
  checkedBox,
  knownForTagResetValue,
  resetKnown,
  setResetKnown,
  setPageCount
}){
  const checkRef = useRef();
  const [checkValue,setCheckValue] = useState(false);
  
  const handleCheckFun=(id)=>{  
    setPageCount(0)   ;         
      if(checkValue===true){
        setCheckValue(false);
        checkRef.current.checked = false;
        onClickKnow(id);
      }else if(checkValue===false){
        setCheckValue(true);
        checkRef.current.checked = true;
        onClickKnow(id);
      }
  }
  const resButton=()=>{
    if(knownValue.length <=0 && resetKnown===false){
      checkRef.current.checked = false;  
      setResetKnown(true)
    }
  }
   

  const onClickKnow = (id) => {
    if(knowForTagID===id){
      setCheckedBox(!checkedBox)
    }
    if (knownValue.filter((item) => item === id).length > 0) {
      setKnownValue(knownValue.filter((item) => item !== id));
    } else {
      setKnownValue([...knownValue, id]);
    }
    // setFilterItem(false);
  };


   
    return (
        <>
            <input  
            //  ref={checkRef} 
             checked={(knownValue.filter((item) => item === labels?._id).length > 0&&knownValue.filter((item) => item === labels?._id))?true:false}  
             className="input-checkbox input-checkbox-knownForTag"
             type="checkbox"      
             onChange={()=>onClickKnow(labels?._id)} />
            <label className="checkbox-label">
                {labels?.title}
            </label>  
        
        </>
    )
}

export default InputCheckBox
