import React from "react";
import ReactStars from "react-rating-stars-component";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "./PopCard.css";
import FitQuality from "../ui/FitAndQuality/FitQuality";
import FitQualitySimple from "../ui/FitAndQuality/FitQualitySimple";
function PopCard({ open, setOpen }) {
  return (
    <>
      <Modal
        className="Write-Review-component-main"
        onClose={() => setOpen(false)}
        open={open}
        center
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
      >
        <div className="BrandName-pop">
          <div className="close-Button-pop"></div>
          <div className="brand-detail-section">
            <div className="detail-para submitbrand-row-padding">
              <h1>Add Review</h1>
            </div>
            <div className="brand-name-feild submitbrand-row-padding">
              <lable className="lable">Brand Name :</lable>
              <p className="lable">Burberry</p>
            </div>
            <div className="take-rating submitbrand-row-padding">
              <label className="lable">Your Review :</label>
              <ReactStars
                size={40}
                count={5}
                isHalf={true}
                value={0}
                color={"black"}
                emptyIcon={
                  <BsStar className="Rating-start-BrandPage Rating-start-reviewPage" />
                }
                halfIcon={
                  <BsStarHalf className="Rating-start-BrandPage Rating-start-reviewPage-fill" />
                }
                filledIcon={
                  <BsStarFill className="Rating-start-BrandPage Rating-start-reviewPage-fill" />
                }
                activeColor={"#AF1C1C"}
              />
            </div>
         
            <div className="fit-and-quality-wrap-pop">
              <label className="label">
          <FitQualitySimple isBrandPage={false} />
              </label>
              <div className="fit-div"></div>
            </div>
            <div className="brand-website submitbrand-row-padding">
            <label className="lable">Add Image</label>
            <input className="submit-brand-input" type="image" name="" id="" />
          </div>
            
            <div className="review-box-wrap brand-detail-wrap submitbrand-row-padding">
              <label className="lable" style={{ alignSelf: "flex-start" }}>
                Review
              </label>
              <textarea
                name="title"
                className="submit-brand-input"
                id=""
                cols="50"
                rows="10"
              ></textarea>
            </div>

            <div className="submit-brand-btn-div submitbrand-row-padding button-pop-brand">
              <button className="button-pop-brand submit-brand-btn">
                Submit
              </button>
            </div>
          </div>
        </div>
        {/* <button>s */}
      </Modal>
    </>
  );
}

export default PopCard;
