import React from 'react'
import './reviewbanner.css'
import reviewimg from '../../../images/reviewimg.png'
import { useHistory } from "react-router-dom";

function Reviewbanner() {
  const history = useHistory();

    return (
        <>
        {window.screen.width > 560&& <div  className="reviewcontainer">
        <div className="submitareivew">
            <div className="reivewtext">
                <h1>TRIED A NEW BRAND? SHARE YOUR EXPERIENCE</h1>
            </div>
            <div className="reviewbutton">
                <button className="reviewbutton-btn" onClick={() => history.push("/discoverpage")}>SUBMIT A REVIEW</button>
            </div>
        </div>
        <div className="reviewimg">
           <img src={reviewimg} alt="this" />
        </div>
    </div>}
    </>
    )
}

export default Reviewbanner
