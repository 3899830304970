import {React,useState,useEffect,useContext} from 'react'
import './roundicon.css';
import LogContext from "../../../../LogContext";

function Roundicon({categoryName,imageLink,id}) {
  const [selectCategoryButton,setSelectCategoryButton] = useState(false);
  const logcontext = useContext(LogContext);

  const onClickCat = (id)=>{
    setSelectCategoryButton(!selectCategoryButton);
    if(logcontext.catValue.filter(item=>item===id).length>0)
    logcontext.setCatValue(logcontext.catValue.filter(item=>item!==id))
    else{
      logcontext.setCatValue([...logcontext.catValue,id]);
    }
    logcontext.setPaginationPageOffSet(0);
  }
  
    return (
      <div onClick={()=>onClickCat(id)} className={(logcontext.catValue.filter(item=>item===id).length>0&&logcontext.catValue.filter(item=>item===id))?"iconbox iconboxSelect":"iconbox"}>
        <div  className={(logcontext.catValue.filter(item=>item===id).length>0&&logcontext.catValue.filter(item=>item===id))?"iconborder iconborderSelect":"iconborder"}>
          <img src={imageLink} alt="" />
        </div> 
          <p>{categoryName}</p>
      </div>
    )
}

export default Roundicon
