import { React} from "react";
import "./YourStyle.css";
import shareImg from "../../../assets/img/Share.svg";
import quizImg from "../../../assets/img/quiz.svg";
import { FiEdit } from "react-icons/fi";
import "react-responsive-modal/styles.css";
import { FaUserCircle } from "react-icons/fa";
import dotenv from "dotenv";
dotenv.config();

function YourStyle({ userData ,setOpenEdit,setInputStatus}){

  const editButtonOn=()=>{
    setTimeout(() => {
      setInputStatus(true)
    },2000)
      setOpenEdit(true)
  }

  return (
    <div className="yourstyle-wrap">
      <div className="yourstyle-div1">
        <div className="edit-icon">
          <FiEdit color="#af1c1c" onClick={() =>editButtonOn()} />
        </div>
        <div className="profile-img-div">
          {userData?.profilePic?(
           <img className="UserProfile-edit" src={`${userData?.profilePic}`} alt="user-pic" />
          ) :(
            <FaUserCircle className="profile-svg" />
          )}
        </div>
        <div className="profile-img-div-info">
          <div>
            <p className="CeraProLight">Name</p>
            <p className="CeraProLight">Mail</p>
            <p className="CeraProLight">Location</p>
          </div>
          <div>
            <p className="infospan CeraProMedium">
              {userData?.name ? userData?.name : userData?.firstName}
            </p>
            <p className="infospan CeraProMedium">{userData?.email}</p>
            <p className="infospan CeraProMedium">{userData?.location?userData?.location:"Not Specified"}</p>
          </div>
        </div>
      </div>
      <div id="yourstyle" className="yourstyle-div2">
        <div className="yourstyle-div2-heading">
          <h1 className="CeraProBold"> #YourStyle </h1>
        </div>
        <div className="quote">
          <p className="NunitoLight">
            {"Fashion Is Like Eating, You Shouldn't Stick To The Same Menu. "}
          </p>
        </div>
        <div className="yourstyle-buttons-wrap">
          <div className="yourstyle-button-div yourstyle-button-div-1">
            <div className="yourstyle-button-text">Retake The Quiz</div>
            <div className="yourstyle-button-icon">
              <img src={quizImg} alt="" />
            </div>
          </div>
          <div className="yourstyle-button-div share-button-profilepage-yourstyle">
            <div className="yourstyle-button-text">Share The Quiz</div>
            <div className="yourstyle-button-icon">
              <img src={shareImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export default YourStyle;
