import dotenv from "dotenv";
import { React, useState, useEffect, useRef} from "react";
import YourStyle from "../YourStyle/YourStyle";
import Invite from "../Invite/Invite";
import "./profilepage.css";
import Country from "../../Auth/Country";
import {toast} from "react-toastify";
import { FaUserCircle } from "react-icons/fa";
import { BsFillCameraFill  } from "react-icons/bs";
import Reviewbanner from "../ReviewBanner/Reviewbanner";
import Profile from "../Profile/Profile";
import Yourreviewcontainer from "../YourReviewContainer/Yourreviewcontainer";
import BrandCards from "../../ui/BrandCards/BrandCards";
import UpperFooter from "../../ui/UpperFooter/UpperFooter";
import MustReadCardProfilePage from "../../ui/mustread/MustReadCardProfilePage";
import BottomFooter from "../../ui/BottomFooter/BottomFooter";
import EasySteps from "../EasySteps/EasySteps";
import axios from "axios";
import EmptyData from "../../ui/EmptyData/EmptyData.js";
import buttonArrowImg from "../../../assets/img/button-arrow.svg";
import {useHistory } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import { IoIosMail } from "react-icons/io";
import { FaWhatsappSquare, FaFacebookSquare } from "react-icons/fa";
import Cong from "../../../components/Profile/Cong/Cong";
import ReactPaginate from 'react-paginate';
import { GrPrevious,GrNext } from "react-icons/gr";
import ResMustReadPinned from "../../ui/ResponsiveMustRead/ResMustReadPinned";
import upDateImage from "../../../assets/canva2.png"
import PassWordChange from "../../../assets/4.png";
import ResDiscoverCard from "../../Discover/ResDiscoverCards/ResDiscoverCard"
import TrandingCard from "../../ui/BrandCards/TrandingCard";
import buttonWhiteAarrow from "../../../assets/img/button-white-arrow.svg";
import { set } from "date-fns/esm";
dotenv.config();

function Profilepage() {
  const history = useHistory();
  const [wishListData, setwishListData] = useState([]);
  const [userReviewRating, setUserReviewRating] = useState([]);
  const [userData, setUserData] = useState();
  const [updateProfileMessage,setUpdateProfileMessage] = useState(false);
  const [changePasswordSuccess ,setChangePasswordSuccess] = useState(false);
  const [profileUpdate,setProfileUpdate] = useState(false);
  const [blogLength , setBlogLength] = useState();
  const [openAnother,setOpenAnother] = useState(false);
  const [pageCount,setPageCount] = useState(1);
  const [pageCountForApi ,setPageCountForApi] = useState(1)
  const ref = useRef();

  const [oldPassWord,setOldPassWord] = useState();
  const [newPassWord,setNewPassWord] = useState();
  const [confirmNewPassword , setConfirmNewPassWord] = useState();
  const [checkPass,setCheckPass] = useState(false);

  const [switchEditPage,setSwitchEditProfile] = useState(false);
  const [profileImage,setProfileImage] = useState()
  const [openEdit, setOpenEdit] = useState(false);
  const [inputNameValue, setInputNameValue] = useState();
  const [inputLocationValue, setInputLocationValue] = useState();
  const [ imageFile , setImageFile] = useState();
  const [openEditModal ,setOpenEditModal] = useState(false);
  const profileImageRef = useRef();
  const [inputStatus,setInputStatus] = useState(false);

  const selecetfilePop = ()=> profileImageRef.current.click();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      getwishListapidata();  
    } 
  }, [pageCountForApi]);
  
  useEffect(() => {
     getUserData();
  }, [profileUpdate]);
      
  const getwishListapidata = async () => {
    const token = localStorage.getItem("token");
    if(token){
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/brand/wish-list/user?page=${pageCountForApi}&limit=${3}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setwishListData([...response?.data?.brands]);
      setPageCount(response?.data?.totalPage);
    } catch (error) {
      console.log(error, "error In Profile Page");
    }}
  };

  const scrollfunction = (scrollOffset) => {
    ref.current.scrollBy({
      top: 0,
      left: scrollOffset,
      behavior: "smooth",
     });
  };
  
  const getUserData = async () => {
    const token = localStorage.getItem("token");
    if(token){
    try {
      const response = await axios.get(
         `${process.env.REACT_APP_API_URL}/user/profile`,
          { headers: { Authorization: `Bearer ${token}` } }
       );
         setUserData(response?.data);
        }  catch (error) {
        console.log(error);
       }
     }
   };
  
  const addWishListApiData = async (card) => {
    const token =  localStorage.getItem("token");
    if(token){
    try {
      await axios
        .patch(
          `${process.env.REACT_APP_API_URL}/brand/wish-list/${card?._id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
         .then(() => {
               const newCards = [...wishListData].filter(
             (item) => item?._id !== card?._id
           );
            setwishListData(newCards);
         });
       } catch (error) {
        console.log(error, "message from TrandingFile");
      }
    }
  };
  
  const CongratulationsAndEasyStep =()=>{
    if(userData?.fiveStarProfile?.writeBrandReview && userData?.fiveStarProfile?.rateBrand && userData?.fiveStarProfile?.updateLocation && userData?.fiveStarProfile?.takeTheStyleQuiz &&  userData?.fiveStarProfile?.submitNewBrand){
      return <Cong />
    } else {
      return <EasySteps  userData={userData} /> 
    }
  } 
  


  const ProfilePatchApi = async () => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("name",inputNameValue?(inputNameValue):(userData?.name?userData?.name:userData?.firstName));
    formData.append("location",inputLocationValue?(inputLocationValue):(userData?.location));
    formData.append("images",imageFile);

    try {      
        await axios.patch(
        `${process.env.REACT_APP_API_URL}/user`,
          formData,
         { headers: { Authorization: `Bearer ${token}` } }
       ).then((res)=>{
          
           setProfileUpdate(!profileUpdate);
           setUpdateProfileMessage(true);
       });
     } catch (error) {
       console.log(error, "error In User Patch API");
     }finally{
      setOpenEdit(false);
      setInputNameValue("");
      setInputLocationValue("");
    }
  };

  const handleInputValueName = (e) => {
    setInputNameValue(e.target.value);
  };

  const handleInputValueLocation = (e) => {
    setInputLocationValue(e.target.value);
  };
  
  const handlePageChange=(e)=>{
    setPageCountForApi((e.selected)+1);
  }
  const pageOffset =0;
  
  const onFileChange=(e)=>{
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setImageFile(e.target.files[0])
  }
  const handleOldPass =(e)=>{
    setOldPassWord(e.target.value);
  }

  const handleNewPass =(e)=>{
    setNewPassWord(e.target.value);
  }

  const handleConfirmNewPass =(e)=>{
    setConfirmNewPassWord(e.target.value)
  }

  useEffect(()=>{
    if(confirmNewPassword!==newPassWord){
      setCheckPass(true)
      console.log("Password is Not Same")
    }else{
      setCheckPass(false);
      console.log("PassWord is Same")
    }
  },[confirmNewPassword]);


  const changePassword =async()=>{
    
    const token = localStorage.getItem("token")
    if(confirmNewPassword===newPassWord && newPassWord!==""){
      try {
         await axios.post(`${process.env.REACT_APP_API_URL}/auth/change-password`,{
          oldPassword: oldPassWord,
          newPassword: newPassWord
        },{headers:{Authorization: `Bearer ${token}` }}).then((res)=>{
          setChangePasswordSuccess(true);
        })
        } catch (error) {
          toast(error.response.data.error,{type:'error'})
        }finally {
          setOldPassWord();
          setNewPassWord();
          setConfirmNewPassWord();
          setOpenEdit(false);
          setSwitchEditProfile(false);
        }
    }else {
      toast.error("Confirm Password Not Match")
    }

  }
  useEffect(()=>{
    setTimeout(()=>{
      setChangePasswordSuccess(false);
    },3000)
  },[changePasswordSuccess])
  useEffect(()=>{
    setTimeout(() => {
      setUpdateProfileMessage(false)
    },3000)
  },[updateProfileMessage])

  return (
    <>
      <Profile />
    {updateProfileMessage && <div className="login-Image-container"><img className="login-pop-image" src={upDateImage} alt="pic" /></div>}
    {changePasswordSuccess && <div className="login-Image-container"><img className="login-pop-image" src={PassWordChange} alt="pic" /></div>}
      <Modal open={openAnother} onClose={() => setOpenAnother(false)} center>
          <h2>Share</h2>
          <div className="share-modal-wrap">
                  <div className="whatsapp share-modal">
                  <a href="https://web.whatsapp.com" target="blank"><FaWhatsappSquare color={"brown"} size={"3rem"} /></a>
                  </div>
                  <div className="facebook share-modal">
                  <a href="https://www.facebook.com" target="blank"><FaFacebookSquare color={"brown"} size={"3rem"} /></a>
                  </div>
                  <div className="mail share-modal">
                  <a href="https://mail.google.com/mail/u/0/#inbox?compose=new" target="blank"><IoIosMail color={"brown"} size={"4rem"} /></a>
                  </div>
                </div>
          <div className="link-row">
            <div className="link-for-copy">http://15.206.178.99/</div>
            <div className="copy-btn">
              <button>Copy</button>
            </div>
          </div>
        </Modal>
        <Modal
            open={openEdit}
            onClose={() =>  setOpenEdit(false)}
            center
            classNames={{
              overlay: "customOverlay",
              modal: "edit-modal",
            }}>
            
            <div className="edit-profile-Button-Div">
                <h2 onClick={()=>setSwitchEditProfile(false)} className={switchEditPage?"edit-modal-heading bg-color":" edit-modal-heading"}>Edit Your Profile</h2>
                <h2 onClick={()=>setSwitchEditProfile(true)} className={switchEditPage?"edit-modal-heading ":"edit-modal-heading bg-color"}>Change Password</h2>
            </div>

            { switchEditPage?<>
            <div className="edit-modal-profile-wrap">
            <div className="edit-feild-1">
            <div className="edit-input-label">
                    <label className="password-Change-Class">Old Password</label>
                  </div>
                  <div className="edit-feild-text edit-feild-text-name">
                    <input
                      type="PassWord"
                      name="passWord"
                      className="name-input-field Input-profile-Name changePassword-input"
                      placeholder="Old Password"
                      onChange={handleOldPass}
                      value={oldPassWord}
                    />
                   </div>
                  </div>
                 <div className="edit-feild-1">
            <div className="edit-input-label">
                    <label className="password-Change-Class">New PassWord</label>
                  </div>
                  <div className="edit-feild-text edit-feild-text-name">
                    <input
                      type="password"
                      name="NewPassWord"
                      className="name-input-field Input-profile-Name changePassword-input"
                      placeholder="New Password"
                      onChange={handleNewPass}
                      value={newPassWord}/>
                   </div>
                  </div>
                  <div className="edit-feild-1">
                <div className="edit-input-label">
                    <label className="password-Change-Class">Confirm PassWord</label>
                  </div>
                  <div className="edit-feild-text edit-feild-text-name">
                    <input
                      type="password"
                      name="ConfirmPassword"
                      className="name-input-field Input-profile-Name changePassword-input changePassword-another"
                      placeholder="Confirm Password"
                      onChange={handleConfirmNewPass}
                      value={confirmNewPassword}/>              
                   </div>                   
                  </div>
                  {checkPass&&<div className="Pass-Error">Password is Not Same</div>}
                </div>
                <div className="edit-profile-submit changepassword-btn">
              <button
                className="edit-profile-submit-btn"
                onClick={() => changePassword()}>
                Submit
                </button>
              </div> </>:<>
            <div className="edit-profile-modal-wrap">
              <div className="camera-icon camera-icon-profile-page">
              <input type="file" style={{display:"none"}} ref={profileImageRef}  accept="image/png, image/jpeg ,image/svg,image/jpg" onChange={onFileChange} />
                <BsFillCameraFill onClick={()=>selecetfilePop()}  color="#ffffff" size={"0.8rem"}/>
              </div>

              <div className="edit-image">
                {profileImage?(<img className="edit-profile-image" src={profileImage} alt='cropped'/>):(userData?.profilePic ? (
                  <img className="edit-profile-image" 
                  src={userData?.profilePic} 
                  alt="profilePic" />      
                ) : ( <FaUserCircle  size={"10rem"} color="#989898" />))}
              </div>

              <div className="edit-form-wrap">
                <div className="edit-feild-1">
                  <div className="edit-input-label">
                    <label>Name</label>
                  </div>
                  <div className="edit-feild-text edit-feild-text-name">
                    <input
                      type="text"
                      name="name"
                      inputmode={inputStatus?"":"none"}
                      className="name-input-field Input-profile-Name"
                      placeholder={`${userData?.name?userData?.name:userData?.firstName}`}
                      onChange={handleInputValueName}
                      value={inputNameValue}
                    />
                  </div>
                </div>
                <div className="edit-feild-2">
                  <div className="edit-input-label">
                     <label>Location</label>
                  </div>
                  <div className="edit-feild-text">
                      <select className="country-select-profilepage" onChange={handleInputValueLocation} name="countries" id="profileCountry">
                        {Country.map((CountryName)=>{
                          return <option value={CountryName==="Delivers To"?"":CountryName} label={CountryName==="Delivers To"?"Select Country":CountryName}></option>
                        })}
                      </select>    
                    </div>
                </div>
              </div>
            </div>      
            <div className="edit-profile-submit">
              <button
                className="edit-profile-submit-btn"
                onClick={() => ProfilePatchApi()}>
                   Submit
                </button>
              </div> 
            </>}         
          </Modal>          
      <YourStyle setInputStatus={setInputStatus} setOpenEdit={setOpenEdit} userData={userData} />
      <CongratulationsAndEasyStep />
      <div className="cards-section-wrap">
        <div id="wishlist" className="containor">
          <h1 className="CeraProBold">#Wishlist</h1>
          <p className="ceraProLight responsive-subheading">
            Fashion is Fun When Social Lorem ipsum dolor sit amet, consectetur
            adipisicing elit. Maiores iure dolorum, accusamus unde ullam hic!
          </p>
          <div className="WhishList-container-scroll">
            <div className="main-card-div-Home-FreshFind scroll-div-wishlist">
              {wishListData?.length > 0 ? (
                wishListData?.map((card) => {
                  return (
                    <BrandCards
                      card={card}
                      addWishListApiData={addWishListApiData}
                    />
                  );
                })
              ):(
                <EmptyData
                wishListPage={true}
                 PageNu={"Pro-WishList"}
                  Heading={""}
                  ButtonText={"Discover new brands"}
                  EmptyMassage={"Your wishlist is empty. Like a few brands and create your wishlist for the next shopping spree"}
                />
              )}            
            </div>     
          </div>
          {wishListData?.length !== 0 && (
            
            <button
              style={{marginBottom:"-50px"}}
              className="discribe-btn"
              onClick={() => history.push("/discoverpage")}
            >
              <p>DISCOVER MORE</p>
              <img src={window.screen.width > 560?buttonArrowImg:buttonWhiteAarrow} alt="" />
            </button>
          )}
          <div className="pagination-container">
         {wishListData?.length !== 0 && <ReactPaginate 
                previousLabel={<GrPrevious className="Prev-Button-Pagination"/>}
                nextLabel={<GrNext className="Next-Button-Pagination"/>}
                pageClassName="All-li-Items"
                pageLinkClassName="li-Item-link"
                previousClassName="Prev-li-Point-main"
                previousLinkClassName="Prev-li-Point"
                nextClassName="Next-BTN-Point-Main"
                nextLinkClassName="Next-BTN-Link"
                breakLabel="..."
                breakClassName="Dot-Break-Point-Main"
                breakLinkClassName="Dot-Break-Point"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="All-Item-Main-Container"
                activeClassName="Item-active-Point"
                forcePage={pageOffset}
           />}</div>
        </div>
      </div>
      <Invite />
      <MustReadCardProfilePage setOpenAnother={setOpenAnother}   setBlogLength={setBlogLength}  blogTittle="#Pinned" />
      <ResMustReadPinned  blogTittle="#Pinned" />
      <Reviewbanner />
      <Yourreviewcontainer userReviewRating={userReviewRating} />
      <UpperFooter />
      <BottomFooter />
    </>
  );
}

export default Profilepage;
