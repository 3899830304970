import React, { useEffect } from "react";
import "./BottomFooter.css";
import Mail from "../../../assets/img/mail.svg";
import {Link} from 'react-router-dom';

export default function BottomFooter() {
  

  return (
    <>

      <div className="bottom-footer-wrap">
        <div className="footer-div-1">
          <ul className="bottom-footer-elements">
          <li>
            <Link className="footer-link" to='/'>Home</Link>
          </li>
            <li><Link className="footer-link" to='/aboutus'>About us</Link></li>
            <li><Link className="footer-link" to='/termandcondition'>Terms and Conditions</Link></li>
            <li>
            <Link className="footer-link" to='/blogpage'>Explore</Link>
          </li>
            <li><Link className="footer-link" to='/submitbrand'>
              Submit a new Brand</Link></li>
            <li><Link className="footer-link" to="/privacy">Privacy Policy</Link></li>
          </ul>
        </div>
        <div className="footer-div-2">
          <div className="footer-mail">
            <img src={Mail} alt="" />
            <p onClick={()=> window.open("mailto:fashion@spiffyyou.net")}>fashion@spiffyyou.net</p>
          </div>
        </div>
      </div>
      <div className="Disclaimer-FooterSection">
        Disclaimer - No copyright infringement intended . We do not own or claim to own any of the brand data , logos and pictures . All sourced from companies’ website / social media handles.
    </div>
      <div className="end-div">
        <p>© Spiffy all rights reserved</p>
      </div>
    </>
  );
}
