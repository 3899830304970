import React from 'react'
import "./AboutUsSlider.css";
import aboutMobile  from "../../../assets/about/aboutPageSFile.svg";
import MobileBanner from "../../../assets/MobileAboutBanner.png";
import AboutUSPage from "../../../assets/aboutPageHome.png"
export default function AboutUsSlider() {
    return (
        <div className="AboutUs-wrap">
            <div className="AboutUs-main">
            {window.screen.width > 560?<img src={aboutMobile} className='about-web-view-img' alt="" />:
            <img src={AboutUSPage} className='about-web-view-img-mob' alt=""  />}  
            </div>  
        </div>
    )
}

