import React from 'react'
import './profile.css'
import profile from '../../../assets/ProfielBannar.png'
import profileMobile from "../../../assets/img/profile-m.png";
import axios from 'axios';

function Profile() {
 
      
    return (
        <>
        <div className="container-web">
            <img src={profile} alt="" />
        </div>
         <div className="container-mobile">
         <img src={profile} alt="" />
     </div>
     </>
    )
}

export default Profile
