import {React,useState} from "react";
import "./BlogFilter.css";
import filter from "../../../images/filter.svg";
import { BsCheckLg } from "react-icons/bs";
import { FaTemperatureHigh } from "react-icons/fa";
export default function Filter({handleCallback,fitlerValue,setFileterValue}) {
  const [inputValue,setInputValue] = useState('');
  const [filtershow , setFiltershow] = useState(false);
  const [inputDisable ,setInputDisable] = useState(false);

  const onchange = (e) => {
    setInputValue(e.target.value);
  }
  const handleSearchData=(e)=>{
    handleCallback(inputValue);  
    if(e.keyCode == 13){
      setInputDisable(true);
      setTimeout(() => {
        setInputDisable(false);
      },2)
      }
  }
  const handleKeyUp =(e)=>{
    
  }
  return (
    <div className="filter-row-wrap">
     <div className="filter-search-container">           
      <div className="blog-search">
        <input 
            type="text"
            inputmode={inputDisable?"none":""}
            placeholder="search" 
            onKeyUp={handleSearchData}
            onChange={onchange}
            value={inputValue}  
         />
      </div>
      <div onClick={()=>setFiltershow(!filtershow)} className="blog-filter">
        <p className="sortBy-blog">Sort By</p>
        <img src={filter} alt="" />
         <div className={filtershow?"blog-page-filter-div":"hide-blog-filter"}>
           <div
            onClick={()=>
              setFileterValue({ RecentlyAdd:{value: -1, status: true},
              mostPopular: { value: 1, status: false },
              relevant:{ value: 1, status: false},
              ATOZ:{ value: 1, status: false},
              ZTOA:{ value: 1, status: false},
              value:"RecentlyAdded"})}
              className={"sort-option-box"}>
              <span className={fitlerValue.RecentlyAdd.status?"sortBy-option-container-SL":"sortBy-option-container"}>Recently Added</span>
              <span className={fitlerValue.RecentlyAdd.status?"sort-icon-container-SL":"sort-icon-container"}>
              <BsCheckLg className={fitlerValue.RecentlyAdd.status?"check-icon-blog-sort-SL":"check-icon-blog-sort"} />
              </span>
           </div>
           <div
             onClick={()=>
              setFileterValue({ RecentlyAdd:{value:"", status: false},
              mostPopular: { value:  1 , status: true },
              relevant:{ value: "", status: false},
              ATOZ:{ value: "", status: false},
              ZTOA:{ value: "", status: false},
              value:"MostPopular"})}
              className={"sort-option-box"}>
              <span className={fitlerValue.mostPopular.status?"sortBy-option-container-SL":"sortBy-option-container"}>Most Popular</span>
              <span className={fitlerValue.mostPopular.status?"sort-icon-container-SL":"sort-icon-container"}>
              <BsCheckLg className={fitlerValue.mostPopular.status?"check-icon-blog-sort-SL":"check-icon-blog-sort"} />
              </span>
           </div>
           <div
             onClick={()=>
             setFileterValue({ RecentlyAdd:{value: 1, status: false},
              mostPopular: { value: 1, status: false },
              relevant:{ value: -1, status: true},
              ATOZ:{ value: 1, status: false},
              ZTOA:{ value: 1, status: false},
              value:"Relevant"})}
              className={"sort-option-box"}>
              <span className={fitlerValue.relevant.status?"sortBy-option-container-SL":"sortBy-option-container"}>Relevant</span>
              <span className={fitlerValue.relevant.status?"sort-icon-container-SL":"sort-icon-container"}>
              <BsCheckLg className={fitlerValue.relevant.status?"check-icon-blog-sort-SL":"check-icon-blog-sort"} />
              </span>
         </div>
         <div
             onClick={()=>
             setFileterValue({ RecentlyAdd:{value: "", status: false},
              mostPopular: { value: "", status: false },
              relevant:{ value: "", status: false},
              ATOZ:{ value: 1, status: true},
              ZTOA:{ value: "", status: false},
              value:"ATOZ"})}
              className={"sort-option-box"}>
              <span className={fitlerValue.ATOZ.status?"sortBy-option-container-SL":"sortBy-option-container"}>A-Z</span>
              <span className={fitlerValue.ATOZ.status?"sort-icon-container-SL":"sort-icon-container"}>
              <BsCheckLg className={fitlerValue.ATOZ.status?"check-icon-blog-sort-SL":"check-icon-blog-sort"} />
              </span>
         </div>
         <div
             onClick={()=>
             setFileterValue({ RecentlyAdd:{value: "", status: false},
              mostPopular: { value: "", status: false },
              relevant:{ value: "", status: false},
              ATOZ:{ value: "", status: false},
              ZTOA:{ value: -1, status: true},
              value:"ZTOA"})}
              className={"sort-option-box"}>
              <span className={fitlerValue.ZTOA.status?"sortBy-option-container-SL":"sortBy-option-container"}>Z-A</span>
              <span className={fitlerValue.ZTOA.status?"sort-icon-container-SL":"sort-icon-container"}>
              <BsCheckLg className={fitlerValue.ZTOA.status?"check-icon-blog-sort-SL":"check-icon-blog-sort"} />
              </span>
         </div>
        </div>
      </div>
      </div>
    </div>
  );
}
