import React from 'react'
import './AvailableDelivery.css';
function AvailableDelivery({showAvailableDeliveryCard}) {
    return (
        <div className={showAvailableDeliveryCard?"main-container-DeliveryCard":"Hidden_class_available"}>
             <p>Delivers Worldwide</p>            
        </div>
    )
}

export default AvailableDelivery
