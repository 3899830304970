import {React,useState} from 'react';
import {AiFillLike,AiOutlineLike} from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import dotenv from "dotenv";
dotenv.config();
function ReviewReply({commentReply}) {
    const [likeStatus,setLikeStatus] = useState(commentReply?.liked);
    const [countOfLikes,setCountOfLikes] = useState(commentReply?.likes?commentReply?.likes?.length:0);
    const commentOncommentLikeAPi=async(commentReplyID)=>{
        try {
          const res =await axios.patch(`${process.env.REACT_APP_API_URL}/comment-on-comments/like/${commentReplyID}`).then(()=>{
            setLikeStatus(!likeStatus)
            if(likeStatus){
                setCountOfLikes(countOfLikes-1) 
            }else{
                setCountOfLikes(countOfLikes+1) 
            }
          }) 
       } catch (error) {
           console.log(error,"Message From Comment On Comment Like Api")
       }
      }
  return (
  <div className="main-container-comment-on-comments">
  <div style={{marginLeft:"54px",marginBottom:"30px"}}>
  <div className="commment-div-container-reply">
<div className="like-count-and-button">
{likeStatus?<AiFillLike onClick={()=>commentOncommentLikeAPi(commentReply?._id)} className="Like-button-comment-inbox-reply" />
  :<AiOutlineLike onClick={()=>commentOncommentLikeAPi(commentReply?._id)} className="Like-button-comment-inbox-reply"/>}
  <span className='comment-like-text'>{countOfLikes}</span>
</div>

 <div className="comment-userName-reply">

{/* {commentReply?.userId?.profilePic ? (
                <img
                  src={commentReply?.userId?.profilePic.includes("googleusercontent")?commentReply.userId.profilePic:`${process.env.REACT_APP_API_URL+commentReply?.userId?.profilePic}`}
                  alt=""
                  className="reply-comment-card-profile-img"
                />
              ) : (
                <span>
                <FaUserCircle className="comment-profile-icon" />
                </span>
              )} */}
 <p className="user-name-reply">{ commentReply?.isAdmin?"Admin":commentReply.userId?.name}</p>         
</div>
   <div className="comment-text-reply"><p>{commentReply?.comment}</p></div>
</div> 

</div>
</div>
);
}

export default ReviewReply;
