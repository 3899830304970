import React from "react";
import "./BannerSection.css";
import clothImg from "../../../assets/img/brands-img-cover.jpg";
import brandImg from "../../../assets/img/Brands page banner.png";
import BrandPageBanner from "../../../assets/Banner for every brand card.png"

export default function BannerSection() {
  return <div className="banner-wrap">
    <img src={BrandPageBanner} alt="" />
  </div>;
}
