import {React, useRef} from "react";
import "./QuizAndSocialButton.css";
import {useLocation,useHistory} from "react-router-dom";
import Bulb from "../../../assets/ideaImageBulb.svg";
export default function QuizAndSocialButton() {
  const location = useLocation();
  const history = useHistory();
  const takeQuize = "https://www.tryinteract.com/share/quiz/60a3278c331f270017e93cd2";
    return (
      (!location.pathname.includes("/discoverpage/")  &&
    <div className={location.pathname==="/loginpage"?"side-icons-hide-class":location.pathname==="/register"?"side-icons-hide-class":"quiz-and-social-btn-wrap "}>
      <button className="social-btn-wrap">
      <a href="https://www.instagram.com/spiffy.you/" target={"blank"}>
        <img
          src={require("../../../assets/img/instagram.svg").default}
          alt=""/>
        </a>
        <a href="https://in.pinterest.com/kikaicomp/_created/" target="_blank" rel="noopener noreferrer">
          <img
            src={require("../../../assets/img/pintrest.svg").default}
            alt="pintrest"
          />
        </a>
                 
        <a href="https://www.facebook.com/people/Spiffy-You/100078406224985/" target="_blank" rel="noopener noreferrer">
          <img
            src={require("../../../assets/img/facebook.svg").default}
            alt="facebook"
          />
        </a>
      </button>
      <button onClick={location.pathname==="/"?()=>window.open(`${takeQuize}`,"_blank"):()=>{history.push("/submitbrand")}} className={location.pathname==="/"?"quiz-btn-wrap":"quiz-btn-wrap-other"}>
      {location.pathname==="/"?<p className="text-class-side-com">TAKE YOUR QUIZ</p>:<p className="text-class-side-com">RECOMMEND A NEW BRAND</p>}
        {location.pathname==="/"?<img className="like-button-side-com-H" src={'likeImagexd.svg'} alt="like"/>
        :<img className="like-button-side-com" src={Bulb} alt="Bulb" />}
        {/* HiOutlineLightBulb */}
      </button>
    </div>
   
   )
   );
}
