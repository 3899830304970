import React from 'react';
import './Done.css';
function Done({showValueOfDone}) {
    return (
        <div className={showValueOfDone?"main-container-DoneCard":" Hidden_class_in_done"}>
          <p>Done</p>            
        </div>   
    )
}

export default Done
