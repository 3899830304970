import {React,useState,useContext} from 'react';
import axios from "axios";
import { AiOutlineLike,AiFillLike } from 'react-icons/ai';
import LogContext from "../../../LogContext";

import dotenv from "dotenv";

dotenv.config();

function CommentOnComment({reply,CommentOnCommentID}) {
    const logStatus = useContext(LogContext);
    const [likeStatus,setLikeStatus] = useState(reply?.liked)
    const [countOfLikes,setCountOfLikes] = useState(reply?.likes?reply?.likes?.length:0);
    const commentOncommentLikeAPi=async()=>{
      const token = localStorage.getItem("token");
      if(!token){
        logStatus.setOpenPop(true)
      }
      else{
        try {
          await axios.patch(`${process.env.REACT_APP_API_URL}/comment-on-comments/like/${reply._id?reply._id:CommentOnCommentID}`).then(()=>{
            setLikeStatus(!likeStatus)
            if(likeStatus){
                setCountOfLikes(countOfLikes-1) 
            }else{
                setCountOfLikes(countOfLikes+1) 
            }
          }) 
       } catch (error) {
           console.log(error,"Message From Comment On Comment Like Api")
       }
      }

    }   
  
    return (
        <div className="Comment-On-Blogs">
             <div style={{marginLeft:"54px",marginBottom:"30px"}}>
             <div className="commment-div-container-reply">
          <div className="like-count-and-button">
          {likeStatus?<AiFillLike onClick={()=>commentOncommentLikeAPi()} className="Like-button-comment-inbox-reply" />
             :<AiOutlineLike onClick={()=>commentOncommentLikeAPi()} className="Like-button-comment-inbox-reply"/>}
             <span className='comment-like-text'>{countOfLikes}</span>
          </div>
  
            <div className="comment-userName-reply">
         {/* {reply?.userId?.profilePic ? (
                <img
                  src={reply?.userId?.profilePic.includes("googleusercontent")?reply.userId.profilePic:`${process.env.REACT_APP_API_URL+reply?.userId?.profilePic}`}
                  alt=""
                  className="reply-comment-card-profile-img"
                />
              ) : (
                <span>
                <FaUserCircle className="comment-profile-icon" />
                </span>
              )} */}
            <p className="user-name-reply">{reply?.isAdmin ?"Admin":reply?.userId?.name}</p>         
          </div>
              <div className="comment-text-reply"><p>{reply?.comment}</p></div>
          </div> 
         
          </div>
        </div>
    )
}

export default CommentOnComment
