import React from "react";
import "./FitQuality.css";
import FitQualityAdvanced from "./FitQualityAdvanced";

export default function FitQuality({isBrandPage,brandPageDataWithid}) {
  const fitValue = {
    FirstValue:"Runs Small",
    SecondValue:"True To Size",
    ThirdValue:"Runs Large",
  };
  const QualityValue = {
    FirstValue:"Could Be Better",
    SecondValue:"Ok",
    ThirdValue:"Amazing",
  };
  return (
    <div className="fit-and-quality-wrap">
      <div className="fit-wrap">
        <div className={isBrandPage ?"lable-b":"fit-title submitbrand-row-padding lable"}>
          <lable >Fit</lable>
        </div>
        <div className="radio-select">

         <FitQualityAdvanced brandPageDataWithidFit={brandPageDataWithid?.fit} value={true} name={'fit'} titleValue={fitValue}/>
          
        </div>
      </div>
      <div className={isBrandPage ?"line-padding closing-line":"not-closing-line"}></div>

      
      <div className="quality-wrap">
        <div className={isBrandPage?"lable-b":"qualit-title submitbrand-row-padding lable"}>
          <lable>Quality</lable>
        </div>
        <div className="radio-select">
           <FitQualityAdvanced brandPageDataWithidquality={brandPageDataWithid?.quality}  value={false} name={'quality'} titleValue={QualityValue}/>
        </div>
      </div>

    </div>
  );
}

