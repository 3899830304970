import React from "react";
import "./OtherDetails.css";
import trueImg from '../../../assets/img/check.png';
import falseImg from '../../../assets/img/close.png'

export default function OtherDetails({ brandPageDataWithid}) {
  return (
    <div className="other-details-wrap">
      <div className="other-detail-heading">
        <h1 className="same-heading-brand-page">Other Details</h1>
      </div>
      <div className="other-detail-options">
        <div className="first-column">
          <div className="first-option">
            <div className="true-false-img-div">
              <img src={brandPageDataWithid?.deliversWorldWide?trueImg:falseImg} alt="" />
              {/* className="closeImg" */}
            </div>
            <div className="option-text">Delivers globaly?</div>
          </div>
          <div className="second-option">
          <div className="true-false-img-div">
              <img src={brandPageDataWithid?.onlineShopping?trueImg:falseImg} alt="" />
            </div>
            <div className="option-text"> Online shopings?</div>  
          </div>
        </div>
        <div className="second-column">
        <div className="first-option">
            <div className="true-false-img-div">
              <img src={brandPageDataWithid?.plusSizesAvailable?trueImg:falseImg} alt="" />
            </div>
            <div className="option-text">Plus Sizes Available?</div>
          </div>
          <div className="second-option">
          <div className="true-false-img-div">
              <img src={brandPageDataWithid?.petiteSizesAvailable?trueImg:falseImg} alt="" />
            </div>
            <div className="option-text"> Petite Sizes Available?</div>
           
          </div>
        </div>
      </div>
    </div>
  );
}
