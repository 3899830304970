import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import welcomeSlider1Img from "../../../assets/img/welcome-slider1-img.svg";
import welcomeSlider2Img from "../../../assets/img/home-slider-04.png";
import welcomeSlider3Img from "../../../assets/img/home-slider-05.png";
import "./Slider.css";
import buttonArrowImg from "../../../assets/img/button-arrow.svg";
import buttonArrowImgWhite from "../../../assets/img/whiteArrow.svg";
import { useHistory } from "react-router-dom";

export default function Slider() {
  const history = useHistory();
  return (
    <div className="slider-Main-Conatainer">
      <div className="slider-wrap">
        <Carousel showThumbs={false}>
          <div className="slider-1">
            <div className="slider-1-text">
              <h1 className="CeraProBold">
                Find your next favourite fashion brand curated by customers, not
                influencers.
              </h1>
              {/* Make these texts as a constant string variable in a separate file so that when there is a need to use it in multiple place you can replace the text by only changing the value assigned to that variable instead of manually changing it in all the files. */}
              <p className="CeraProLight welcome-slider-subheading">
                Whether you are a minimalist or a style diva, our community-
                driven reviews will help you discover under-the-radar fashion
                label tailormade for you. 
              </p>
              <button
                className="slider-discribe-btn"
                onClick={() => history.push("/discoverpage")}
              >
                <p className="slider-Image-Button">Browse Fashion Labels</p>
                <img
                  src={buttonArrowImg}
                  alt=""
                  className="redImg "
                  style={{ width: "8%",}}
                />
                <img
                  src={buttonArrowImgWhite}
                  className="whiteImg"
                  alt=""
                  style={{ width: "8%" }}
                />
              </button>
            </div>
            <div className="slider-1-img slider-img-section">
              <img
                src={welcomeSlider1Img}
                alt=""
                className="slider-img-section welcome-slider-img-1"
              />
            </div>
          </div>
          <div className="slider-1">
            <div className="slider-1-text">
              <h1 className="CeraProBold">
                Find your next favourite fashion brand curated by customers, not
                influencers.
              </h1>
              <p className="Nunito-SemiBold welcome-slider-subheading">
                Whether you are a minimalist or a style diva, our community-
                driven reviews will help you discover under-the-radar fashion
                label tailormade for you.
              </p>
              <button
                className="slider-discribe-btn-2 "
                onClick={() => history.push("/discoverpage")}
              >
                <p>Browse Fashion Labels</p>
                <img
                  src={buttonArrowImgWhite}
                  className="whiteImg2"
                  alt=""
                  style={{ width: "8%" }}
                />
                <img
                  src={buttonArrowImg}
                  alt=""
                  className="redImg2"
                  style={{ width: "8%" }}
                />
              </button>
            </div>
            <div className="slider-1-img slider-img-section">
              <img
                src={welcomeSlider2Img}
                alt=""
                className="slider-img-section welcome-slider-img-2"
                
              />
            </div>
          </div>
          <div className="slider-1">
            <div className="slider-1-text">
              <h1 className="CeraProBold">
                Find your next favourite fashion brand curated by customers, not
                influencers.
              </h1>
              <p className="Nunito-SemiBold welcome-slider-subheading">
                Whether you are a minimalist or a style diva, our community-
                driven reviews will help you discover under-the-radar fashion
                label tailormade for you. 
              </p>
              <button
                className="slider-discribe-btn"
                onClick={() => history.push("/discoverpage")}
              >
                <p>Browse Fashion Labels</p>
                <img
                  src={buttonArrowImg}
                  alt=""
                  className="redImg"
                  style={{ width: "8%" }}
                />
                <img
                  src={buttonArrowImgWhite}
                  className="whiteImg"
                  alt=""
                  style={{ width: "8%" }}
                />
              </button>
            </div>
            <div className="slider-1-img slider-img-section">
              <img
                src={welcomeSlider3Img}
                alt=""
                className="slider-img-section welcome-slider-img-3"
              />
            </div>
          </div>
        </Carousel>
      </div>
      <div className="responsive-welcom-text">
        <div className="welcome-main-heading">
          <h1>
            Find your next favourite fashion brand curated by customers, not
            influencers.
          </h1>
        </div>
        <div className="welcome-subheading">
          <p className="Nunito-SemiBold welcome-slider-subheading responsive-welcom-text-para">
            Whether you are a minimalist or a style diva, our community- driven
            reviews will help you discover under-the-radar fashion label
            tailormade for you.
          </p>
        </div>
        <div>
          <button
            className="slider-discribe-btn-2 responsive-welcome-text-btn"
            onClick={() => history.push("/discoverpage")}
          >
            <p className="responsive-wel-slider-para">
            Browse Fashion Labels
            </p>
            <img
              src={buttonArrowImgWhite}
              className="whiteImg2"
              alt=""
              style={{ width: "8%" }}
            />
            <img
              src={buttonArrowImg}
              alt=""
              className="redImg2"
              style={{ width: "8%" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
