import React from "react";
import "./Team.css";
import { FaLinkedin } from "react-icons/fa";
import akansha from "../../../assets/Akansha.png";
import astha from "../../../assets/Astha.jpg";
import garima from "../../../assets/Garima.jpg";

export default function Team() {
  const teamprofilearr = [
    {
     name:"ASTHA PASRICHA",
     linkedinLink:"www.linkedin.com/in/astha-pasricha-a32b01a/",
     profilePic:astha,
     FirstInfo:`Product management and sales strategy professional in global positions for over 12 years .`,
     secondInfo:` Led multiple change management projects  to drive revenues and shape processes .`, 
     ThirdInfo:`MBA from KJ Somaiya school of Management, Mumbai`,
     lastLIne:true
    },
    // {
    //   name:"GARIMA MAMGAIN",
    //   linkedinLink:"www.linkedin.com/in/garimamamgain/",
    //   profilePic:garima,
    //   FirstInfo:`Marketing and strategy professional with over 12 years of multi-industry experience with top consumer and business brands.`,
    //   secondInfo:` Led key global transformation projects in the Asia Pacific region (APAC). `,
    //   ThirdInfo:`      Masters in Business Administration (MBA) from the Indian Institute of Management, Lucknow`,
    //   lastLIne:true
    //  },
      {
      name:"AKANKSHA VYAS",
      linkedinLink:"www.linkedin.com/in/akankshavyas90/",
      profilePic:akansha,
      FirstInfo:`Marketing professional with 5 years of experience in product and brand communication, distributor & retailer network development, campaign management, and loyalty programs.`,
      secondInfo:`Masters in Business Administration (MBA) from the Indian Institute of Management, Indore`,
      lastLIne:false
     },
]
  return (
    <div className="team-section-wrap">
      <div className="team-background-div">
        <h1>#The Team</h1>
      </div>
  
      <div className="team-card-wrap">
        {teamprofilearr.map((data) => {
          return (
            <div className="team-card">
              <div className="team-img-div"><img className={!data.lastLIne&&"imageContainerTeam"}  src={data.profilePic}/></div>
              <div className="connected-line"></div>
              <div className="team-linkedin" onClick={()=>window.open(`//${data.linkedinLink}`)}>
                <FaLinkedin size={"1.5rem"} color={"#31A2AC"}/>
              </div>
              <div className="team-description">
                <h1>{data.name}</h1>
                <p>
                {data.profileInfo}
                <ul>
                  <li>{data.FirstInfo}</li>
                  <li>{data.secondInfo}</li>
                 {data.lastLIne && <li>{data.ThirdInfo}</li>}
                </ul>
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
