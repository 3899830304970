import { React,useState,useContext } from "react";
import "./Rate.css";
import ReactStars from "react-rating-stars-component";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import dotenv from "dotenv";
import axios from "axios";
import LogContext from "../../../LogContext.js"
dotenv.config();

export default function Rate({
  setOpenLginPopUp,
  brandPageDataWithid,
  starValueonBrandPage,
  setStarvalueonBrandpage,
  setReviewButtonStatus,
  setBrandRatingID,
  brandRatingID,
  ReviewsButtonState
}) {
  const history = useHistory();
  const [ratingValue,setRatingValue] = useState()
  const logStatus = useContext(LogContext);
  const checkLogIn = () => {
    const token = localStorage.getItem("token");
    if(token) {
      history.push({
        pathname: "/reviewbrand",
        state: {
          BrandID:brandPageDataWithid?._id,
          Rating_id:brandPageDataWithid?.userRated ? brandPageDataWithid?.userRated?._id : brandRatingID,
          Brand_name:brandPageDataWithid?.title,
          ratingvalueComes: brandPageDataWithid?.userRated ? brandPageDataWithid?.userRated?.rating : starValueonBrandPage,
        },
      })
    } else {
      logStatus.setOpenPop(true)
    }
  };


  
  const ratingApi=async(starValue)=>{
    const token = localStorage.getItem("token");
    if(token){
      try{ 
      const res =  await axios.post(`${process.env.REACT_APP_API_URL}/rating-review/}`,{
         brandId:brandPageDataWithid?._id,
         rating:starValue
        })
        setBrandRatingID(res?.data?._id)
        setRatingValue(res?.data?.rating)
        setReviewButtonStatus(res?.data?.rating)
        } catch (error) {
          console.log(error,"message from ratingStarsApi")
        }
    }
  }

  const ratingchanged = (newValue) => {
    setStarvalueonBrandpage(newValue);
    ratingApi(newValue)  
  };

  return (
    <div className="rating-outer-div">
      <div className="rate-wrap">
        <div className="rate-div-1">
          <h1 className="same-heading-brand-page">Rate It</h1>
        </div>
        <div className="rate-div-2">
          <div className="rate-div-star-section">
            <ReactStars
              key={brandPageDataWithid?.userRated?.rating}
              size={40}
              count={5}
              edit={(brandPageDataWithid?.userRated?.rating)?false:true}
              value={brandPageDataWithid?.userRated?.rating?brandPageDataWithid?.userRated?.rating:starValueonBrandPage}
              color={"black"}
              onChange={ratingchanged}
              emptyIcon={ <BsStar className="Rating-start-BrandPage Rating-start-reviewPage" />      }
              halfIcon={
                <BsStarHalf className="Rating-start-BrandPage Rating-start-reviewPage-fill" />
              }
              filledIcon={
                <BsStarFill className="Rating-start-BrandPage Rating-start-reviewPage-fill" />
              }
              activeColor={"#AF1C1C"}
            />
          </div>
         {!ReviewsButtonState&&<div className="rate-div-review-btn">
            <button onClick={()=>checkLogIn()}>Write a Review</button>
          </div>}
        </div>
      </div>
    </div>
  );      
}
