import {React,useState} from 'react'
import App from './App.js'
import { BrowserRouter } from "react-router-dom";
import LOGContext from "./LogContext";
import { useLocalStorage } from '@mantine/hooks';

function AppWraper() {
    const [value, setValue] = useLocalStorage({ key: 'logInStatus', defaultValue:false});
    const [openPop,setOpenPop] = useState(false);
    const [paginationPageOffSet,setPaginationPageOffSet] = useState(0)
    const [emptyStarsLogOutUser ,setEmptystarsLogOutUser] = useState(Math.random(100));
    const [loginMessage ,setLoginMessage] = useState(false);
    const [isLoggedIn, setisLoggedIn] = useState(false);
    const [selectedRating,setSelectedRating] = useState(0);
    const [checked,setChecked] = useState(false);
    const [inputValueCountry,setInputValueCountry] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [selectSortItem, setselectSortItem] = useState({
      highlyRateted: { value: "", status: false },
      mostPopular: { value: "", status: false },
      recentlyAdded: { value: -1, status: true },
      relevant: { value: "", status: false },
      ATOZ: { value: "", status: false },
      ZTOA: { value: "", status: false },
      value: "RecentlyAdded",
    });
    const [catValue, setCatValue] = useState([]);
    const [knownValue ,setKnownValue] = useState([]);

  return (
    <LOGContext.Provider value={
      {loginMessage ,setLoginMessage,
      openPop,setOpenPop,
      paginationPageOffSet, setPaginationPageOffSet,
      setEmptystarsLogOutUser,emptyStarsLogOutUser,
      isLoggedIn, setisLoggedIn,
      value, setValue,
      selectedRating,setSelectedRating,
      checked,setChecked,
      inputValueCountry,setInputValueCountry,
      inputValue, setInputValue,
      selectSortItem, setselectSortItem,
      catValue, setCatValue,
      knownValue ,setKnownValue
      }}>
       <BrowserRouter>
       <App/>
       </BrowserRouter>
    </LOGContext.Provider>  
  )
}

export default AppWraper