import { React, useState,useEffect ,useContext } from "react";
import { withRouter } from "react-router-dom";
import "./BlogCards.css";
import "../../ui/mustread/MustReadCards.css";
import buttonWhiteAarrow from "../../../assets/img/button-white-arrow.svg";
import { BiCommentDetail, BiShareAlt } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { HiThumbUp, HiOutlineThumbUp } from "react-icons/hi";
import dotenv from "dotenv";
import { Modal } from "react-responsive-modal";
import moment from 'moment';
import { FaWhatsappSquare, FaFacebookSquare } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LogContext from "../../../LogContext";
import CommingSoon from "../../../assets/canva3.png";
import { FacebookShareButton,WhatsappShareButton,EmailShareButton} from "react-share";
dotenv.config();

function MustReadCards({
  card,
  pro,
  likeBlogApi,
  fontValue,
}) {

  const [like, setLike] = useState(false);
  const [openShare,setOpenShare] = useState(false);
  const showDate =()=>{
    const date = new Date(card?.createdAt);
    const upDate =moment(date).format("DD MMM YYYY")  
     return upDate;
  }
  const  logStatus = useContext(LogContext);
  const history = useHistory();
  const [copy ,setCopy] = useState(false);
  const [likeCount,setLikeCount] = useState(0)
  const commentCount = card?.comments?.length;
  useEffect(()=>{
    setLikeCount(card?.likes?.length);
    setLike( localStorage.getItem("token")&& card?.liked);
  },[card?.title])
  
  const likeClickhandler = () => {
    // alert(likeCount)
    const token = localStorage.getItem("token");
    if (token) {
      if (like) {
        setLike(false);
        setLikeCount((likeCount)-1)
        likeBlogApi(card);
      } else {
        setLike(true);
        setLikeCount((likeCount)+1)
        likeBlogApi(card);
      }
    } else {
      logStatus.setOpenPop(true);
    }
  };

  const openBlogPage = (hash) => history.push({
    pathname: `/blogpage/${card?._id}`,
    state:{BlogID:card?._id,likeCountvalue:likeCount, commentCount},
    hash,
  })

  const handleImageError =(e)=>{
     e.target.src=CommingSoon;
  }

  const classNameFunction = (pro) => {
    if (pro === "HomePage") {
      return "MR-cards-wrap-blog-HomePage";
    } else if (pro === "ProfilePage") {
      return "MR-cards-wrap-blog-HomePage";
    } else if (pro === "BlogPage") {
      return "MR-cards-wrap-blog";
    }
  };

  const MAX_LENGTH = 155;
  const Max_Length_HOME = 170;
  const MAX_LENGTH_TITLE = 35;
  
  return (
    <div  className={classNameFunction(pro)}>
              <Modal open={openShare} onClose={() => setOpenShare(false)} center>
                <h2>Share</h2>
                <div className="share-modal-wrap">
                  <div className="whatsapp share-modal">
                <WhatsappShareButton
                  url={"https://www.spiffyyou.com/blogpage/"+card?._id}
                  quote={"Recommended Blog:"+card?.title}
                  hashtag={"#spiffy"}
                  description={"aiueo"}
                  className="Demo__some-network__share-button">
                  <FaWhatsappSquare color={"brown"} size={"3rem"} /></WhatsappShareButton>
                  </div>
                  <div className="facebook share-modal">
                <FacebookShareButton
                    url={"https://www.spiffyyou.com/blogpage/"+card?._id}
                      quote={"Recommended Blog:"+card?.title}
                    hashtag={"#spiffy"}
                    description={"aiueo"}
                    className="Demo__some-network__share-button">
                  <FaFacebookSquare color={"brown"} size={"3rem"} />
                </FacebookShareButton>
                  </div>
                  <div className="mail share-modal">
                  <EmailShareButton
                    url={"https://www.spiffyyou.com/blogpage/"+card?._id}
                       quote={"Recommended Blog:"+card?.title}
                    hashtag={"#spiffy"}
                    description={"aiueo"}
                    className="Demo__some-network__share-button"
                    >
                      <IoIosMail color={"brown"} size={"4rem"} />
                    </EmailShareButton>
                  </div>
                </div>
                <div style={{padding:'10px'}} className="link-row">
                  <div className="link-for-copy">https://spiffyyou.com/blogpage/{card?._id}</div>
                  <div className="copy-btn">
                  <CopyToClipboard
                   text={"https://www.spiffyyou.com/blogpage/"+card?._id}
                   onCopy={()=>setCopy(true)}>
                    <button style={{marginLeft:"10px"}}>Copy</button>
                    </CopyToClipboard>  
                  </div>
                </div>
              </Modal>
      <div className={
          pro === "ProfilePage"
            ? "MR-main-card blog-main-card-profile"
            : " MR-main-card blog-main-card"
        }>
        <div className="MR-card-top blog-card-top">
          <h1
          onClick={() => openBlogPage("OpenBlogPageTop")} 
           className="CeraProMedium blogTitle">
            {`${
              card?.title.trim().length > MAX_LENGTH_TITLE
                ? card?.title.substring(0, MAX_LENGTH_TITLE) + "..."
                : card?.title
            }`}
          </h1>
          <p className="homepage-p blog-data-heading">{showDate()}</p>
        </div>
        <div className="MR-card-midd">
          <div className="MR-midd-text blog-midd-text">
            <p
              className={
                pro === "HomePage"
                  ? "NunitoLight blog-text blogText-section"
                  : fontValue === "ProFilePage"
                  ? "blogText-section-Pro"
                  : "blogText-section-Ex"
              }
            >
              {`${
                card?.summary.trim().length >
                (pro === "HomePage" ? Max_Length_HOME : MAX_LENGTH)
                  ? card?.summary.substring(
                      0,
                      pro === "HomePage" ? Max_Length_HOME : MAX_LENGTH
                    ) + "..."
                  : card?.summary
              }`}
            </p>
            <div className="continue-reading blog-continue-reading">
              <button
                className="Nunito-Regular"
                onClick={() => openBlogPage("OpenBlogPageTop")}
              >
                Continue Reading
                <img className="arrow-Image-blog-button" src={buttonWhiteAarrow} alt="" />
              </button>
            </div>
          </div>
          <div
            className={
              pro === "HomePage"
                ? "MR-midd-img blog-midd-img Blog-image-expolre"
                : fontValue === "ProFilePage"
                ? "MR-midd-img-Pro  Blog-image-expolre"
                : "MR-midd-img-Ex blog-midd-img Blog-image-expolre"
            }>
            <img
              onClick={() => openBlogPage("OpenBlogPageTop")}
              src={`${card?.thumbnailImage}`}
              alt=""
              className="Image-width-res"
              onError={handleImageError}
            />
          </div>
        </div>
        <div className="MR-card-bottom">
          <div className="MR-card-icon">
           <div className="like-blog">
           {like ? (              
              <HiThumbUp
                onClick={() => likeClickhandler()}
                className="blog-card-icons"
              />              
            ) : (
              <HiOutlineThumbUp
                onClick={() => likeClickhandler()}
                className="blog-card-icons"/>
            )}
            <p>{likeCount}</p>
           </div>
           <div className="comment-blog">
           <BiCommentDetail
              onClick={() => openBlogPage("commentedits")}
              className="blog-card-icons">

              </BiCommentDetail>
             <p>{commentCount}</p>          
             </div>
             <div onClick={()=>setOpenShare(true)} className="share-blog">
             <BiShareAlt
               // onClick={() => setOpenAnother(true)}
               className="blog-card-icons"
              />
             </div>
           </div> 
         </div>
       </div>
     </div>
   );
 }

export default MustReadCards;
