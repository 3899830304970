import {React,useEffect} from "react";
import "./Burbery.css";
import fillStar from "../../../assets/img/rad-star.svg";
import blankStar from "../../../assets/img/blank-star.svg";
import ButtonOnBrand from "../Buttons/ButtonOnBrand";

export default function Burbery({setBrandRatingID,brandRatingID,ReviewButtonStatus,setOpenLginPopUp,scrollfunction ,starValueonBrandPage, brandPageDataWithid,open,setOpen, openAnother, setOpenAnother,ReviewsButtonState}) {

  let fullStars = new Array(5);
  fullStars.fill(0);
  useEffect(()=>{
    console.log(brandPageDataWithid,"ApiData")
  },[])
  return (
    <>
      <div className=" burbery-wrap">
        <div className="burbery-text-div">
          <div className="brand-name-div">
            <h1 className="CeraProBold">{brandPageDataWithid?.title}</h1>
          </div>
          <div className="burbery-rating ">
            <div className="StarRating-div-wrap">
              <div className="StarRating-div">
                {fullStars.map((star, index) => {
                  return index < parseInt(brandPageDataWithid?.ratingAvg) ? (
                    <img src={fillStar} alt=''></img>
                  ) : (
                    <img src={blankStar} alt=''></img>
                  );
                })}
              </div>
              <div className="rating-row-2">
                <div className="rating-num CeraProMedium">
                  {parseInt(brandPageDataWithid?.ratingCount)}{"      Reviews"}
                </div>
                <div className="rating-point-brandPage CeraProLight">
                  {""}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="brand-description">
          <p className="same-para-brand-page">{brandPageDataWithid?.description}</p>
        </div>
        <ButtonOnBrand
        ReviewsButtonState={ReviewsButtonState}
          setBrandRatingID={setBrandRatingID}
          brandRatingID={brandRatingID}
          ReviewButtonStatus={ReviewButtonStatus}
          setOpenLginPopUp={setOpenLginPopUp} 
          starValueonBrandPage={starValueonBrandPage} 
          brandPageDataWithid={brandPageDataWithid} 
          openAnother={openAnother} 
          setOpenAnother={setOpenAnother} 
          scrollfunction={scrollfunction} 
          open={open} 
          setOpen={setOpen} />
      </div>
    </>
  );
}

