import { useLocation,useHistory, Switch, Route} from "react-router-dom";
import React from 'react';
import "./app.css"
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import BrandPage from "./components/BrandPage/BrandPage";
import Profilepage from "./components/Profile/Profilepage/Profilepage";
import Discoverpage from "./components/Discover/Discoverpage/Discoverpage";
import BlogPage from "./components/BlogPage/Blog";
import OpenBlog from "./components/BlogPage/OpenBlog/OpenBlog";
import LogInpage from "./components/Auth/LogInPage";
import ReviewImage from "./assets/ReviewImage.png"
import BrandSumbit from "./assets/SumbitBrand.png"
import Register from "./components/Auth/Register";
import { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import {GiCancel} from "react-icons/gi";
import axios from "axios"; //This is imported but not used remove it.
import ResponsiveNav from "./components/ui/ResponsiveNavBar/ResponsiveNav";
import SubmitBrand from "./components/SubmitNewBrand/SubmitBrand";
import ReviewBrand from "./components/ReviewBrand/ReviewBrand";
import About from "./components/AboutUsPage/About";
import {ToastContainer } from "react-toastify";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ForgotLinkFile from "./components/Auth/ForgotLinkFile";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-icons/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-richtexteditor/styles/material.css";
import WebShareGfg from "./sharebutton";
import PrivacyPol from "./components/ui/PrivacyPol";
import TermAndCondition from "./components/ui/TermAndCondition";
import QuizAndSocialButton from "./components/ui/QuizAndSocialButton/QuizAndSocialButton";
import  LoginMessage from "./assets/canva1.png";
import EmailVerifycation from "./components/Auth/EmailVer";
import EmailVerifycationLink from "./components/Auth/EmailVerLink";
import Registration from "./assets/Registration.png";
import LoginPopCard from "./components/ui/CardComponent/LoginPopCard";
import logContext from "./LogContext";
import NewPassword from "./components/Auth/NewPassword";
import BlogCreation from "./components/BlogCreation/blog";  
import GetAll from "./components/BlogCreation/getAll/GetAll";
import Edit from "./components/BlogCreation/edit/Edit";

function App(props) {
  const location = useLocation()
  const history = useHistory();
  const [openModal ,setOpenModal] = useState(false);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [isReload ,setisReload] =  useState(false);
  const [ editOpen , setEditOpen] = useState(false);
  const [editOpenSumbit,setEditOpenSumbit] = useState(false);
  const [loginMessage ,setLoginMessage] = useState(false);
  const [navShow ,setNavShow] = useState(false);
  const [userData,setUserData] = useState(false);
  const [openEmailV ,setOpenEmailV] = useState(false);
  const [emailName,setEmailName] = useState();
  const [emailVerified ,setEmailVerified] = useState();
  const [registeration,setRegisteration] = useState(false);
  const [closeSearchBox ,setCloseSearchBox] = useState(false)
  const [inputValue,setInputValue] = useState("");
  const [inputValueMob,setInputValueMob] = useState("");
  const logState = React.useContext(logContext);
  const getUserIDApi = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
      await axios.get(
          `${process.env.REACT_APP_API_URL}/user/profile`,
          { headers: { Authorization: `Bearer ${token}` } }
        ).then((res)=>{
          localStorage.setItem("userID", res?.data?._id);
          setUserData(res?.data);
          // setEmailVerified(res?.data?.)
          setEmailVerified(res?.data?.emailVerified);
          setEmailName( res?.data?.email)
          localStorage.setItem("userEmail", res?.data?.email);
        })
        } catch (error) {
        console.log(error);
       }
     }
  };

  useEffect(() => {
    getUserIDApi();
  }, [isReload]);
   

 useEffect(()=>{
   setTimeout(()=>{
     setLoginMessage(false);
   },9000)
 },[loginMessage])

 const clearInputDataFromSearchAndCloseTheSideNvBar=()=>{
  setNavShow(false);
  setInputValue("")
  setInputValueMob("");

 }

//  <GiCancel onClick={()=>{setRegisteration(false)}} className="cancel-button-log" />


//  editOpen 
  return(
    <div>
    {/* editOpenSumbit */}
    {editOpen && <div className="Review-Image-container">  <img className="Review-Image" src={ReviewImage} alt="pic" /> <GiCancel onClick={()=>{setEditOpen(false)}} className="cancel-button" /></div>}
    {editOpenSumbit && <div className="Review-Image-container"><img className="Review-Image" src={BrandSumbit} alt="pic" /> <GiCancel onClick={()=>{setEditOpenSumbit(false)}} className="cancel-button" /> </div>}
    {loginMessage && <div className="login-Image-container"><img className="login-pop-image" src={LoginMessage} alt="pic" /> <GiCancel onClick={()=>{setLoginMessage(false)}} className="cancel-button" /> </div>}
    {registeration && <div className="login-Image-container"><img className="login-pop-image" src={Registration} alt="pic" /> <GiCancel onClick={()=>{setRegisteration(false)}} className="cancel-button" /> </div>}
    {logState.openPop  && <div className=""><LoginPopCard/> </div>}
    {/* logState.openPop */}
      <Modal
      open={openEmailV}
      onClose={()=>setOpenEmailV(false)}
      classNames={{
          overlay: " customOverlay",
          modal: " customModal",
        }}> Hello
      </Modal>
      <Modal
        open={openModal}
        onClose={ () => setOpenModal(false)}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}>
        <div className="BrnadPage-Log-Pop">
          <div className="login-image-pop pop-item-1">
            <img className="Login-image-pop" src="loginPopUp.svg" alt="" />
          </div>
          <div
            className="Brand-Page-log-pop-item pop-item-2"
            onClick={() => {
              history.push("/logInpage");
            }}>Login
          </div>
          <div className="Brand-Page-log-pop-item pop-item-3"
            onClick={() => {
              history.push("/Register");
            }}>
            Register
          </div>
        </div>
      </Modal>
       <ToastContainer autoClose={1999} position="top-center" />
        <QuizAndSocialButton />
        {location.pathname==="/EmailVerifycation"?"":(location.pathname.includes("/verify-email")?"":<Navbar inputValue={inputValue} setInputValue={setInputValue} isLoggedIn={isLoggedIn} setisLoggedIn={setisLoggedIn} closeSearchBox={closeSearchBox} setCloseSearchBox={setCloseSearchBox} />)}
        {location.pathname==="/EmailVerifycation"?"":(location.pathname.includes("/verify-email")?"":<ResponsiveNav inputValueMob={inputValueMob} setInputValueMob={setInputValueMob} navShow={navShow} setNavShow={setNavShow} isLoggedIn={isLoggedIn} setisLoggedIn={setisLoggedIn} />)}
        <Switch>
        <div onClick={()=>clearInputDataFromSearchAndCloseTheSideNvBar()}>
          {/* <Route exact path="/openBlog" component={OpenBlog} /> */}
          <Route exact path="/blogpage/:id" component={OpenBlog} />          
          <Route exact path="/profilepage" render={(props) => <Profilepage {...props} />}/>
          <Route exact path="/discoverpage" render={(props) =>
          (<Discoverpage isLoggedIn={isLoggedIn} {...props} />)} />
          {/* <Route exact path="/brandpage" component={BrandPage} /> */}
          <Route exact path="/discoverpage/:id" component={BrandPage} />
          <Route exact path="/loginpage" render={(props) =>
          (<LogInpage {...props} isLoggedIn={isLoggedIn}  setLoginMessage={setLoginMessage} setisReload={setisReload} setisLoggedIn={setisLoggedIn} />)} />
          <Route exact  path="/register" render={(props) => 
          (<Register  {...props} setRegisteration={setRegisteration} isLoggedIn={isLoggedIn} setisReload={setisReload} setisLoggedIn={setisLoggedIn} />)} />
          <Route exact path="/BlogPage" component={BlogPage} />
          <Route exact path="/submitbrand" render={(props)=>(<SubmitBrand {...props}  editOpenSumbit={editOpenSumbit} setEditOpenSumbit={setEditOpenSumbit}    />)} />
          <Route exact path="/reviewbrand" render={(props)=>(<ReviewBrand  {...props} edittOpen={editOpen}  setEditOpen={setEditOpen}   />)} />
          <Route exact path="/AboutUs" component={About} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/shareButton" component={WebShareGfg} />
          <Route exact path="/ForgotLinkFile/:token" component={ForgotLinkFile} />
          <Route exact path="/privacy" component={PrivacyPol} />
          <Route exact path="/new/:token" component={NewPassword} />
          <Route exact path="/termandcondition" component={TermAndCondition} />
          <Route exact path="/EmailVerifycation" render={()=>(<EmailVerifycation emailname={emailName}/>)} />
          <Route exact path="/verify-email/:token"  component={EmailVerifycationLink} />
          <Route exact path="/blog-creation/:token"  component={BlogCreation} />
          <Route exact path="/getAll/:token"  component={GetAll} />
          <Route exact path="/edit"  component={Edit} />
          <Route exact  path="/" render={(props) => 
          (<Home {...props} isReload={isReload} setisReload={setisReload} isLoggedIn={isLoggedIn} setisLoggedIn={setisLoggedIn} /> )} />
          </div>
        </Switch>    
    </div>
  );
}

export default App;
