import dotenv from "dotenv";
import  Config  from "./config.js";
dotenv.config();

const baseUrl = Config.baseURL;

const ApiRoutes = {
  //auth api
  login:baseUrl+"/auth/login",
  googleLogin:baseUrl+"/auth/google-login",
  register:baseUrl + "/auth/register",
  facebook:baseUrl+"/auth/facebook-login",

  // brand get api
  brandTrending:baseUrl+"/brand/trending",
  brandFreshFinds:baseUrl+"brand/fresh-finds",
  brandWishListUser:baseUrl+"brand/wish-list/user"

};

export default ApiRoutes;
