import { React, useState } from "react";
import "./FitQualityAdvanced.css";

function FitQualityAdvanced({
  titleValue,
  name,
  value,
  brandPageDataWithidFit,
  brandPageDataWithidquality,
}) {
  return (
    <div className="main-container-fitQuality">
      <div className="radio-1 radio-div-Ad">
        <div
          className={
            value
              ? "radiio-button-div-ad BG-color border-round-left"
              : "border-round-left radiio-button-div-ad S-BG-color"
          }
        >
          <span className="Border-Fit-Ad hidden-class-Ad"> </span>
          <div
            className={
              value
                ? parseInt(brandPageDataWithidFit) === 1
                  ? " Radio-Button-Input-Sl  ML-Ad radio-input-Ad "
                  : "Radio-Button-Input ML-Ad radio-input-Ad "
                : parseInt(brandPageDataWithidquality) === 1
                ? " Radio-Button-Input-Sl  ML-Ad radio-input-Ad "
                : "Radio-Button-Input ML-Ad radio-input-Ad "
            }
          ></div>
          <span
            className={
              value
                ? "Border-Fit-Ad border-BG-Color"
                : "Border-Fit-Ad border-BG-Color-SL"
            }
          ></span>
        </div>
        <div className="inputBox-title">{titleValue.FirstValue}</div>
      </div>
      {/* Radio-Button-Input-Sl */}
      {/* Radio-Button-Input ML-Ad radio-input-Ad  */}

      <div className="radio-1 radio-div-Ad">
        <div
          className={
            value
              ? "radiio-button-div-ad S-BG-color"
              : "radiio-button-div-ad BG-color"
          }
        >
          <span
            className={
              value
                ? "Border-Fit-Ad border-BG-Color-SL"
                : "Border-Fit-Ad border-BG-Color"
            }
          >
            {" "}
          </span>
          <div
            className={
              value
                ? parseInt(brandPageDataWithidFit) === 2
                  ? "Radio-Button-Input-Sl radio-input-Ad "
                  : "radio-input-Ad Radio-Button-Input"
                : parseInt(brandPageDataWithidquality) === 2
                ? "Radio-Button-Input-Sl radio-input-Ad "
                : "radio-input-Ad Radio-Button-Input"
            }
          />
          <span
            className={
              value
                ? "Border-Fit-Ad border-BG-Color-SL"
                : "Border-Fit-Ad border-BG-Color"
            }
          ></span>
        </div>
        <div className="inputBox-title">{titleValue.SecondValue}</div>
      </div>
      <div className="radio-1 radio-div-Ad">
        <div
          className={
            value
              ? "radiio-button-div-ad BG-color border-round-right"
              : "border-round-right radiio-button-div-ad S-BG-color"
          }
        >
          <span
            className={
              value
                ? "Border-Fit-Ad border-BG-Color"
                : "Border-Fit-Ad border-BG-Color-SL"
            }
          >
            {" "}
          </span>
          <div
            className={
              value
                ? parseInt(brandPageDataWithidFit) === 3
                  ? "Radio-Button-Input-Sl MR-Ad radio-input-Ad"
                  : "Radio-Button-Input MR-Ad radio-input-Ad"
                : parseInt(brandPageDataWithidquality) === 3
                ? "Radio-Button-Input-Sl MR-Ad radio-input-Ad"
                : "Radio-Button-Input MR-Ad radio-input-Ad"
            }
          />
          <span className="Border-Fit-Ad hidden-class-Ad"></span>
        </div>
        <div className="inputBox-title">{titleValue.ThirdValue}</div>
      </div>
    </div>
  );
}

export default FitQualityAdvanced;
