import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import styles from './edit.module.css'
import 'react-image-crop/dist/ReactCrop.css'
import "cropperjs/dist/cropper.css";
import React, { useEffect, useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { TailSpin } from  'react-loader-spinner';
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const Edit = () => {

  const history = useHistory()
  let query = useQuery();
  // const [searchParams] = useSearchParams();

  const token = localStorage.getItem('token');

  let headers = {
    'Authorization': `Bearer ${token}`
  }

  const [title, settitle] = useState('')
  const [desc, setdesc] = useState('')
  const [bannerImg, setbannerImg] = useState('')
  const [blog, setBlog] = useState();
  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState();
  const [fetchedData, setfetchedData] = useState({})
  const [showEditor, setShowEditor] = useState(false);

  const hiddenBannerInput = useRef(null);
  const hiddenThumbnailInput = useRef(null);

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  useEffect(() => {
    axios.get(`https://backend.spiffyyou.com/cms/blog/${query.get('id')}`)
      .then((res) => {
        setfetchedData({
          bannerImg: res.data.bannerImage,
          cropData: res.data.thumbnailImage,
        })

        settitle(res.data.title)
        setdesc(res.data.summary)
        let body = {
          "uri": res.data.content
        }
        axios.post(`https://backend.spiffyyou.com/cms/blog-comment/content`, body).then((res) => {
          var strippedHtml = res.data.replace(/<[^>]+>/g, '');
          setBlog(strippedHtml)
        })
      })
      .catch(console.log)
  }, [])

  useEffect (()=>{
    setTimeout(() => {
      setShowEditor(true)
    }, 1000);
  },[])


  const handlechange = (content) => {
    setBlog(content)
  }

  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const createBlog = async () => {

    var myFile = dataURLtoFile(cropData, 'thumbnail.jpeg');
    const logoFormData = new FormData();
    logoFormData.append("file", myFile);
    let data = {
      "summary": desc,
      "title": title,
      "content": blog,
      "bannerImage": bannerImg,
      "thumbnailImage": cropData
    }
    setTimeout(() => {
      axios.patch(`https://backend.spiffyyou.com/cms/newBlog/${query.get('id')}`, data, { headers })
        .then(() => window.alert('Blog updated successfully'))
        .catch(console.log)
    }, 300);
  }

  const handlebannerUpload = (e) => {

    const bannerFormData = new FormData();
    bannerFormData.append("file", e.target.files[0]);

    axios.post("https://backend.spiffyyou.com/cms/upload", bannerFormData, { headers })
      .then((res) => {
        setbannerImg('res', res.data.url)
      }).catch((err) => {
        console.log(err)
      })

  }

  const handleBanner = event => {
    hiddenBannerInput.current.click();
  };

  const handleThumbnail = event => {
    hiddenThumbnailInput.current.click();
  };

  

  return (
    <div>
      <h2 style={{ textAlign: "center", margin: "15px" }}>Edit Blog</h2>
      <div className={styles.mainContainer}>

        <div onClick={() => history.push(`/getAll/${token}`)} style={{ margin: '15px', backgroundColor: "#2f4f4f", color: 'whitesmoke', padding: '10px', border: 'none', borderRadius: '5px' }}>
          All Blogs
        </div>

        {/* <div onClick={() => history.push(`/edit/${'df'}`)} >
          Edit
        </div> */}

        <div className={styles.formWrapper} >
          <div className={styles.formContainer} >

            <input
              onChange={e => settitle(e.target.value)}
              value={title}
              placeholder="Enter Title..."
              className={styles.inputField}
            />

            <input
              onChange={e => setdesc(e.target.value)}
              value={desc}
              placeholder="Enter Description..."
              className={styles.inputField}
            />

            <button className={styles.imageUploadBtn} onClick={handleBanner}>
              {bannerImg == null ? 'Upload Banner' : 'Update Banner'}
            </button>
            <input
              type={'file'}
              ref={hiddenBannerInput}
              onChange={handlebannerUpload}
              style={{ margin: '6px 0px', height: '290px', width: '170px', objectFit: 'cover', display: 'none' }}
            />

            <button className={styles.imageUploadBtn} onClick={handleThumbnail} >
              {cropData == null ? 'Upload Thumbnail' : 'Update Thumbnail'}
            </button>
            <input
              type="file"
              ref={hiddenThumbnailInput}
              onChange={onChange}
              style={{ margin: '6px 0px', display: 'none' }}
            />

            <div style={{ width: "100%" }}>
              <Cropper
                // style={{ height: 400, width: "100%" }}
                zoomTo={0.5}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
              <h1>
                {image && <button style={{ float: "right" }} onClick={getCropData} className={styles.cropBtn}>
                  Crop Image
                </button>}
              </h1>
            </div>
          </div>
          <div className={styles.previewBox} >
            <p style={{ height: '100px', textAlign: 'initial', fontSize: '30px' }} >{title}</p>
            <div className={styles.descBox} >
              <p style={{ position: 'relative', textAlign: 'initial' }} >{desc}</p>
              {(bannerImg || fetchedData.bannerImg) &&
                <div style={{ borderRadius: '10', overflow: 'hidden', height: '400px', width: '300px', position: 'absolute', top: 100, right: 100 }} >
                  <img
                    src={(bannerImg && URL.createObjectURL(bannerImg)) || fetchedData.bannerImg}
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                </div>
              }
              {(cropData || fetchedData.cropData) &&
                <div style={{ borderRadius: '10', overflow: 'hidden', height: '100px', width: '100px', position: 'absolute', top: 450, right: 130 }} >
                  <img src={cropData || fetchedData.cropData} alt="cropped" style={{ objectFit: 'contain', height: '100px', width: '100px' }} />
                </div>
              }
            </div>
          </div>
        </div>

        { showEditor ? 
            <LoadSunEditor blog={blog} createBlog={createBlog} handlechange={handlechange} /> : <TailSpin  color="#000000" height={100} width={100} />
        }
        <div>
          <div dangerouslySetInnerHTML={{ __html: blog }} /></div>
      </div>
      <br style={{ clear: "both" }} />
    </div>
  );
};

export default Edit;


const LoadSunEditor = (props) => {
  const { blog, createBlog, handlechange } = props;
  return (
    <div style={{ width: '70%', marginTop: `${window.screen.availHeight / 5}px` }} >
      <button onClick={() => createBlog()} className={styles.submitBtn} >Submit Blog</button>
      <SunEditor
        autoFocus={true}
        placeholder="Please type here..."
        onChange={handlechange}
        defaultValue={blog}
        height='80vh'
        setOptions={{
          buttonList: [
            [
              "bold",
              "underline",
              "italic",
              "list",
              "align",
              "lineHeight",
              "fontSize",
              "table",
              "image",
              "video",
              "audio",
              "fontColor",
              "undo",
              "redo",
              "preview"
            ]
          ]
        }}
      />
    </div>
  )
}