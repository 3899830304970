import { React, useEffect, useState, useRef,useContext } from "react";
import "./BrandCards.css";
import { FaStar } from "react-icons/fa";
import locationImg from "../../../assets/img/Location.svg";
import shareImg from "../../../assets/img/Share.svg";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import AvailableDelivery from "../AvailableDelivery/AvailableDelivery";
import { useHistory,useLocation} from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { BsStarFill, BsStarHalf } from "react-icons/bs";
import { toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { FaWhatsappSquare, FaFacebookSquare } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { MdImageNotSupported } from "react-icons/md";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FacebookShareButton,WhatsappShareButton,EmailShareButton} from "react-share";
import CommingSoon from "../../../assets/canva3.png";
import LogContext from "../../../LogContext";


export default function FreshFind({ card }) {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [showRatingValue, setShowRatingValue] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [ratingID,setRatingID] = useState("");
  const [enter, setEnter] = useState(false);
  const [wishListRefresher, setWishListRefresher] = useState(false);
  const [ copy ,setCopy] = useState(false);
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const [emptyStarsLogOutUser,setEmptystarsLogOutUser] = useState();
  const logStatus = useContext(LogContext)
  const MAX_LENGTH_TITLE = 12;

  const ref = useRef();
  const getWishlistApiData = async () => {
    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/brand/wish-list/${card._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log(error, "message from TrandingFile");
    }
  };
  
  useEffect(() => {
    const token = localStorage.getItem("token");
    const userID = localStorage.getItem("userID");
    if (token) {
      if (card?.wishList?.includes(userID)) {
        setWishListRefresher(true);
      } else {
        setWishListRefresher(false);
      }
    }
  }, [card]);

  const scrollfunction = (scrollOffset) => {
    ref.current.scrollBy({
      top: 0,
      left: scrollOffset,
      behavior: "smooth",
    });
  };
  const ratingStarsAPi=async(starValue)=>{
    try {
      const token = localStorage.getItem("token");
  const res =  await axios.post(`${process.env.REACT_APP_API_URL}/rating-review/}`,{
     brandId:card?._id,
     rating:starValue
    },{headers:{Authorization: `Bearer ${token}` }})
    setRatingID(res?.data?._id);
    setShowRatingValue(true);
    } catch (error) {
      console.log(error,"message from ratingStarsApi")
    }
  }


  const ratingchanged = (newValue) => {
    const token = localStorage.getItem("token");
    if(token) {
      ratingStarsAPi(newValue)
      setRatingValue(newValue);
    } else{
      logStatus.setOpenPop(true);
      setRatingValue(0);
      logStatus.setEmptystarsLogOutUser(Math.random())
    } 
  };

const checkLogIn = ( Brand_name,) => {
    const token = localStorage.getItem("token");
    if (token === "") {
      return toast("LogIn First", { type: "error" });
    } else {
      history.push({
        pathname: "/reviewbrand",
        state: {
          Rating_id:ratingID?ratingID:card?.userRated?._id,
          Brand_name: Brand_name,
          ratingvalueComes:card?.userRated?card?.userRated?.rating:ratingValue,         
          PagePath:location.pathname,
          BrandID:card?._id,
        },
      });
    }
  };

  const onClickHandler = () => {
    const token = localStorage.getItem("token");
    if (token) {
      if (wishListRefresher === true) {
        setWishListRefresher(false);
        getWishlistApiData();
      } else if (wishListRefresher === false) {
        setWishListRefresher(true);
        getWishlistApiData();
      }
    } else {
      logStatus.setOpenPop(true);

    }
  };
  const handleOnError=(e)=>{
    e.target.src=CommingSoon
  }
 

  let history = useHistory();

  let fullStars = new Array(5);
  fullStars.fill(0);
  const starValue = ()=>{
    if(logStatus.value){return card?.userRated?card?.userRated?.rating:ratingValue;}
    else{
       return 0;}
  }
  const editValue =()=>{
    if(logStatus.value){
      return card?.userRated?false:true;
    }else{
      return true
    }
  }


  return (
    <>
      <div  className="card-1">
       
        <div className="card-img-div">
          <AvailableDelivery
            showAvailableDeliveryCard={card?.deliversWorldWide}
          />
          {enter ? (
            <div onMouseLeave={() => setEnter(false)}>
            
            { <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={1000}
                showThumbs={false}
              >
                <div
                  onClick={() =>
                    history.push({
                      pathname: `/discoverpage/${card?._id}`,
                      state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed} ,
                    })
                  }
                  className="image1 pointer-container">
                  {card?.images?(
                  
                    <img
                      src={`${card?.images[0]}`}
                      className="pointer-container "
                      alt=""
                      onError={handleOnError}
                    />          
                  ) : (
                    <MdImageNotSupported className="Empty-Image-Card-Tranding-brandPage" />
                  )}
                </div>
                <div
                  onClick={() =>
                    history.push({
                      pathname: `/discoverpage/${card?._id}`,
                      state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed},
                    })
                  }
                  className="image1 pointer-container"
                >
                  {card?.images ? (
                    <img
                      src={`${card?.images[1]}`}
                      alt=""
                      className="pointer-container"
                      onError={handleOnError}
                    />
                  ) : (
                    <MdImageNotSupported className="Empty-Image-Card-Tranding-brandPage" />
                  )}
                </div>
              </Carousel>}
            </div>
          ) : card?.logo? (
            <div
              className="Tranding-card-image"
              onMouseEnter={() => setEnter(true)}
            >
              <img
                className="pointer-container brandData-image"
                // style={{ aspectRatio: 357 / 274 }}
                onClick={() =>
                  history.push({
                    pathname: `/discoverpage/${card?._id}`,
                    state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed} ,
                  })
                }
                src={card?.logo}
                alt=""
                onError={handleOnError}
              />
            </div>
          ) : (
             <img onClick={() =>
                  history.push({
                    pathname: `/discoverpage/${card?._id}`,
                    state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed} ,
                  })
                } src={CommingSoon}  alt=""/>
          )}
        </div>
        <div className="card-first-detail">
          <div className="first-detail-title">
            <div
              onClick={() =>
                history.push({
                  pathname:`/discoverpage/${card?._id}`,
                  state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed} ,
                })
              }
              className="title BrandCard-main-title CeraProMedium"
            >
              {`${
                card?.title.trim().length > MAX_LENGTH_TITLE
                  ? card?.title.substring(0, MAX_LENGTH_TITLE) + "..."
                  : card?.title
              }`}
            </div>
            <div className="country CeraProLight">{card?.city}</div>
          </div>
          <div className="first-detail-rating">
            <div className="rating-row-1">
              {fullStars.map((star, index) => {
                return index < parseInt(card?.ratingAvg) ? (
                  <FaStar color={"brown"} />
                ) : (
                  <FaStar color={"#707070"} />
                );
              })}
            </div>
            <div className="rating-row-2-brandCard">
              <div className="rating-num CeraProMedium">
                {parseInt(card?.ratingCount)}
                {card?.ratingCount<=1 ? "  Rating" : " Ratings"}
              </div>
              <div className="rating-point CeraProLight">
                {/* Rating Count*/}
              </div>
            </div>
          </div>
        </div>
        <div className="tag-row-tranding">
          {card?.knownForTag?.length < 1 ? (
            <div className="Hide-knonwForTagArrow"></div>
          ):(
            <div>
              <MdKeyboardArrowLeft
                onClick={() => scrollfunction(-200)}
                className="ScrollButton-knowForTag"
              />
            </div>
          )}
          <div className="KnowForTag-scroll-div" ref={ref}>
            {" "}
            {card?.knownForTag?.map((Tag) => {
              return (
                <div 
                onClick={()=>history.push({pathname:"/discoverpage",state:{knowForTagID:Tag?._id,KnownForTagTitle:Tag?.title}})}
                    className="tags">
                   <h4>{Tag.title}</h4>
                </div>
              );
            })}{""}
          </div>
          {card?.knownForTag?.length < 1 ? (
            <div className="Hide-knonwForTagArrow"></div>
          ) : (
            <div>
              <MdKeyboardArrowRight
                onClick={() => scrollfunction(+200)}
                className="ScrollButton-knowForTag"
              />
            </div>
          )}
        </div>

        <div className="icon-row">
          <div
            // onClick={() =>
            //   history.push({
            //     pathname: "/brandpage",
            //     state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed} ,
            //   })
            // }
            onClick={() =>
              history.push({  pathname: `/discoverpage/${card?._id}`, state:{cardID:card?._id,PerPath:history.location.pathname,StarRating:card?.userRated?.rating,ReviewButtonStatus:card?.userReviewed}})
            }
            className="learn-more"
          >
            Learn More
          </div>
          <div className="icon-section">
            
            <div className="favorite">
              {/* TODO:  */}
              {wishListRefresher ? (
                <AiFillHeart
                  onClick={() => onClickHandler()}
                  className=" heart-icon-size"
                />
              ) : (
                <AiOutlineHeart
                  onClick={() => onClickHandler()}
                  className=" heart-icon-size"
                />
              )}
            </div>
            <div className="share">
              <img
                src={shareImg}
                alt=""
                className="freshfinds-cards-icon"
                onClick={() => setOpen(true)}
              />
              <Modal open={open} onClose={() => setOpen(false)} center>
                <h2>Share</h2>
                <div className="share-modal-wrap">
                  <div className="whatsapp share-modal">
                    <WhatsappShareButton
                  url={"https://spiffyyou.com/discoverpage/"+card?._id}
                  quote={"Recommended Brand:"+card?.title}
                  hashtag={"#spiffy"}
                  description={"aiueo"}
                  className="Demo__some-network__share-button"
                   ><FaWhatsappSquare color={"brown"} size={"3rem"} /></WhatsappShareButton>
                  </div>

                  <div className="facebook share-modal">
                    <FacebookShareButton
                        url={"https://spiffyyou.com/discoverpage/"+card?._id}
                         quote={"Recommended Brand:"+card?.title}
                        hashtag={"#spiffy"}
                        description={"aiueo"}
                        className="Demo__some-network__share-button"
                    >
                    <FaFacebookSquare color={"brown"} size={"3rem"} />
                  </FacebookShareButton>
                  </div>

                  <div className="mail share-modal">
                   <EmailShareButton
                    url={"https://spiffyyou.com/discoverpage/"+card?._id}
                       quote={"Recommended Brand:"+card?.title}
                    hashtag={"#spiffy"}
                    description={"aiueo"}
                    className="Demo__some-network__share-button"
                    >
                      <IoIosMail color={"brown"} size={"4rem"} />
                    </EmailShareButton>
                  </div>
                </div>
                <div style={{padding:"10px"}} className="link-row">
                  <div className="link-for-copy">https://spiffyyou.com/discoverpage/{card?._id}</div>
                  <div style={{marginLeft:"10px"}} className="copy-btn">
                  <CopyToClipboard
                 text={"https://spiffyyou.com/discoverpage/"+card?._id}
                 onCopy={()=>setCopy(true)}>
                  <button className="copy-button">Copy</button>
                   </CopyToClipboard>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>

        <div className="bottom-rating-row bottom-stars-freshfind">
          <div className="rate-it">
            <p className="CeraProMedium">Rate it</p>
          </div>
          <div
            className={showRatingValue===true ? "Hide-stars " : ""}
          >
            <div>
              <ReactStars
                key={logStatus.emptyStarsLogOutUser}
                size={30}
                count={5}
                isHalf={false}
                edit={editValue()}
                value={starValue()}
                onChange={ratingchanged}
                emptyIcon={<BsStarFill className="Rating-start-BrandPage" />}
                halfIcon={<BsStarHalf className="Rating-start-BrandPage" />}
                filledIcon={<BsStarFill className="Rating-start-BrandPage" />}
                color={"white"}
                activeColor={"#AF1C1C"}
              />
            </div>
          </div>
          <div onClick={() => checkLogIn( card?.title)}>
           {logStatus.value && <div
              style={(card?.userReviewed )?({display:"none"}):( (showRatingValue || card?.userRated)?{}:{display:"none"})}
              className={"review-div CeraProMedium"}> Write a <br /> Review
            </div>}
          </div>
        </div>
      </div>
    </>
  );
}


