import  { React, useEffect, useState,useRef } from 'react'
import "./MustRead.css";
import axios from "axios";
import ReadAllButton from "./ReadAllButton";
import BlogCard from "../../../components/BlogPage/BlogCards/BlogCards";
import EmptyDataOnBlog from '../EmptyData/EmptyDataOnBlog';
import ReactPaginate from 'react-paginate';
import { GrPrevious,GrNext } from "react-icons/gr";
function MustReadCardProfilePage({setBlogLength,setOpenAnother}) {
    const [likeBlogList, setLikeBlogList]= useState([]);  
    const [open,setOpen] = useState(false);
    const [pageCount,setPageCount] = useState(1);
    const [pageCountForApi ,setPageCountForApi] = useState(1)

    const ref = useRef();
    const getPinnedBlogsApiData = async () => {
        const token = localStorage.getItem("token");
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/cms/like-blog/user?page=${pageCountForApi}&limit=${2}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setLikeBlogList(response?.data?.Blog);
          setPageCount(response?.data?.totalPage);
        } catch (error) {
          console.log(error, "Error In Blog Api on ProfilePage");      
      }
      };

      const likeBlogApi= async (card)=>{
        const token = localStorage.getItem('token');
       if(token){ 
          try {
             await axios.patch(`${process.env.REACT_APP_API_URL}/cms/like-blog/${card?._id}`,{headers: {"Authorization" : `Bearer ${token}`} })
              .then(()=>{
                const newCards = [...likeBlogList].filter(item=> item?._id !== card?._id)
                setLikeBlogList(newCards);
               })
             }   catch (error) {
              console.log(error);
            }
          }
        }

        const scrollfunction=(scrollOffset)=>{
          ref.current.scrollBy({
            top: 0,
            left:scrollOffset,
            behavior: 'smooth'
          })
        }    
      useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
          getPinnedBlogsApiData();
        }
      }, [pageCountForApi]);
        
  const handlePageChange=(e)=>{
  setPageCountForApi((e.selected)+1);
  }

 const pageOffset =0;
    return (
    <div className="Profilepage-BlogSection">
     <div className={likeBlogList?.length>0?"mustread-section-wrap":"mustread-section-wrap-empty"}>
        <div id="pinned" className="bg-div">
          <h1 className="CeraProBold mustread-h1">{"#Pinned"}</h1>
          <p className="mustread-p CeraProLight">
          Find all the Spiffy Stories you liked in one place
          </p>
        </div> 
       <div className="two-cards-wrap-profile">
       <div className={"first-card-div-profile"}>  
       {/* {likeBlogList?.length >2? <MdKeyboardArrowLeft onClick={()=>scrollfunction(-550)}  className="icon-arrow-profilePage" />:""} */}
       <div className="scroll-BlogCard-profilePage" ref={ref}>
       {likeBlogList?.length >0?(likeBlogList?.map((card, index) => {
        return (  <BlogCard indexNo={index} setOpenAnother={setOpenAnother} likeBlogApi={likeBlogApi} fontValue={"ProFilePage"} pro={"ProfilePage"} key={card?._id} setOpen={setOpen}  card={card} /> );
       })):<EmptyDataOnBlog  profilepage={true} Heading={''} ButtonText={"Read More"} EmptyMassage={"No Items In Your Blog List"} />}
       </div>

       {/* {likeBlogList?.length >2? <MdKeyboardArrowRight onClick={()=>scrollfunction(+550)}  className="icon-arrow-profilePage" />:''} */}
             </div>
             
            </div>
         { likeBlogList?.length > 0 &&
            <div style={{marginTop:"-28.2%"}}> 
              <ReactPaginate 
                previousLabel={<GrPrevious className="Prev-Button-Pagination"/>}
                nextLabel={<GrNext className="Next-Button-Pagination"/>}
                pageClassName="All-li-Items"
                pageLinkClassName="li-Item-link"
                previousClassName="Prev-li-Point-main"
                previousLinkClassName="Prev-li-Point"
                nextClassName="Next-BTN-Point-Main"
                nextLinkClassName="Next-BTN-Link"
                breakLabel="..."
                breakClassName="Dot-Break-Point-Main"
                breakLinkClassName="Dot-Break-Point"
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="All-Item-Main-Container"
                activeClassName="Item-active-Point"
                forcePage={pageOffset}
           />
           </div>}
       {likeBlogList?.length>0?<ReadAllButton pro={true} />:''}
         
          </div> 
                
    </div>        
    )
}

export default MustReadCardProfilePage
