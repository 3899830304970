import dotenv from "dotenv";
import { React, useState, useEffect,useContext,memo } from "react";
import "./discoverpage.css";
import "../../ui/Pagination.css";
import Discoverbanner from "../Discoverbanner/Discoverbanner";
import Iconnav from "../Iconnav/Iconnav";
import UpperFooter from "../../ui/UpperFooter/UpperFooter";
import BottomFooter from "../../ui/BottomFooter/BottomFooter";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import DiscoverCards from "../DiscoverCards/DiscoverCards";
import ResDiscoverCard from "../ResDiscoverCards/ResDiscoverCard";
import axios from "axios";
import { BsCheckLg } from "react-icons/bs";
import filterIcon from "../../../assets/ImageAnother/FilterIcon.svg";
import sortIcon from "../../../assets/ImageAnother/sortIcon.svg";
import {useLocation,useHistory} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { GrPrevious,GrNext } from "react-icons/gr";
import { TailSpin } from  'react-loader-spinner';
import NoData from "../../../assets/NoDataImage.png";
import CardCoponent from "../../ui/CardComponent/CardCoponent.js";
import LogContext from "../../../LogContext";
dotenv.config();


function Discoverpage({user,isLoggedIn}) {
    const location = useLocation();
    const history = useHistory();
    const [brandData, setbrandData] = useState([]);
    // const [inputValue, setInputValue] = useState("")
    const [showSortItem, setshowSortItem] = useState(false);
    const [showFilterItem, setFilterItem] = useState(false);
    const [knownValue ,setKnownValue] = useState([]);
    const [loaderSpin ,setLoaderSpin] = useState(false)
    const [knowForTagID , setKnowForTagID] =  useState(location.state?location.state.knowForTagID:"") ;
    const [knownForTagTitle , setKnownForTagTitle] =useState(location.state?location.state.KnownForTagTitle:"") ;
    const [filterStatus , setFilterStatus] = useState(location.state?true:false);
    const [pageCount,setPageCount] = useState(1);
    const [pageCountForApi ,setPageCountForApi] = useState(1)
    // const [pageForceOffSet,setPageForceOffSet] = useState(logStatus.paginationPageOffSet);
    const logStatus = useContext(LogContext);
    

  useEffect(()=>{
    if(location.state){
      logStatus.knownValue.push(knowForTagID) 
      }
  },[])

 

  const handleCallback = (childData) => {
    logStatus.setInputValue(childData);
  };


  useEffect(() => {
    getbrandPageapidataWithSearchValue();
   }, [logStatus.inputValue, logStatus.selectSortItem , logStatus.catValue , logStatus.knownValue , logStatus.checked , logStatus.selectedRating , logStatus.inputValueCountry , logStatus.paginationPageOffSet , isLoggedIn , location.pathname ]);
   
  
   
  const getbrandPageapidataWithSearchValue = async () => {
      const token = localStorage.getItem("token");
      let cat = "";
      logStatus.catValue.map((category_id) => (cat += `&category=${category_id}`));
      let known = "";
      logStatus.knownValue.map((known_id) => (known += `&known-for-tag=${known_id}`));
      
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/brand?search=${logStatus.inputValue}&sort-highly-rated=${
          logStatus.selectSortItem.highlyRateted.value
        }&sort-most-popular=${
          logStatus.selectSortItem.mostPopular.value
        }&sort-title=${logStatus.selectSortItem.ZTOA.status?logStatus.selectSortItem.ZTOA.value:logStatus.selectSortItem.ATOZ.value}&page=${logStatus.paginationPageOffSet+1}&limit=${6}&${logStatus.checked&&"&delivers-world-wide="+logStatus.checked}&sort-recently-added=${logStatus.selectSortItem.recentlyAdded.value}${
          cat !== "" ? cat : ""
        }${known !== "" ? known : ""}&avg-rating=${logStatus.selectedRating}${logStatus.inputValueCountry!=="" &&"&brandOrigin="+logStatus.inputValueCountry.toUpperCase()}`, 
        isLoggedIn ? {headers:{Authorization: `Bearer ${token}`}}
          : {}
      );
      setbrandData(response?.data?.brands);
      setPageCount(response?.data?.totalPage);
    } catch (error) {console.log(error, "Error in Discover");
    }finally{
        setLoaderSpin(true)
      }    
   };  
  
  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {behavior: "smooth",},500);
  },[]);

  useEffect(() => {
    setTimeout(() => {
      setshowSortItem(false);
    }, 100);
  }, [logStatus.selectSortItem]);
    

    const handlePageChange=(e)=>{
    logStatus.setPaginationPageOffSet((e.selected));
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {behavior: "smooth",},100); 
    }


const pageOffset =0;

const changeHRated=()=>{
  logStatus.setselectSortItem({
    highlyRateted: { value: -1, status: true },
    mostPopular: { value: -1, status: false },
    recentlyAdded: { value: -1, status: false },
    relevant: { value:-1, status: false },
    ATOZ: { value: "", status: false },
    ZTOA: { value: "", status: false },
    value: "HighlyRateted",
  })
  setPageCountForApi(1);
  // setPageForceOffSet(0);
  logStatus.setPaginationPageOffSet(0)

}

const changeMPopular=()=>{
  logStatus.setselectSortItem({
    highlyRateted: { value: "", status: false },
    mostPopular: { value: -1, status: true },
    recentlyAdded: { value: "", status: false },
    relevant: { value: "", status: false },
    ATOZ: { value: "", status: false },
    ZTOA: { value: "", status: false },
    value: "MostPopular",
  })
  setPageCountForApi(1);
  // setPageForceOffSet(0);
  logStatus.setPaginationPageOffSet(0)
}

const changeRAdd=()=>{
  logStatus.setselectSortItem({
    highlyRateted: { value: "", status: false },
    mostPopular: { value: "", status: false },
    recentlyAdded: { value: -1, status: true },
    relevant: { value: "", status: false },
    ATOZ: { value: "", status: false },
    ZTOA: { value: "", status: false },
    value: "RecentlyAdded",

  })
  setPageCountForApi(1);
  // setPageForceOffSet(0);
  logStatus.setPaginationPageOffSet(0)
}

const changeRelevant=()=>{
  logStatus.setselectSortItem({
    highlyRateted: { value: "", status: false },
    mostPopular: { value: "", status: false },
    recentlyAdded: { value: "", status: false },
    relevant: { value: 1, status: true },
    ATOZ: { value: "", status: false },
    ZTOA: { value: "", status: false },
    value: "Relevant",
  })
   setPageCountForApi(1);
    //  setPageForceOffSet(0);
     logStatus.setPaginationPageOffSet(0)
}

const changeATOZ=()=>{
  logStatus.setselectSortItem({
    highlyRateted: { value: "", status: false },
    mostPopular: { value: "", status: false },
    recentlyAdded: { value: "", status: false },
    relevant: { value: "", status: false },
    ATOZ: { value: 1, status: true },
    ZTOA: { value: "", status: false },
    value: "Relevant",
  })
  setPageCountForApi(1);
  // setPageForceOffSet(0);
  logStatus.setPaginationPageOffSet(0)
}
const chagneZTOA=()=>{
  logStatus.setselectSortItem({
    highlyRateted: { value: "", status: false },
    mostPopular: { value: "", status: false },
    recentlyAdded: { value: "", status: false },
    relevant: { value: "", status: false },
    ATOZ: { value: "", status: false },
    ZTOA: { value: -1, status: true },
    value: "Relevant",
   })
     setPageCountForApi(1);
    //  setPageForceOffSet(0);
     logStatus.setPaginationPageOffSet(0)
}


  return (
    <div>
      <Discoverbanner />
      <Iconnav setPageCount={logStatus.setPaginationPageOffSet} />
      <div className="discover-middle-wrap">
        <div className="filter-sort-row">
          <div className="filter-div" >
            <div className="filter-btn-discover-page">
            <button onClick={() => setFilterItem(!showFilterItem)}>
              filter
              <img className="filter-sort-icon" alt="." src={filterIcon} />
            </button>
            </div>      
              <div onMouseLeave={()=>setFilterItem(false)}  className={showFilterItem ?"filter-responsive-discover-page ":"filter-responsive-discover-page  response-leftsidebar"}
              >
                <LeftSideBar inputValue={logStatus.inputValue} setInputValue={logStatus.setInputValue} setPageCount={logStatus.setPaginationPageOffSet}  showFilterItem={showFilterItem} setFilterItem={setFilterItem}  filterStatus={filterStatus} knownForTagTitle={knownForTagTitle} knowForTagID={knowForTagID} inputValueCountry={logStatus.inputValueCountry} setInputValueCountry={logStatus.setInputValueCountry} selectedRating={logStatus.selectedRating} setSelectedRating={logStatus.setSelectedRating} checked={logStatus.checked} setChecked={logStatus.setChecked}  handleCallback={handleCallback}  />
              </div>
            
          </div>
          <div className="sort-div"  onMouseLeave={()=>setshowSortItem(false)}>
            <button  onMouseEnter={()=>setshowSortItem(true)}>
              Sort By
              <img className="filter-sort-icon" alt="." src={sortIcon} />
            </button>
            <div
              className={
                showSortItem
                  ? "sort-List-items sort-list-itempermanant"
                  : "showShortList sort-list-itempermanant"}>
              <div
                className={
                  logStatus.selectSortItem.highlyRateted.status
                    ? "select-item-list-options optionButtonStyle"
                    : "optionButtonStyle"
                }
                onClick={()=>
                  changeHRated()}>
                <span>Highly Rated</span>
                <BsCheckLg
                  className={
                    logStatus.selectSortItem.highlyRateted.status
                      ? "select-item-list-options optionButtonStyleIcon"
                      : "icon-visiable"
                  }/>
                </div>

              <div
                className={
                  logStatus.selectSortItem.mostPopular.status
                    ? "select-item-list-options optionButtonStyle"
                    : "optionButtonStyle"
                }
                onClick={() =>
                  changeMPopular()
                }
              >
                <span>Most Popular</span>
                <BsCheckLg
                  className={
                    logStatus.selectSortItem.mostPopular.status
                      ? "select-item-list-options optionButtonStyleIcon"
                      : "icon-visiable"
                  }
                />
              </div>

              <div
                className={
                  logStatus.selectSortItem.recentlyAdded.status
                    ? "select-item-list-options optionButtonStyle"
                    : "optionButtonStyle"
                }
                onClick={() =>
                  changeRAdd()
                }
              >
                <span>Recently Added</span>
                <BsCheckLg
                  className={
                    logStatus.selectSortItem.recentlyAdded.status
                      ? "select-item-list-options optionButtonStyleIcon"
                      : "icon-visiable"
                  }
                />
              </div>
              <div  
                className={
                  logStatus.selectSortItem.relevant.status
                    ? "select-item-list-options optionButtonStyle"
                    : "optionButtonStyle"}
                onClick={() =>
                  changeRelevant()
                }>
                <span>Relevant</span>
                <BsCheckLg
                  className={
                    logStatus.selectSortItem.relevant.status
                      ? "select-item-list-options optionButtonStyleIcon"
                      : "icon-visiable"
                  }
                />
              </div>
              <div
                className={
                  logStatus.selectSortItem.ATOZ.status
                    ? "select-item-list-options optionButtonStyle"
                    : "optionButtonStyle"
                }
                onClick={() =>
                  changeATOZ()
                }>
                <span>A-Z</span>
                <BsCheckLg
                  className={
                    logStatus.selectSortItem.ATOZ.status
                      ? "select-item-list-options optionButtonStyleIcon"
                      : "icon-visiable"
                  }
                />
              </div>
              <div className={
                  logStatus.selectSortItem.ZTOA.status
                    ? "select-item-list-options optionButtonStyle"
                    : "optionButtonStyle"}
                onClick={() =>
                  chagneZTOA()
                }>
                <span>Z-A</span>
                <BsCheckLg
                  className={
                    logStatus.selectSortItem.ZTOA.status
                      ? "select-item-list-options optionButtonStyleIcon"
                      : "icon-visiable"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="discover-middle-section">
          <div className="discover-left-side-bar">
            <LeftSideBar  inputValue={logStatus.inputValue} setInputValue={logStatus.setInputValue} setPageCount={logStatus.setPaginationPageOffSet}  setFilterItem={setFilterItem} filterStatus={filterStatus} knownForTagTitle={knownForTagTitle} knowForTagID={knowForTagID} inputValueCountry={logStatus.inputValueCountry} setInputValueCountry={logStatus.setInputValueCountry} selectedRating={logStatus.selectedRating} setSelectedRating={logStatus.setSelectedRating} checked={logStatus.checked} setChecked={logStatus.setChecked}  handleCallback={handleCallback} />
          </div>
          <div className="discover-middle-brand-cards">
            <div className="dicover-page-cards">
              {/* <DiscoverCards BrandapiData={brandData}/> */}
              <div className="cards-section-wrap">
                <div className="discover-main-card-div res-discover-main-card-div">
                  {loaderSpin? (brandData?.length > 0?
                    (brandData?.map((card, index) => {
                      return (
                        <ResDiscoverCard
                          key={card?._id}
                          userID={user?._id}
                          card={card}
                          BrandapiData={brandData}
                        />
                      );
                    })):
                    <div className="NoData-container">                  
                        {/* <img className="NoData-Image" src={NoData} alt="NoData" />
                        <button onClick={()=> history.push("/submitbrand")} className="Rec-Brand-BTN">Recommend A Brand</button>
                   */}
                    <CardCoponent/>
                    </div>
                  ) : (
                    <div className="Empty-card-discoverPage">
                      <TailSpin  color="#00BFFF" height={80} width={80} />
                    </div>
                  )}
                </div>            
              </div>
            </div>
            <div className="Pagination-main-container">
             {brandData.length !== 0 && 
                <ReactPaginate                   
                    previousLabel={<GrPrevious className="Prev-Button-Pagination"/>}
                    nextLabel={<GrNext className="Next-Button-Pagination"/>}
                    pageClassName="All-li-Items"
                    pageLinkClassName="li-Item-link"
                    previousClassName="Prev-li-Point-main"
                    previousLinkClassName="Prev-li-Point"
                    nextClassName="Next-BTN-Point-Main"
                    nextLinkClassName="Next-BTN-Link"
                    breakLabel="..."
                    breakClassName="Dot-Break-Point-Main"
                    breakLinkClassName="Dot-Break-Point"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}                    
                    onPageChange={handlePageChange}
                    containerClassName="All-Item-Main-Container"
                    activeClassName="Item-active-Point"
                    forcePage={logStatus.paginationPageOffSet}
                />}
               </div> 
          </div>
        </div>
      </div>

      <UpperFooter />
      <BottomFooter />
    </div>
  );
}

export default memo(Discoverpage);

// BsCheckLg


// filterStatus={filterStatus} knownForTagTitle={knownForTagTitle} knowForTagID={knowForTagID}
//known-for-tag=${knowForTagID?knowForTagID:""}&
