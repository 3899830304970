import React from 'react';
import "./ImageSlider.css";
import cardImage from "../../../assets/img/card-img.svg";
import dotenv from 'dotenv';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import AvailableDeliveryCardD from "../../../components/ui/AvailableDelivery/AvailableDeliveryCardD";
import { MdImageNotSupported } from "react-icons/md";
import NoDataImage from "../../../assets/canva3.png";
dotenv.config();
export default function ImageSlider({brandPageDataWithid}) {
    // const [hoverValue,setHoverValue] = React.useState(false);
    // onMouseEnter={null} 
    const handleError =(e)=>{
        e.target.src = NoDataImage;
    }
    return (
       <> <div className="image-slider-wrap">
      
        <AvailableDeliveryCardD showImageDCard={brandPageDataWithid?.deliversWorldWide} />
        {<Carousel
         autoPlay={true}
         infiniteLoop={true}
         interval={2000}
          
        > 
        {/* <div className="image1">
            <img  src={"http://spiffyyou.com:3000//brand/61988215f1156de001d0aeb9/1.jpg"} alt="" />
        </div> */}
        <div className="image1">
            {brandPageDataWithid?.images?<img src={`${brandPageDataWithid?.images[0]}`} alt=""  onError={handleError} />:<img src={NoDataImage} alt=""  />}
        </div>
        <div className="image1">
            {brandPageDataWithid?.images?<img src={`${brandPageDataWithid?.images[1]}`}  alt=""  onError={handleError} />:<img src={NoDataImage} alt=""/>}
        </div>
   
          </Carousel>}
       </div>
       </>
    )
}
