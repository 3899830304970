import {React,useEffect,useState,useContext} from "react";
import "./Navbar.css";
import {Link} from 'react-router-dom';
import {useHistory,useLocation} from "react-router-dom";
import axios from 'axios';
import searchIcon from "../../images/searchlogo.svg"
import dotenv from 'dotenv';
import LogContext from "../../LogContext"
dotenv.config();

// closeSearchBox ,setCloseSearchBox
function Navbar(props) {
  const searchDataHash={'#WishList':'/profilepage','#Pinned':'profilepage','#Explore':'/','#Trending':'/','#FreshFind':'/'}
  const [searchData ,setSearchData] = useState([]); 
  const [searchDataBlog,setSearchDataBlog] =useState([])
  const logStatus = useContext(LogContext);
 
  const MAX_LENGTH = 40;
  const itemClickedHandle =(brand)=>{
    props.setInputValue("");
    history.push({
      pathname: `/discoverpage/${brand?._id}`,
      state:{cardID:brand?._id},
    })
  }
  const itemClickedHandleBlog=(blog)=>{
    props.setInputValue("");
    history.push({
      pathname: `/blogpage/${blog?._id}`,
      state:{BlogID:blog?._id}
    })
  }

  const onChangeValue=(e)=>{
   props.setInputValue(e.target.value);
  }
  const getSearchAPi = async()=>{
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/brand/search/${props.inputValue}`);
      setSearchData(res?.data?.searchedbrand);
      setSearchDataBlog(res?.data?.searchedBlog)
    } catch (error) {
      console.log(error,"Message From NavBar Api")
    } 
  }

  useEffect(()=>{
    if(props.inputValue){
      getSearchAPi()
    }
  },[props.inputValue])
   
  const linkListSearch= (pageValue)=>{
    props.setInputValue("")
    if(pageValue === "#Trending"){
      window.open(`https://www.spiffyyou.com/#Trending`,'_parent')
    }else if(pageValue === "#WishList"){
      window.open(`https://www.spiffyyou.com/profilepage/#wishlist`,'_parent')
    }else if(pageValue === "#Pinned"){
      window.open(`https://www.spiffyyou.com/profilepage/#pinned`,'_parent')
    }else if(pageValue=== "#Explore"){
      window.open(`https://www.spiffyyou.com/#Explore`,'_parent')
    }else if(pageValue === "#FreshFind"){
      window.open(`https://www.spiffyyou.com/#freshFinds`,'_parent')
    }
  } 

 const seeMoreFunction =()=>{
  props.setInputValue("");  
  history.push("/blogpage");
 }
 const seeMoreFunctionBrnad =()=>{
  props.setInputValue("");
  history.push("/discoverpage");
 }
  
  let history = useHistory();
  let location = useLocation();
  const [selected ,setSelected] = useState({})
  let token = localStorage.getItem('token');
   const activeFunction =()=>{
     if(location.pathname==='/'){
      setSelected({
        Home:true,
        Discover:false,
        Explore:false,
        AboutUs:false,
        Profile:false,
        Login:false,
        Register:false })
     } else if(location.pathname==='/discoverpage'){
      setSelected({
        Home:false,
        Discover:true,
        Explore:false,
        AboutUs:false,
        Profile:false,
        Login:false,
        Register:false })
     }else if(location.pathname==="/blogpage"){
      setSelected({
        Home:false,
        Discover:false,
        Explore:true,
        AboutUs:false,
        Profile:false,
        Login:false,
        Register:false })
     }else if(location.pathname==='/aboutus'){
      setSelected({
        Home:false,
        Discover:false,
        Explore:false,
        AboutUs:true,
        Profile:false,
        Login:false,
        Register:false })
     }else if(location.pathname ==='/profilePage'){
      setSelected({
        Home:false,
        Discover:false,
        Explore:false,
        AboutUs:false,
        Profile:true,
        Login:false,
        Register:false })
     }
     else if(location.pathname ==='/logInPage'){
      setSelected({
        Home:false,
        Discover:false,
        Explore:false,
        AboutUs:false,
        Profile:false,
        Login:true,
        Register:false })
    }
    else if(location.pathname ==='/Register'){
      setSelected({
        Home:false,
        Discover:false,
        Explore:false,
        AboutUs:false,
        Profile:false,
        Login:false,
        Register:true })
    }
   }
   useEffect(()=>{
    activeFunction()
   },[location])

  useEffect(()=>{
   if(token){  
  props.setisLoggedIn(true);
   }else{
     props.setisLoggedIn(false)
   }
},[])

const handlelogOut =()=>{
    localStorage.clear();
    props.setisLoggedIn(false);
    logStatus.setValue(false)
    } 

// const pageComponentLinks = {
//   YourStye:history.push()
// }


 return (
   <>
    <div className="nav-wrap web-nav-bar">
      <div className="nav-logo" onClick={()=>history.push('/')}>
        <h1 style={{ fontFamily:"CeraProBold"}}> Spiffy </h1>
      </div>
      <div className="nav-menu">

        <ul className="nav-ul " >
          <li>
            <Link onClick={activeFunction}  className="link" to='/'><div className={selected.Home?"link-color-onSelect":""}>HOME</div></Link>
          </li>
          <li>
            <Link  onClick={activeFunction} className="link" to="/discoverpage"><div className={selected.Discover?"link-color-onSelect":""}>DISCOVER</div></Link>
          </li>
          <li>
            <Link onClick={activeFunction} className="link" to='/blogpage'><div className={selected.Explore?"link-color-onSelect":""}>EXPLORE</div></Link>
          </li>
          {props.isLoggedIn?(
          <ul className="nav-ul"> 
          <li className="user-profile-li">
            <Link onClick={activeFunction} className="link" to="profilepage">
               <div className={selected.Profile?"link-color-onSelect ":" "}>
                  PROFILE
               </div>
            </Link>            
           </li></ul>):""}
          <li>
            <Link  onClick={activeFunction} className="link" to="/aboutus"><div className={selected.AboutUs?"link-color-onSelect":""}>ABOUT US</div></Link>
          </li>
          <li className="menu-search list-item-search-position-handler">
          <div className="Search-box-div">
          <img src={searchIcon} alt="searchImage" className="Image-icon-nabar" />
            <input
            type="text"
            value={props.inputValue}
            onChange={onChangeValue}
            placeholder="SEARCH"
            className="search-inputbox-navbar"/>
            </div>
            <div className={props.inputValue?"Search-item-show":"Hide-search-item"}>
            {searchData?.slice(0,5)?.map((brand)=>{
              return <div className="Brand-title-search" onClick={()=>itemClickedHandle(brand)}>{brand?.title}</div>
              })}
             {searchData?.length>0?"":""}
             {/* <div className="NoData-inBrands">No  Relevent Brands</div> */}
             <div className="Brand-title-search see-More-button-brand color-change" onClick={seeMoreFunctionBrnad}>See More...</div>
              <div className="Blog-section-data-on-Navbar">
  
                 {searchDataBlog.length>0&&<div className="Blog-section-start-border">Blogs</div>}
                  {searchDataBlog?.slice(0,5)?.map((blog)=>{return <div className="Brand-title-search" onClick={()=>itemClickedHandleBlog(blog)}> {`${blog?.title.trim().length > MAX_LENGTH ? blog?.title.substring(0,MAX_LENGTH) + '...' : blog?.title}`}</div>})}
                  {searchDataBlog.length>0?"":""}
                  {/* <div className="NoData-inBlog">No Relevent Blogs</div> */}
              </div>
              {searchDataBlog.length>0&&<div className="Brand-title-search color-change" onClick={seeMoreFunction}>See More...</div>}
             {localStorage.getItem('token')&&<div className="Blog-section-data-on-Navbar">

             <div className="Blog-section-start-border">Page Sections</div>
                  <ul className="">
                    {Object.keys(searchDataHash).map((search)=>{if(search.toLowerCase().includes(props.inputValue.toLowerCase())){return(<li onClick={()=>linkListSearch(search)} className="page-section-links">{search}</li>)}})}
                  </ul>
               </div>}
            </div>
          </li>     
          {props.isLoggedIn?(
        <ul className="nav-ul"> 
            <li>  
                <Link  onClick={handlelogOut} className="link" to="/logInPage">LOGOUT</Link>
            </li>
        </ul> ):(
                <ul className="nav-ul"> 
                  <li className="menu-join">
                      <Link onClick={activeFunction}  className="link" to="/register"><div className={selected.Register?"link-color-onSelect":""}>SIGNUP</div></Link>
                  </li>
                  <li>
                      <Link onClick={activeFunction}  className="link" to="/loginpage"><div className={selected.Login?"link-color-onSelect":""}>LOGIN</div></Link>
                  </li>
                </ul>
             )}            
        </ul>
      </div>
    </div>
    </>
  );
}

export default Navbar;
