import React from "react";
import "./MustRead.css";
import buttonArrowImg from "../../../assets/img/button-arrow.svg";
import {useHistory} from 'react-router-dom';

export default function ReadAllButton({pro}) {
  const history = useHistory();
  return (
    // <div className="read-all-btn-wrap read-all-btn-wrap-responsive">
    <div className={pro?"Read-All-Button-Ex Hide-ReadButton":"Read-All-Button  Hide-ReadButton"} >
      <button onClick={()=>history.push("/blogpage")} className="discribe-btn read-all-btn-div" >
        <p className="CeraProBold Button-Browse-lables">READ ALL</p>
        <img src={buttonArrowImg} alt="" />
      </button>
    </div>
  );
}
