import React from "react";
import "./ResponsiveTeam.css";
import { FaLinkedin } from "react-icons/fa";
import akansha from "../../../assets/Akansha.png";
import astha from "../../../assets/Astha.jpg";
import garima from "../../../assets/Garima.jpg";
export default function ResponsiveTeam() {
  
  const teamprofilearr = [
    {
     name:"ASTHA PASRICHA",
     linkedinLink:"www.linkedin.com/in/astha-pasricha-a32b01a/",
     profilePic:astha,
     FirstInfo:`Product management and sales strategy professional in global positions for over 12 years .`,
     secondInfo:` Led multiple change management projects  to drive revenues and shape processes .`, 
     ThirdInfo:`MBA from KJ Somaiya school of Management, Mumbai`,
     lastLIne:true
    },
      {
      name:"AKANKSHA VYAS",
      linkedinLink:"www.linkedin.com/in/akankshavyas90/",
      profilePic:akansha,
      FirstInfo:`Marketing professional with 5 years of experience in product and brand communication, distributor & retailer network development, campaign management, and loyalty programs.`,
      secondInfo:`Masters in Business Administration (MBA) from the Indian Institute of Management, Indore`,
      lastLIne:false
     },
  ]

  return (
    <div className="responsive-team-profile-wrap">
      {teamprofilearr.map((data,index) => {
        return (
          <div className="wrap-width-profile-card">
            <div className="responsive-team-profile">
              <div className={index===0?"team-inner-relative-first":"team-inner-relative"}>
                <div className="responsive-profile-circle"><img className={!data.lastLIne&&"imageContainerTeam"}  src={data.profilePic}/></div>
                <div className="reponsive-team-line"></div>
                <div className="responsive-profile-linkedin">
                  <FaLinkedin size={"1.5rem"} color={"#31A2AC"} />
                </div>
              </div>
              <div className="responsive-team-profile-detail">
                <h1 className="CeraProBold">{data.name}</h1>
                <p className="CeraProMedium">
                {data.profileInfo}
                <ul>
                  <li>{data.FirstInfo}</li>
                  <li>{data.secondInfo}</li>
                 {data.lastLIne && <li>{data.ThirdInfo}</li>}
                </ul>
                </p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
