import {React,useState,useEffect} from "react";
import "./FomoAlert.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import dotenv from "dotenv"
import moment from 'moment';
import CommingSoon from "../../../assets/canva3.png";
dotenv.config();
export default function FomoAlert({ isLoggedIn, setisLoggedIn }) {
  const [externalBlogLinks,setExternalBlogLinks] = useState([]);

  const history = useHistory();
  useEffect(()=>{
    getExternalBlog();
  },[])
  
  const getExternalBlog = async()=>{
    try {
    await axios.get(`${process.env.REACT_APP_API_URL}/links`).then((res)=>{
      setExternalBlogLinks(res?.data);
      console.log(res?.data,"Data rom Fomo")
      });     
    } catch (error) {
      console.log(error,"Error in Link File")
    }
  }
  const showDate =(card)=>{
    const date = new Date(card);
    const upDate =moment(date).format("DD MMM YYYY")  
     return upDate;
  }
    const handleError=(e)=>{
       e.target.src=CommingSoon
    }

  
  return (
    <>
      {isLoggedIn ? (
        <div></div>
      ) : (
        <div className="fomoalert-wrap">
          <div className="fomoalert-div">
            <div id="fomoalert" className="fomoalert-heading-div">
              <h1 className="CeraProBold">#FOMOalert</h1>
            </div>
            <div className="join-the-club-div">
              <div className="jointheclub-row">
                <h2 className="Nunito-SemiBold">Don’t have an account yet?</h2>
              </div>
              <div className="jointheclub-btn">
                <button
                  onClick={() => history.push("/Register")}
                  className="Nunito-SemiBold"
                >
                  {" "}
                  Signup
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className=" container">
        <div className="offtheweb-div">
          <div className="offtheweb-heading">
            <h1
              className={
                isLoggedIn
                  ? "CeraProBold container-heading-fomo"
                  : "CeraProBold "
              }>                
              #Off-the-web
            </h1>
            <p className="offtheweb-subheading CeraProLight">
              We scour the net and curate some gems, so you don't have
              to—actionable and practical advice from the web in one place.
            </p>
          </div>
        </div>

        <div className="offweb-card-wrap">
          {externalBlogLinks &&
            externalBlogLinks.map((value) => {
              return (
                <div className="offweb-main-card">
                  <div
                    className="offweb-cards"
                    onClick={() => window.open(`${value.url}`, "_blank")}
                  >
                    <div className="offweb-img">
                      <img className="off-web-image" src={value.image} onError={handleError} alt="" />
                    </div>
                    <div className="offweb-des-wrap">
                      <h3 className="CeraProMedium">{value.title}</h3>
                      <div className="offweb-des-bottom">
                        <div className="offweb-des1">
                          <p className="Nunito-Regular">                       
                            {showDate(value?.updatedAt)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}
