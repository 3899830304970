import {React,useState,useEffect} from 'react'
import leftsideImageF from '../../assets/ImageAnother/reset-password-third.svg';
import "./AuthStyle/Forgotpassword.css";
import {MdOutlineVisibilityOff,MdOutlineVisibility} from 'react-icons/md';
import axios from 'axios';
import {useHistory,useParams} from "react-router-dom";
import dotenv from 'dotenv';
dotenv.config();

function ForgotLinkFile(props) {
    const history = useHistory();
    let emailtoken=useParams().token;
 

    const [visibility,setVisibility] = useState(false);
    const [visibilityAnother,setVisibilityAnother] = useState(false);   

    const [newPassWord,setNewPassWord] = useState("");
    const [confirmPass,setconfirmPass] = useState();
    
    const sendingNewPass =async()=>{
        try {
        console.log(emailtoken,"Token ")
         await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`,{
            token:emailtoken,
            password:newPassWord  
           }).then(()=>{
               history.push("/loginpage")
           })
        } catch (error) {
            console.log(error,"error In ForGotLinkFile");
        }
    }

    useEffect(()=>{
     const token = localStorage.getItem("token");
      if(token){
        history.push("/");
       }
    },[])
     

    const newPassChangeHandler =(e)=>{
        setNewPassWord(e.target.value);
     }

     const confirmPassChangeHandler =(e)=>{
        setconfirmPass(e.target.value)
    }
    
    return (
        <div>
            <div className="main-container-Forgot-password">
           <div className="Forgot-password-Image-section">
                 <img alt="leftside-pic" src={leftsideImageF}/>
           </div>
           <div className="Forgot-password-input-section">
                <div className="Forgot-password-heading"></div>
                <div className="Forgot-password-inputFields">
                   <input className="input-boxs-forgetpassword" value={newPassWord} onChange={newPassChangeHandler} type={visibility?"email":"password"} Placeholder="New-Password" />
                   {visibility?<MdOutlineVisibility onClick={()=>setVisibility(false)}  className="visiable-icons" />:<MdOutlineVisibilityOff onClick={()=>setVisibility(true)} className="visiable-icons" /> }
                </div>
                <div className="Forgot-password-inputFields">
                   <input className="input-boxs-forgetpassword" value={confirmPass} onChange={confirmPassChangeHandler} type={visibilityAnother?"email":"password"} Placeholder="Confirm-Password" />
                   {visibilityAnother?<MdOutlineVisibility onClick={()=>setVisibilityAnother(false)}  className="visiable-icons" />:<MdOutlineVisibilityOff onClick={()=>setVisibilityAnother(true)} className="visiable-icons" />}
                </div>
                <div className="Forgot-password-Button">
                    <button onClick={()=>sendingNewPass()}>Submit</button>
                </div>
           </div>        
        </div>
        </div>
    )
}

export default ForgotLinkFile
