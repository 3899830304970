import dotenv from "dotenv";
import { FacebookProvider, LoginButton } from 'react-facebook';
import { React, useState,useContext, useEffect } from "react";
import "./AuthStyle/LoginPage.css";
import {MdOutlineVisibilityOff,MdOutlineVisibility} from 'react-icons/md';
import {Formik,Form,Field} from "formik"
import * as Yup from 'yup';
import {toast } from "react-toastify";
import {useHistory} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin } from 'react-google-login';
import ApiRoutes from "../../utils/apiConstant";
import {FcGoogle} from 'react-icons/fc'
import { FaFacebookSquare } from "react-icons/fa";
import instance from "../../utils/axiosConstant"
import axios from 'axios';
import loginImg from '../../assets/img/login.png';
import cornerImage from "../../assets/ImageAnother/AuthBackGroundImage.png";
import LogContext from "../../LogContext";

dotenv.config();

function LogInPage(props) {
  const logStatus  = useContext(LogContext);
  const [isRevealed, setIsRevealed] = useState(false);
  const BrandName= props.location.state?props.location.state.BrandName:"";
  const RatingValue = props.location.state?props.location.state.RatingValue:"";
  const BrandID = props.location.state?props.location.state.BrandID:"";
  const history = useHistory();
  useEffect(()=>{
    const token= localStorage.getItem('token');
  if(token){
     history.goBack();
    }
  },[]);

  const Reloadfunction=()=>{
    // setTimeout(() => {
    //   document.location.reload(true);
    // },2000);
  }

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("invalid Email Address")
      .required("Enter Email"),
    password: Yup.string()
      .min(5, "Password must be at least 5 characters")
      .required("Enter Password"),
  });

  // const handleSubmitData = (values) => {
  //   axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
  //       email: values.email,
  //       password: values.password,
  // })
    
  const handleSubmitData = async (values)=>{
     await instance.post(ApiRoutes.login, {
          email:values.email,
          password:values.password,
        }).then(function (response){
          localStorage.setItem("token",response?.data?.token);   
          localStorage.setItem("EmailName",response?.data?.user?.email)  
          props.setisLoggedIn(true);
          props.setisReload(true);
          logStatus.setValue(true);
          props.setLoginMessage(true);
          console.log(response?.data?.user?.emailVerified ,"Login Page");
       if(props.location.hash){
               console.log(BrandID,RatingValue,BrandName,"LoginPage Data")
               history.push({pathname:"/reviewbrand",
             state:{
                BrandID:BrandID,
                ratingvalueComes:RatingValue,
                Brand_name:BrandName},
                hash:"logInpage"
              });
            }
            else if(response?.data?.user?.emailVerified === false){
              console.log("In The Email Verification");
              history.push('/EmailVerifycation')
            }
            else {
               history.push("/");
            }
        })
        .then(()=>{
           Reloadfunction()
        }) 
        .catch(function (error) {
          console.log("error message",error.response.data.error);
          toast(error.response.data.error,{type:"error"});// putting Meaning Full Error
        });
     }

  
  
  const handleLoginonGoogle = async googleData => {
    instance.post(ApiRoutes.googleLogin, {
    token:googleData.tokenId
    })
    .then(function (response) {     
      localStorage.setItem("token",response.data.token);
      
      props.setisLoggedIn(true)
      props.setisReload(true);
      props.setLoginMessage(true);
      logStatus.setValue(true)
      if(props.location.hash){
        console.log(BrandID,RatingValue,BrandName,"LoginPage Data")
        history.push({pathname:"/reviewbrand",
         state:{
            BrandID:BrandID,
            ratingvalueComes:RatingValue,
            Brand_name:BrandName},
            hash:"logInpage"
          });
        }else{
           history.goBack();
        }
   
    }).then(()=>{
      Reloadfunction()
    })
    .catch(function (error) {
  
     // putting Meaning Full Error
    });
    
    // store returned user somehow
  };
   
   
  const  handleResponse =async (data) => {
  //  let res=await axios.post('http://spiffyyou.com:3000/auth/facebook-login',{token:data.tokenDetail.accessToken})
  try {
   await axios.post(`${process.env.REACT_APP_API_URL}/auth/facebook-login`, {
      token:data.tokenDetail.accessToken
      })
      .then(function (response) {     
        localStorage.setItem("token",response.data.token);
        props.setisLoggedIn(true)
        props.setLoginMessage(true);
        props.setisReload(true);
      logStatus.setValue(true)
        if(props.location.hash){
        console.log(BrandID,RatingValue,BrandName,"LoginPage Data")
        history.push({pathname:"/reviewbrand",
         state:{
            BrandID:BrandID,
            ratingvalueComes:RatingValue,
            Brand_name:BrandName},
            hash:"logInpage"  
          });
        }else{
           history.goBack();
      }})
      } catch (error) {
    }
  }


  const handleError = (error) => {
    console.log(error);
  }

  return (
    <div className="main-conatianer">
      <div className="image-container">
        <img
          className="middel-image userSelect"
          alt="middel-img"
          src={loginImg}
        />
      </div>

      <div className="logIn-container">
        <img src={cornerImage} className={"Bear-image"} alt=""/> 
        <div className="Login-section">
          <h1 className="userSelect logoText Nunito-Regular">Log in</h1>
          <Formik
            initialValues={{
              email: "",
              password: "", }}
            validationSchema={schema}
            onSubmit={(values) => {
              handleSubmitData(values);
            }}   render={({ errors, values, touched }) => (
              <Form className="login-From-class">
                <Field
                  name="email"
                  className="inputBox email-Input CeraProMedium"
                  placeholder="Email"
                  type="email"
                />
                {errors.email && touched.email ? (
                  <div className="errormassage">{errors.email}</div>
                ) : null}
                <span>
                  <Field
                    name="password"
                    className="inputBox password-Input CeraProMedium"
                    placeholder="Password"
                    type={isRevealed ? "text" : "password"}
                  />
                  {isRevealed ? (
                    <MdOutlineVisibility
                      className="visiableIcon visiableIconCursor userSelect"
                      onClick={() => setIsRevealed(!isRevealed)}
                    />
                  ) : (
                    <MdOutlineVisibilityOff
                      className="visiableIcon visiableIconCursor userSelect"
                      onClick={() => setIsRevealed(!isRevealed)}
                    />
                  )}
                  {errors.password && touched.password ? (
                    <div className="errormassage">{errors.password}</div>
                  ) : null}
                </span>
                <div
                    onClick={() => history.push("/forgotPassword")}
                    className="otherTextStyle ForgetPassword userSelect CeraProLight"
                  >
                    Forgot Password ?
                  </div>
                <div className="Button-section">
                  <button type="submit" className="submit-button CeraProBold userSelect">Log in</button>
                  <div className="Border-Register-container-login"><div className="Border-Register-login"></div><span className="border-text-login">or</span><div className="Border-Register-login"></div></div>
                  <div>
                    <GoogleLogin                  
                      clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                      render={(renderProps) => (
                        <button
                          className="submit-button-social-login  CeraProBold userSelect Social-Buttons"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                        >
                     <FcGoogle className="Social-icon-google" />
                          Continue with Google
                         </button>
                      )}                      
                      buttonText="Log in with Google"
                      onSuccess={handleLoginonGoogle}
                      onFailure={handleLoginonGoogle}
                      cookiePolicy={"single_host_origin"} />
                      
                      <FacebookProvider appId="1438295313238413">
                      <LoginButton
                        className="submit-button-social-login  CeraProBold userSelect Social-Buttons "
                        scope="email"
                        onCompleted={handleResponse}
                        onError={handleError}>
                         <span className="social-btn-container">  <FaFacebookSquare  className="Social-icon-fb" />Continue with Facebook</span>
                       </LoginButton>
                     </FacebookProvider>
                     <div className="Already-a-member userSelect">Not a member?<span onClick={()=>history.push("/register")} className="userSelect SignIn-text">Sign Up</span></div>
                  </div>
                </div>
              </Form> )}/>
        </div>
      </div>
    </div>
   ); 
 }  

export default LogInPage;
