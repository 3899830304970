import dotenv from "dotenv";
import { React, useState, useEffect } from "react";
import AboutUsSlider from "./aboutus/AboutUsSlider";
import FomoAlert from "./fomoalert/FomoAlert";
import GetInTouch from "./getintouch/GetInTouch";
import Slider from "./welcome/Slider";
import FreshFindData from "../ui/BrandCards/FreshFindData";
import UpperFooter from "../ui/UpperFooter/UpperFooter";
import BottomFooter from "../ui/BottomFooter/BottomFooter";
import MustRead from "../ui/mustread/MustRead";
import "react-toastify/dist/ReactToastify.css";
import "./Home.css";
import buttonArrowImgWhite from "../../assets/img/whiteArrow.svg";
import buttonArrowImg from "../../assets/img/button-arrow.svg";
import axios from "axios";
import ResMustRead from "../ui/ResponsiveMustRead/ResMustRead";
import EmptyData from "../ui/EmptyData/EmptyData";
import TrandingCard from "../ui/BrandCards/TrandingCard";
import { useHistory } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import { FaWhatsappSquare, FaFacebookSquare } from "react-icons/fa";
import Fade from 'react-reveal/Fade';
import { IoIosMail } from "react-icons/io";
dotenv.config();

 export default function Home({ blogData,isLoggedIn,setisLoggedIn }) {
   const history = useHistory();
   const [freshBrandData, setFreshBrandData] = useState([]);
   const [trandingData, setTrandingData] = useState([]);
   const [open, setOpen] = useState(false);
   const [brandSwitch, setBrandSWitch] = useState(false);
   const [openAnother, setOpenAnother] = useState(false);
   const [browserD ,setBrowserD] = useState(false);
 
  // useEffect(() => {
  //   const body = document.querySelector("#root");
  //   body.scrollIntoView(
  //     {
  //       behavior: "smooth",
  //     },
  //     500
  //   );
  // }, []);
  
  const getFrashBrandapidata = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/brand/fresh-finds?page=${1}&limit=${3}`
    );
    setFreshBrandData(response?.data?.brands);
  };
  
  const getTrandingapiData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/brand/trending`
    );
    setTrandingData(response.data);
  };    
 
  
  useEffect(() => {
    getFrashBrandapidata();
    getTrandingapiData();
  },[isLoggedIn]);
  
 
  useEffect(()=>{
    if(window.navigator.userAgent.indexOf("Chrome") !== -1){
      setBrowserD(true);
    }else{
      setBrowserD(false);
    }
  },[])
  return (
    <div>
      {/* import toast and its css if it's there in app.js so that you can use it directly in all the components without toastcontainer*/}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}>
        <div className="BrnadPage-Log-Pop">
          <div className="login-image-pop pop-item-1">
            <img className="Login-image-pop" src="loginPopUp.svg" alt="" />
          </div>
          <div   className="Brand-Page-log-pop-item pop-item-2" onClick={() => {
              history.push("/logInpage");
            }}>
            Login
          </div>
          <div
            className="Brand-Page-log-pop-item pop-item-3"
            onClick={() => {
              history.push("/Register");
            }}>
            Register
          </div>
        </div>
      </Modal>
      <Modal open={openAnother} onClose={() => setOpenAnother(false)} center>
        <h2>Share</h2>
        <div className="share-modal-wrap">
          <div className="whatsapp share-modal">
            <FaWhatsappSquare color={"brown"} size={"3rem"} />
          </div>
          <div className="facebook share-modal">
            <FaFacebookSquare color={"brown"} size={"3rem"} />
          </div>
          <div className="mail share-modal">
            <IoIosMail color={"brown"} size={"4rem"} />
          </div>
        </div>
        <div className="link-row">
          <div className="link-for-copy">http://15.206.178.99/</div>
          <div className="copy-btn">
            <button>Copy</button>
          </div>
        </div>
      </Modal>
      <Slider />
      <div id="Trending" className={browserD?"freshfinds-div tranding-main-Container-safari":"freshfinds-div tranding-main-Container"}>
        <div className="cards-section-wrap">
          <div className="containor Tranding-card-heading-container">
            <h1  className="CeraProBold">#Trending</h1>
            <p className="CeraProLight responsive-subheading">
              Most liked, reviewed and wishlisted fashion labels. You cannot go
              wrong with these tried and tested recommendations.
            </p>
            <div className="main-card-div-Home-FreshFind">
              {trandingData?.length > 0 ? (
                trandingData?.slice(0, 3).map((card) => {
                  return (
                    <TrandingCard
                      trand={true}
                      card={card}
                      heading="#Trending"
                    />
                  );
                })
              ) : (
                <EmptyData
                  Heading={""}
                  ButtonText={"Discover more trending brands"}
                  EmptyMassage={
                    "No Items In Your Wishlist. Lorem Ipsum Lorem Ipsum Lorem Ipsum"
                  }
                />
              )}
            </div>
            {trandingData?.length > 0 ? (
              <Fade bottom><button
                className="discribe-btn"
                onClick={() => history.push("/discoverpage")}
              >
                <p className="Button-Browse-lables">Browse Fashion Labels</p>
                <img src={buttonArrowImg} alt="" />
              </button></Fade>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="responsive-home-page-brand-card">
        <div className="FreshAndTrending-Tabs">
          <div onClick={() => setBrandSWitch(false)} className={brandSwitch?"tab-1 freshFind-tab-1":"tab-1 freshFind-tab-1 CardCatSwitch"}>
            <h3>#FreshFinds</h3>
          </div>
          
          <div onClick={() => setBrandSWitch(true)} className={brandSwitch?"tab-2 trending-tab-2 CardCatSwitch":"tab-2 trending-tab-2" }>
            <h3>#Trending</h3>
          </div>
        </div>
        <div className="responsive-brandcard-switch">
          {brandSwitch ? (
            trandingData?.length > 0 ? (
              trandingData?.map((card) => {
                return (
                  <TrandingCard trand={true} card={card} heading="#Trending" />
                );
              })
            ) : (
              <EmptyData
                Heading={""}
                ButtonText={"Read more"}
                EmptyMassage={
                  "No Items In Your Wishlist. Lorem Ipsum Lorem Ipsum Lorem Ipsum"
                }
              />
            )
          ) : freshBrandData?.length > 0 ? (
            freshBrandData?.map((card, index) => {
              return <FreshFindData card={card} key={card?._id} />;
            })
          ) : (
            <EmptyData
              Heading={""}
              ButtonText={"Read more"}
              EmptyMassage={"No Items In Your Wishlist. Lorem Ipsum Lorem Ipsum Lorem Ipsum"}
            />
          )}
        </div>  
              <button
                className="discribe-btn"
                onClick={() => history.push("/discoverpage")}>
                 <p>Browse Fashion Labels</p>
                <img src={buttonArrowImgWhite} alt="" />
               </button>      
      </div>
      <div className="about-slider-component-wrap-div">
        <AboutUsSlider />
      </div>
      <div className="responsive-trending-cards-div freshfind-container-res">
        <div className="cards-section-wrap">
          <div id="freshFinds" className="containor">
            <h1 className="CeraProBold">#FreshFinds</h1>
            <p className="CeraProLight responsive-subheading">
              Newly added fashion labels based on our community's
              recommendation. Surprise yourself with something new.
            </p>
            <div className="main-card-div-Home">
              {freshBrandData?.length > 0 ? (
                freshBrandData?.slice(0, 3).map((card, index) => {
                  return <FreshFindData card={card} key={index} />;
                })
              ):(
                <EmptyData
                  Heading={""}
                  ButtonText={"Read more"}
                  EmptyMassage={
                    "No Items In Your Wishlist. Lorem Ipsum Lorem Ipsum Lorem Ipsum"
                  }
                />
              )}
            </div>
            {freshBrandData?.length > 0 ? (
              <button
                className="discribe-btn"
                onClick={() => history.push("/discoverpage")}>
                <p className="Button-Browse-lables">Browse Fashion Labels</p>
                <img src={buttonArrowImg} alt="" />
              </button>
            ) : ("")}
          </div>
        </div>
      </div>
      <MustRead
        setOpenAnother={setOpenAnother}
        setOpen={setOpen}
        isLoggedIn={isLoggedIn}
        blogTittle="#Explore"
      />
      <ResMustRead setOpenAnother={setOpenAnother}
        setOpen={setOpen}
        isLoggedIn={isLoggedIn}
        blogTittle="#Explore" />

      {/* Give proper name ex: ResponsiveMustRead */}
      <FomoAlert isLoggedIn={isLoggedIn} setisLoggedIn={setisLoggedIn} />
      <GetInTouch />
      <UpperFooter />
      <BottomFooter />
    </div>
  );
}
