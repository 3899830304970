import React from "react";
import "./OurValues.css";
import valueImg1 from "../../../assets/About_page/4.png";
import valueImg2 from "../../../assets/About_page/5.png";
import valueImg3 from "../../../assets/About_page/6.png";
export default function OurValue() {
  const ourvaluearr = [
    {
      image: valueImg1,
      title: "Respect ",
      description: "We respect our community for paying it forward.",
    },
    {
      image: valueImg2,
      title: "Honesty ",
      description:
        "We communicate with no bias towards an individual or a company.",
    },
    {
      image: valueImg3,
      title: "Integrity  ",
      description:
        " We demonstrate authenticity by never endorsing paid fashion brand promotions. ",
    },
  ];
  return (
    <>
      <h1 className="ourvalue-heading">#OurValues</h1>
      {/* <img src={"../../../images/1.png"} alt=""/> */}
      <div className="our-value-wrap">
        {ourvaluearr.map((values,index) => {
          return (
            <div className={`our-value-first-col mrTop${index}`}>
              <div className="our-value-img-div">
                <img src={values.image} alt="" />
              </div>
              <div className="our-value-title">
                <h1>{values.title}</h1>
              </div>
              <div className="our-value-description">
                <p>{values.description}</p>
              </div>
            </div>
          );
        })}
       
      </div>
    </>
  );
}
