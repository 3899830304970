import {React,useState,useEffect} from 'react'
import "./AuthStyle/Forgotpassword.css";
import axios from 'axios';
import leftsideImageF from '../../assets/ImageAnother/reset-password-third.svg';
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import dotenv from 'dotenv';


dotenv.config();

function  ForgotPassword() {
        
    const [userEmail,setUserEmail] = useState("");
    const [emailToken,setEmailToken] = useState();

    const history = useHistory();


    useEffect(()=>{
    const token = localStorage.getItem("token")
    if(token){
        history.goBack();
    }
    },[]);
    
    const forgotPasswordApi = async()=>{
        try {
       const response =  await axios.post(`${process.env.REACT_APP_API_URL}/auth/request-reset-password`,{email:userEmail})
       .then((res)=>{return toast("Please check your email to reset password",{type:"success"})});
       setEmailToken(response) ; 
        } catch (error) {
            console.log(error,"error in forgot password File")
        }
        setUserEmail("")
    }
    
    const emailChangeHandler =(e)=>{
       setUserEmail(e.target.value);
    } 
    
    const forGotPassHandler = ()=>{
        forgotPasswordApi();
        history.push({pathname:"/loginpage", state:{EmailToken:emailToken}})
    }
    
    return (
        <div className="main-container-Forgot-password">
           <div className="Forgot-password-Image-section">
                 <img src={leftsideImageF}/>
           </div>
           <div className="Forgot-password-input-section">
                <div className="Forgot-password-heading"></div>
                <div className="Forgot-password-inputFields">
                   <h2 className="Forgot-password-Heading CeraProMedium">Forgot Password</h2>
                   <input value={userEmail} onChange={emailChangeHandler} type="email" Placeholder="Email" />
                </div>
                <div className="Forgot-password-Button">
                   <button onClick={()=>forGotPassHandler()}>Submit</button>
                </div>
           </div>        
        </div>
    )
}

 export default ForgotPassword
