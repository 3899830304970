import {React,useState,useEffect }from "react";
import "./yourreviewpost.css";
import { FaStar } from "react-icons/fa";
import {AiOutlineLike} from "react-icons/ai";
import { Carousel } from "react-responsive-carousel";
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import {BiCommentDetail} from "react-icons/bi"
import { FaUserCircle } from "react-icons/fa";
import { Modal } from "react-responsive-modal";
import dotenv from "dotenv"
import ReviewReply from "./ReviewReply"
import moment from 'moment';
import NoImage from "../../../assets/NobrandImage.png"
import CommingSoon from "../../../assets/canva3.png";
dotenv.config();

function Yourreviewpost({ratingLikeApi, reviewItem ,likeCounter, }) {
  const history = useHistory();
  const starvalue = [1,2,3,4,5];
  const [replyOfReviews ,setReplyOfReview] = useState([]);
  const [showImageOfReview,setShowImageOfReview] = useState(false);
  const [showReviewReply,setShowReviewReply] = useState(false);
  const cards = { rating: 3, ratingNum: "1685 rating", ratingPoint: 5 };
  const [ updateGetAPi,setUpdateGetApi] = useState(false);
  const brandID = reviewItem?.brandId?._id;
  const [inputReply,setInputReply] = useState();
  const showDate =()=>{
    const date = new Date(reviewItem?.createdAt);
    const upDate =moment(date).format("DD MMM YYYY")  
     return upDate;
  }

  const commentOnComment =async()=>{

    const token = localStorage.getItem("token");
     try {  
     await axios.post(`${process.env.REACT_APP_API_URL}/comment-on-comments/1/${reviewItem?._id}`,
     {comment:inputReply},
     { headers: { Authorization: `Bearer ${token}` } })
     .then((res)=>{
      setInputReply('');
     setUpdateGetApi(!updateGetAPi)
     
     });
    } catch (error) {
      setInputReply('');
       console.log(error,"Error From Comment On Comment Tab 1 Api , Rating");  
    }
  }
  const CommentOnCommentGetApi =async()=>{
    const token = localStorage.getItem("token");
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/comment-on-comments/${reviewItem?._id}`, { headers: { Authorization: `Bearer ${token}` } })
          setReplyOfReview(response?.data)
        
    } catch (error) {
      console.log(error,"Error In Comment-on-Commets Get APi")
    }
  }
 
 
  useEffect(()=>{
   CommentOnCommentGetApi()
  },[updateGetAPi]);


  const handleReplyChange = (e)=>{
    setInputReply(e.target.value);
  }
  let fullStars = new Array(5);
  fullStars.fill(0);
  const handleError =(e)=>{
    e.target.src= NoImage
  }
  const errorHandlerImage=(e)=>{
  e.target.src=CommingSoon

  }
  const MAX_LENGTH_TITLE = 15;
  return (
    <>
    <Modal
     open={showReviewReply}
     onClose={()=>setShowReviewReply(false)}
     classNames={{
          overlay: "customOverlay",
          modal: "customModal",
        }}
     >
      <div className="main-container-review-reply">
        <div className="input-container-review-reply">
          <input value={inputReply} onChange={handleReplyChange} className="input-box-review-reply" type="text" placeholder="Reply"/>
          <button onClick={()=>commentOnComment()} className="input-Button-review-reply">Reply</button>
        </div>  
         <div className="All-Review-Reply-Container">
            {replyOfReviews.map((commentReply)=>{
              return <ReviewReply commentReply={commentReply} />
            })}
         </div>        
      </div>  
     </Modal>
     <Modal
      open={showImageOfReview}
      onClose={()=>setShowImageOfReview(false)} 
      classNames={{
       overlay: "customOverlay",
       modal: "customModal",
        }}>
       {reviewItem?.images[0]&&<Carousel
          autoPlay={false}
          infiniteLoop={true}
          interval={2000}
          showThumbs={false}>

          {reviewItem?.images[0]&&<div onClick={()=>setShowImageOfReview(true)} >
            {<img  src={reviewItem?.images[0]} alt=""  />
            }
          </div>}
          {reviewItem?.images[1]&&<div onClick={()=>setShowImageOfReview(true)}  >
          {<img   src={reviewItem?.images[1]} alt=""  />
            } 
          </div>}
        </Carousel>}
     </Modal>
    <div className="maindiv-reviewpost">
      <div className="left-part">
        <div className="brand-logo pointer-container">
           {reviewItem?.brandData?.logo?
           <img onError={handleError}  onClick={()=>history.push({pathname:'/BrandPage',state:{ cardID:reviewItem?.brandData?._id}})}
           src={`${reviewItem?.brandData?.logo}`} alt="logo" />:
           <FaUserCircle  onClick={()=>history.push({pathname:'/BrandPage',state:{cardID:reviewItem?.brandData?._id}})} className="rating-review-noimage no-logo-image" />}
        </div>
          
        <div className="logotext">
          <h2 onClick={()=>history.push({pathname:'/BrandPage',state:{cardID:reviewItem?.brandData?._id}})} className="logotext-heading pointer-container">
          {`${
            reviewItem?.brandData.title?.trim()?.length > MAX_LENGTH_TITLE
                  ? reviewItem?.brandData.title?.substring(0, MAX_LENGTH_TITLE) + "..."
                  : reviewItem?.brandData.title
              }`}
          </h2>
        </div>
      </div>

      <div className="center-part">
        <div className="center-top">
          <div className="center-top-like">
            <div className="like-icon">
              {<AiOutlineLike  className="like-Button" />}
            </div>
            <div className="like-count">{reviewItem?.likes?.length}</div>
          </div>

          <div className="center-top-like" style={{marginLeft:"10px"}}>
            <div className="like-icon">
              {<BiCommentDetail onClick={()=>setShowReviewReply(true)}  className="like-Button" />}
            </div>
            <div  className="like-count">{replyOfReviews?.length}</div>
          </div>
          <div className="center-top-comment">
            {starvalue.map((star, index) => {
              return index < parseInt(reviewItem?.rating) ? (
                <FaStar  className="star-icon" color={"brown"} />
              ) : (
                <FaStar  className="star-icon"  color={"#707070"} />
              );
            })}
          </div>
          <div className="center-top-date">
            <p>{showDate()}</p>
          </div>
        </div>
        <div className="bottom-part">
          <p className="Review-content-box"> 
             {reviewItem?.review}
          </p>
        </div>
      </div>

      <div className="right your-rating-review">    
      {reviewItem?.images.length>2?
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          interval={2000}
          showThumbs={false}>
         { reviewItem?.images[1]&&<div className="image1">
          <img className="image1-rating-review-brandpage"  src={reviewItem?.images[1]} alt="pic" onError={errorHandlerImage}  />
             
          </div>}
        </Carousel>:<div className="image1">
            {reviewItem?.images[0]&&

              <img className="image1-rating-review-brandpage" src={reviewItem?.images[0]} alt="pic" onError={errorHandlerImage}  />
            }
          </div>}
      </div>  


      <div className="Mobile-view-Detail">
        <div className="rating-stars">
        {starvalue.map((star, index) => {
              return index < parseInt(reviewItem?.rating) ? (
                <FaStar  className="star-icon" color={"brown"} />
              ) : (
                <FaStar  className="star-icon"  color={"#707070"} />
              );
            })}
          <div className="rating-details">
            <p className="pera-1">1,685 rating</p>
          </div>
        </div>
        <div className="Date-Details">{showDate()}</div>
      </div>
    </div>
    </>
  );
}

export default Yourreviewpost;
