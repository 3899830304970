import dotenv from "dotenv";
import { React, useState, useEffect,useContext ,useRef } from "react";
import "./LeftSideBar.css";
import axios from "axios";
import searchIcon from "../../../images/searchlogo.svg";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";
import Country from "../../Auth/Country";
import Fade from 'react-reveal/Fade';
import InputCheckBox from "./InputCheckBox";
import LogContext from "../../../LogContext";
dotenv.config();

export default function LeftSideBar({
  inputValueCountry,
  setInputValueCountry,
  selectedRating,
  setSelectedRating,
  checked,
  setChecked,
  handleCallback,
  knownValue,
  setKnownValue,
  knowForTagTitle,
  filterStatus,
   knownForTagTitle,
   knowForTagID,
   setFilterItem,
   showFilterItem,
   setPageCount,
   inputValue, 
   setInputValue
}) {
  const logStatus = useContext(LogContext);
  const [knownForTagData, setKnownForTagData] = useState([]);
  const [resetKnown,setResetKnown] = useState(true);
  const [starsKey, setStarsKey] = useState(Math.random());
  const [checkedBox,setCheckedBox] = useState(true);
  const outerCheck = useRef()
  const ratingchanged = (newValue) => {
    setPageCount(0)
    setSelectedRating(newValue);
    setFilterItem(false);
  };


  const clearstar = () => {
    ratingchanged(0);
    setStarsKey(Math.random()); 
  };

  const onClickKnow = (id) => {
    if(knowForTagID===id){
      setCheckedBox(!checkedBox)
    }
  
    // setKnownForTagData(!knownForTagData);
    if (logStatus.knownValue.filter((item) => item === id).length > 0) {
      logStatus.setKnownValue(logStatus.knownValue.filter((item) => item !== id));
    } else {
      logStatus.setKnownValue([...logStatus.knownValue, id]);
    }
    setFilterItem(false);
  };
  
  useEffect(() => {
    getKnownForTagApiData();
  }, []);
  
  const getKnownForTagApiData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/known-for-tag?page=1&limit=1000`
      );
      setKnownForTagData(response.data.knownForTag);
     } catch (error) {
      console.log(error);
    }
  };
    
  const knownForTagResetValue = () => {
    logStatus.setKnownValue([]); 
    setResetKnown(false)
    setCheckedBox(false)
    // setFilterItem(false);

  }

  const onChange = (e) => {
    setPageCount(0);
    setInputValue(e.target.value);
  };
  const handleSearchData = async (e) => {
    handleCallback(inputValue);
    if(e.keyCode == 13){
      setFilterItem(false);
    }
  };
  const handleChange = (checked) => {
    setChecked(checked);
  };
   
  const countryInputValue = (e) => {
    setInputValueCountry(e.target.value);
  };

  const onSelecthandler = (e) => {
    setPageCount(0)
    setInputValueCountry(e.target.value);
    setFilterItem(false);
  };
  const deliveryWordWide=()=>{
    setPageCount(0)
    setChecked(!checked);
    setFilterItem(false);
  }
  
  return (
    <>
      <div className="lefside-searchdiv-wrap">
        <div className="lefside-searchdiv">
          <img className="search-icon-image" src={searchIcon} alt="" />
          <input
            type="text"
            inputmode={showFilterItem?"":"none"}
            placeholder="SEARCH BRAND"
            className="search-placeholder search-button-inputbox-discoverpage"
            onKeyUp={handleSearchData}
            onChange={onChange}
            value={inputValue}
          />
        </div>
      </div>
      <div className="known-for-section-wrap">
        <div className="known-heading-div">
          <h1>Known For</h1>
         {logStatus.knownValue.length >0 &&<div onClick={() => knownForTagResetValue()} className="Clear-KnownForTag">
              Reset
            </div>}
        </div>
        {/* checkbox options  */}
      
        <div className="bottom-option-div fix-height-div "> 
      
        {knowForTagID ?<div className="checkbox-div">
                <input
                  
                  onClick={() => onClickKnow(knowForTagID)}
                  type="checkbox"
                  checked={checkedBox}
                  ref={outerCheck}
                  className="input-checkbox input-checkbox-knownForTag"/>
                 <label for="vehicle1" className="checkbox-label">
                  {knownForTagTitle}
                </label>
              </div>:""}        
          {knownForTagData?.map((checkboxData) => {
            return (
               (knowForTagID!==checkboxData?._id?<Fade left><div className="checkbox-div">
               {/* <input
                  onClick={() => onClickKnow(checkboxData?._id)}
                  type="checkbox"
                  className="input-checkbox input-checkbox-knownForTag"/>
                 <label for="vehicle1" className="checkbox-label">
                  {checkboxData?.title}
                </label> */}
                <InputCheckBox
                 setPageCount={setPageCount}
                  labels={checkboxData}
                  setKnownValue={logStatus.setKnownValue}
                  setFilterItem={setFilterItem}
                  knownValue={logStatus.knownValue}
                  setCheckedBox={setCheckedBox}
                  checkedBox={checkedBox}
                  knowForTagID={knowForTagID}
                  knownForTagResetValue={knownForTagResetValue}                 
                  setResetKnown={setResetKnown}
                  resetKnown={resetKnown}
                 />
              </div></Fade>:"")
            );
          })}
         
        </div>
        <div className="bottom-option-div rating-star-disccoverpage-div ">
          {selectedRating > 0 && (
            <div onClick={() => clearstar()} className="Clear-Rating-Filter">
              Reset
            </div>
          )}
          <h1>AVERAGE RATINGS</h1>
            <ReactStars
              key={starsKey} 
              size={30}
              count={5}
              isHalf={false}
              value={selectedRating}
              onChange={ratingchanged}
              emptyIcon={<BsStar className="Rating-start-BrandPage emptyIcon-rating" />}
              halfIcon={<BsStarHalf className="Rating-start-BrandPage" />}
              filledIcon={<BsStarFill  className="Rating-start-BrandPage" />}
              color={"#fff"}
              activeColor={"#31a2ac"}
            />         
        </div>
      <div className="bottom-option-div delivers-to-world-wide-section">
        {/* {checked && (
            <div onClick={() => clickInput()} className="Clear-Rating-Filter-Dl">
              Clear
            </div>
          )} */}
          <input className="input-checkbox-knownForTag" checked={checked} onClick={() =>deliveryWordWide()} type="checkbox" />
          <h1>DELIVERS WORLDWIDE</h1>  
        </div>
       <div className="DeliversTo-section">
     <div className="lefside-searchdiv left-side-DT">
          <img  src={searchIcon} alt="" />
          <select value={inputValueCountry} onChange={onSelecthandler} className="search-country-discover option-class-drop">
            {Country.map((CountryName) => {
              return (
                <option
                  className="option-class-drop"
                  value={
                    CountryName === "Origin Country"?"":CountryName} >
                   {CountryName}
                 </option>
               );
             })}
           </select>
          </div>
        
        </div>
      </div>
    </>
  );
}
