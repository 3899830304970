import { React, useEffect, useState } from "react";
import "./MustRead.css";
import BlogCard from "../../BlogPage/BlogCards/BlogCards";
import axios from "axios";
import ReadAllButton from "./ReadAllButton";
import EmptyDataOnBlog from "../../ui/EmptyData/EmptyDataOnBlog";
export default function   MustRead(props) {
  const [blogData, setBlogData] = useState([]);

  const getBlogData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/cms/blog?page=${1}&limit=${2}`
    );
    setBlogData(response?.data?.Blog);
  };
  useEffect(() => {
    getBlogData();
  }, [props.blogTittle,props.isLoggedIn]);

  const likeBlogApi = async (card) => {
    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/cms/like-blog/${card?._id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="BlogSection-HomePage">
      {" "}
      <div
        className={
          blogData?.length > 0
            ? "mustread-section-wrap Must-Read-section-Profilepage"
            : "mustread-section-wrap-empty"
        }
      >
        <div id="Explore" className="bg-div">
          <h1 className="CeraProBold mustread-h1">{"#Explore"}</h1>
          <p className="mustread-p CeraProLight">
            How-tos, must-trys and strictly avoid - our editorial covers
            fundamentals remaining in style. Read our explore section and refresh your
            wardrobe.
          </p>
        </div>
        {blogData?.length > 0 ? (
          blogData.slice(0, 2).map((card, index) => {
            return (
              <div className="two-cards-wrap">
                <div
                  className={index === 0 ? "first-card-div" : "second-card-div"}
                >
                  <BlogCard
                    setOpenAnother={props.setOpenAnother}
                    pro={"HomePage"}
                    setOpen={props.setOpen}
                    likeBlogApi={likeBlogApi}
                    card={card}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <EmptyDataOnBlog
            profilepage={false}
            Heading={""}
            ButtonText={"Read More"}
            EmptyMassage={"No Items In Your Blog List"}
          />
        )}
        {blogData?.length > 0 ? (
          <ReadAllButton nameForClass="read-all-btn-wrap" />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
