import {React,useState,useEffect} from 'react'
import "./AuthStyle/EmialVer.css"
import EmailVerifycationImage from "../../assets/EmailVerification.svg";
import axios from 'axios';
import dotenv from 'dotenv';
import { TailSpin } from  'react-loader-spinner';
import {toast} from 'react-toastify';
import {useHistory} from "react-router";
dotenv.config();

function EmailVer({emailname}) {
   
    const [resData,setResData] = useState();
    const history = useHistory();
    const handleAPiData = async()=>{ 
      const EmailName = localStorage.getItem('EmailName');
      try {
         await axios.post(`${process.env.REACT_APP_API_URL}/auth/request-email-verification`,{
              email:EmailName
          }).then((res)=>{
             setResData(res?.data);
             toast.success("Check You Email")
          })
      } catch (error) {
        toast.error(error.response.data.error)
      }
    }
    useEffect(()=>{
     const loginToken =localStorage.getItem('token');
     if(!loginToken){
        history.push('/logInPage')
     }
    },[])

    // useEffect(()=>{
    
    //   handleAPiData()
    // },[])

  return (
    <div className="Main-container-Email">
         <div className="image-container-email">
             <img className="side-container-email-image" src={EmailVerifycationImage} alt="" />
         </div>
          
         <div className="input-container-email">
            <div className="input-container-sub">
            <div className="Heading-container-Email CeraProBold">Email Verification</div>    
            <div className="Button-container-emailVer">
                <button className="Send-email-ver" onClick={()=>handleAPiData()}>
                  Send Email   
                </button>
                {/* <div style={LinkPageStyle}>
                  <TailSpin  color="#8BFFAC" height={100} width={100} />
                 </div>  */}
            </div>
            </div>
         </div>
    </div>
  )
}

export default EmailVer

const LinkPageStyle ={
  display:"flex",
  flexDirection:"column",
  justifyContent:"center",
  alignItems: "center",
  height:"20vh",
  marginTop: "1rem",
  marginLeft:"-40px"
}   
