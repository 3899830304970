import React from 'react'
import  './AvailableDeliveryCardD.css'
function AvailableDeliveryCardD({showImageDCard}) {
    return (
        <div className={showImageDCard?"main-container-DeliveryCard-D":"hidden_class_d_box"}>
             <p>Delivers worldWide</p>            
        </div>
    )
}

export default AvailableDeliveryCardD
