import React from 'react';
import BottomFooter from '../ui/BottomFooter/BottomFooter';
import UpperFooter from '../ui/UpperFooter/UpperFooter';
import './About.css';
import HeroSection from './HeroSection/HeroSection';
import Mission from './Mission/Mission';
import OurValue from './OurValues/OurValue';
import ResponsiveTeam from './ResponsiveTeamProfile/ResponsiveTeam';
import ProvideItems from './SpiffyProvide/ProvideItems';
import Team from './TheTeam/Team';

export default function About() {
    return (
        <div>
            <HeroSection/>
            <Mission/>
            <ProvideItems/>
            <OurValue/>
            <Team/>
            <ResponsiveTeam/>
            <UpperFooter/>
            <BottomFooter/>
            
        </div>
    )
}
