import { React, useState, useEffect, useRef } from "react";
import "./iconnav.css";
import Roundicon from "./Roundicon/Roundicon";
import axios from "axios";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

function Iconnav({ setPageCount }) {
  const ref = useRef();
  const refmobile = useRef();

  
  const [discoverPageIcon, setDiscoverPageIcon] = useState([]);
  useEffect(() => {
    getCategoryApiData();
  }, []);
  

  const scrollfunction = (scrollOffset) => {
    ref.current.scrollBy({
      top: 0,
      left: scrollOffset,
      behavior: "smooth",
    });
  };
  const scrollfunctionMobile = (scrollOffset) => {
    refmobile.current.scrollBy({
      top: 0,
      left: scrollOffset,
      behavior: "smooth",
    });
  };
  const getCategoryApiData = async () => {
  try {
    await axios.get(
     `${process.env.REACT_APP_API_URL}/category?page=1&limit=1000`
      ).then((response)=>{ 
        setDiscoverPageIcon(response?.data?.category)
        })
  } catch (error) {
      console.log(error,"Message From Cat Api");
    }
  };

  return (
    <div className="iconnavcontainer">
      <div className="iconcontainer1">
        <div className="left-side-scroll-button">
          <MdKeyboardArrowLeft
            onClick={() => scrollfunction(-500)}
            className="arrow-icon-Ds"
          />
        </div>
        <div className="mid-scroll-container" ref={ref}>
          {discoverPageIcon?.map((iconData, index) => {
            return (
              <Roundicon
                categoryName={iconData?.title}
                id={iconData?._id}
                imageLink={iconData?.icon}      
                text={iconData?.title}
              />
            );
          })}
        </div>
        <div className="right-side-scroll-button">
          <MdKeyboardArrowRight
            onClick={() => scrollfunction(500)}
            className="arrow-icon-Ds"
          />
        </div>
      </div>

      <div className="iconcontainer1-mobile">
        <div
          onClick={() => scrollfunctionMobile(-500)}
          className="left-side-scroll-button"
        >
          <MdKeyboardArrowLeft className="arrow-icon-Ds" />
        </div>

        <div className="mid-scroll-container" ref={refmobile}>
          {discoverPageIcon?.map((iconData) => {
            return (
              <Roundicon
                categoryName={iconData?.title}
                id={iconData?._id}
                imageLink={iconData?.icon}      
                text={iconData?.title}
              />
            );
          })}
        </div>
 
        <div
          onClick={() => scrollfunctionMobile(500)}
          className="right-side-scroll-button"
        >
          <MdKeyboardArrowRight className="arrow-icon-Ds" />
        </div>

        {/* <Roundicon image={resort} text="Resort Wear" />
        <Roundicon image={formal} text="Formal"  />
        <Roundicon image={accesssories} text="Accessories" />
        <Roundicon image={athleisure} text="Athleisure" />
        <Roundicon image={unisex} text="Unisex" />
        <Roundicon image={bespoke} text="Bespoke Clothing" />
        <Roundicon image={outerwear} text="Outerwear" /> */}
      </div>
    </div>
  );
}
export default Iconnav;
