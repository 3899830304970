import React from "react";
import "./cong.css";
import { BsArrowRight } from "react-icons/bs";
import {useHistory} from "react-router-dom";

function Cong() {
  const history = useHistory();
  return (
    <div className="cong-div-wrap">
      <div className="cong-div-top-text">
        <h1>CONGRATULATIONS, YOU ARE AN ALL STAR PROFILE</h1>
      </div>
      <div className="button-container">
        <div className="button" onClick={() => history.push("/discoverpage")}>
          <p>Discover And Rate Other Brands</p>
          <BsArrowRight color={"white"}/>

        </div>
        <div className="button" onClick={() => history.push("/submitbrand")}>
          <p> Submit A New Brand </p> 
          <BsArrowRight />
        </div>
      </div>
    </div>
  );
}

export default Cong;
