import {React} from "react";
import "./invite.css";
import girlspic from "../../../images/girlsgroup.png";
import { FaWhatsapp } from "react-icons/fa";
import { GoMail } from "react-icons/go";
import { RiFacebookBoxLine } from "react-icons/ri";
import { FacebookShareButton,WhatsappShareButton,EmailShareButton} from "react-share";
import { FaWhatsappSquare, FaFacebookSquare } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
  
function Invite() {
   return (
    <>
    <div className="invite-section-wrap">
      <div className="invite-section-img-div">
        <img src={girlspic} alt="" />
      </div>
      <div className="invite-section-content-div">
        <div className="content-div-text">
          <h1>INVITE A FRIEND TO JOIN</h1>
          <h3>3 INVITATIONS LEFT</h3>
        </div>
        <div className="content-div-icons">
          <div className="invite-msg invite-icon">
          <EmailShareButton
                    url={"https://www.spiffyyou.com"}
                    quote={"Find your next favourite fashion label"}
                    hashtag={" "}
                    description={"aiueo"}
                    className="Demo__some-network__share-button"
                    >
                      <IoIosMail className="Mail-icon-invite" color={"brown"}  />
          </EmailShareButton>
          </div>
          <div className="invite-whatsapp invite-icon">
          <WhatsappShareButton
                  url={"https://www.spiffyyou.com"}
                  quote={"Find your next favourite fashion label"}
                  hashtag={"#hashtag"}
                  description={"aiueo"}
                  className="Demo__some-network__share-button">
                  <FaWhatsappSquare className="whatsApp-icon-invite" color={"brown"}  />
          </WhatsappShareButton>
          </div>
          <div  className="invite-share invite-icon">
          <FacebookShareButton
                    url={"https://www.spiffyyou.com"}
                    quote={"Find your next favourite fashion label"}
                    hashtag={"#spiffy"}
                    description={"aiueo"}
                    className="Demo__some-network__share-button">
                    <FaFacebookSquare className="faceBook-icon-invite" color={"brown"}  />
          </FacebookShareButton>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Invite;
