  import React from 'react'
  import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
  import './GoogleMap.css'; 
  const containerStyle = {
    // width: '500px',
    height: '300px'
  };

  const center = {
    lat: -3.745,
    lng: -38.523
  };

  function MyComponent() {
    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyAu5J57XY590FGr6gpDdiSrd_VUvaSBHgA"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      map.fitBounds(bounds);
      setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])

    return isLoaded ? (
      <div className="google-Map-Section">
        <GoogleMap
          mapContainerStyle={containerStyle}
         
          zoom={10}
          defaultCenter={{ lat: 22.7196, lng:75.8577}}
          onLoad={onLoad}
          onUnmount={onUnmount}

        >
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
        </GoogleMap>
        </div>
    ) : <></>
  }

  export default React.memo(MyComponent)