import React from "react";
import "./EmptyData.css";
import { useHistory } from "react-router-dom";
import buttonArrowImgWhite from "../../../assets/img/whiteArrow.svg";

function EmptyData({ Heading, ButtonText, EmptyMassage,PageNu ,wishListPage }) {
  const history = useHistory();

  return (
    <div className={wishListPage? "empty-main-div-container-whish" : "empty-main-div-container" }>
      {/* <div className="Heading-container">
        <h1 className="CeraProBold">{Heading}</h1>
      </div> */}
      <div className={wishListPage?"Massage-container-wish":"Massage-container"}>
        <div className="Empty-Text">{EmptyMassage}</div>
       
      </div>
      <div
          className={`Button-container Pro-WishList`}
          onClick={() => history.push("/discoverpage")}>
          {""}
          {ButtonText}{" "}
          <img src={buttonArrowImgWhite} alt="" />
        </div>
    </div>
  );
}

export default EmptyData;
